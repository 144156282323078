import { OPERADOR_SET_FIELD, 
         OPERADOR_SET_CURRENT,
         OPERADOR_SET_CURRENT_LIST_PARCEIRO,
         OPERADOR_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS
       } from '../actions';
import OperadorModel from '../Model/operadorModel';

const INITIAL_STATE = new OperadorModel().voidConstructor();

export default function operadorReducer(state = INITIAL_STATE, action) {
switch (action.type) {
    case OPERADOR_SET_CURRENT:
       return action.operador;
    case OPERADOR_SET_FIELD:
       const newState = { ...state };
       newState[action.field] = action.value;
       return newState;
    case OPERADOR_SET_CURRENT_LIST_PARCEIRO:
        //let tt = { ...doc.data(), id:  doc.id};
        const newState2 = { ...state};
        newState2[action.field] = action.value;
        return newState2;
    case OPERADOR_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS:
        const newState3 = { ...state, parceirosDisponiveis : action.value};
        return newState3;
   default:
       return state;
}
}