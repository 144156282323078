import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const sweetAlertHandler = (alert) => {
    const MySwal = withReactContent(Swal);
    MySwal.fire({
        title: alert.title,
        text: alert.text,
        type: alert.type
    });
};
export const sweetAlertHandlerError = (title, errorMessage) => {
    return sweetAlertHandler({ title: title, type: 'error', text: errorMessage ?? undefined });
}
export const sweetAlertHandlerSuccess = (text) => {
    return sweetAlertHandler({ title: text, type: 'success', text: ''});
}