import firebase from "../config/firebase";
import { getDateDDMMYYYYHHMMFromTimeStamp } from './utils'
import Cripto from './cripto'

const nameStr = 'logsErros';
var timerVivo = false;
var running = false;
async function timeSendingToFirebase(t) {
    timerVivo = true;
    await t.Sending();
    setTimeout(function () {
        timeSendingToFirebase(t);
    }, 1000);
}


class Log {
    static UID = "";
    static log = null;
    static storageName = nameStr;
    constructor() {
        timerVivo = false;
    }

    static getInstance() {
        if (Log.log == null) {
            Log.log = new Log();
        }
        if (timerVivo == false) {
            timeSendingToFirebase(Log.log);
        }
        return Log.log;
    }

    LogginError(userMail, metodoName, description, trace) {
        try {
            if (userMail == undefined)
                userMail = "";
            if (metodoName == undefined)
                metodoName = "";
            if (description == undefined)
                description = "";
            if (trace == undefined)
                trace = "";

            var itemLog = {
                datatime: getDateDDMMYYYYHHMMFromTimeStamp(new Date().getTime()),
                datatimeStamp: new Date().getTime(),
                type: 'ERROR',
                methodName: metodoName,
                description: description,
                user: userMail,
                msgtrace: trace
            }

            var itensLog = JSON.parse(Cripto.DecryptedStorage(Log.storageName));
            if (itensLog != undefined && itensLog != null && itensLog.length > 0) {  //ja existe logs para envio!, adicionar na fila
                //nothing do to, item has exist!
                var i = 0;
                for (i = 0; i < itensLog.length; i++) {
                    if (itensLog[i].methodName == metodoName &&
                        itensLog[i].description == description)
                        return;
                }
            } else {
                itensLog = [];
            }

            itensLog.push(itemLog);
            Cripto.EncryptedStorage(Log.storageName, JSON.stringify(itensLog));
        } catch (ex) {
            itensLog.push(itemLog);
            Cripto.EncryptedStorage(Log.storageName, JSON.stringify(itensLog));
        }
    }


    async Sending() {
        if (running)
            return;
        running = true;
        var itensLog = JSON.parse(Cripto.DecryptedStorage(Log.storageName));

        if (itensLog != undefined && itensLog != null && itensLog.length > 0) {  //ja existe logs para envio!, adicionar na fila
            console.log('Existe dados de logs de erros!');
            var itemLog = itensLog[0];
            console.log('Existe dados de logs de erros [enviando]');
            if (await Log.log.Send(itemLog) != true) {//nao conseguiu enviar, colocar novamente na fila!
                console.log('Existe dados de logs de erros [nao conseguiu enviar]!');
            } else {
                console.log('Existe dados de logs de erros [enviou!, removendo agora]!');
                itensLog = JSON.parse(Cripto.DecryptedStorage(Log.storageName));
                itensLog.shift();
                Cripto.EncryptedStorage(Log.storageName, JSON.stringify(itensLog));
            }
        }
        running = false;
    }

    async Send(itemLog) {
        var db = firebase.firestore();
        try {
            await db.collection('logsWeb').add({
                datatime: itemLog.datatime,
                datatimeStamp: itemLog.datatimeStamp,
                type: itemLog.type,
                methodName: itemLog.methodName,
                description: itemLog.description,
                user: itemLog.user,
                msgtrace: itemLog.msgtrace,
            });
            return true;
        } catch (e) {
            return false;
        }
    }

}
export default Log;