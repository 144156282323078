import { combineReducers } from 'redux';

import reducer from '../store/reducer.js';
import userReducer from './usuarioReducer.js';
import medicoReducer from './medicoReducer.js';

import convenioReducer from './convenioReducer.js';
import convenioListReducer from './convenioListarReducer.js';

import LoginReducer from './loginReducer.js';

import medicoListarReducer from './medicoListarReducer.js';
import associadoReducer from './associadoReducer.js';
import associadoListarReducer from './associadosListarReducer.js';


import operadorReducer from './operadorReducer.js';
import operadorListarReducer from './operadorListarReducer.js';

import antecedenteReducer from './antecedentesReducer.js';

import administradorReducer from './administradorReducer.js';
import administradorListarReducer from './administradorListarReducer.js';

import dependenteReducer from './dependentesReducer.js';
import dependentesListarReducer from './dependentesListarReducer.js';

import contatoReducer from './contatosConvenioReducer.js';
import contatoListarReducer from './contatosConvenioListarReducer.js';

import notificacaoesReducer from './notificacoesReducer.js';

import dashboardReducer from './atendimentoListarReducer.js';

import listChatMessages from './chatReducer.js';

import perfilReducer from './perfilReducer.js';
import perfilReducerListar from './perfilListarReducer.js';
import antecedentesListarReducer from './antecedentesListarReducer.js';
import enfermeiroReducer from './enfermeiroReducer.js';
import enfermeiroListarReducer from './enfermeiroListarReducer.js';
import triagensReducer from './triagemListarReducer.js';
import Atendimento from './atendimentoReducer';

import EstacoesListarReducer from './estacaoCRUDListarReducer';
import EstacoesReducer from './estacaoCRUDReducer';

import tecEnfermeirosListarReducer from './tecEnfermagemListarReducer';
import tecEnfermeiroReducer from './tecEnfermagemReducer';
import clinicaDigital from "./ClinicaDigitalReducer";

import prescricao from './prescricaoReducer';
import prescricaoList from './prescricoesListarReducer';

import hipotese from './hipoteseReducer';
import hipoteseList from './hipoteseListarReducer';

import turno from './turnoReducer';
import turnoList from './turnoListarReducer';

import especialidade from './especialidadeReducer';
import especialidadeList from './especialidadeListarReducer';

import agendaReducer from './agendaReducer.js';
import agendaListReducer from './agendaListarReducer.js';
import horarioReducer from './horarioReducer.js';
import horarioListReducer from './horarioListarReducer.js';
import ocorrencia from './ocorrenciaReducer';
import ocorrenciasList from './ocorrenciasListaReducer';
import atestadoReducer from './atestadoReducer.js';
import receituarioEspecialReducer from './receituarioEspecialReducer.js';
import receituarioSimplesReducer from './receituarioSimplesReducer.js';
import solicitacaoExamesReducer from './solicitacaoExamesReducer';
import HipoteseListarReducer from './hipoteseDiagnosticaListarReducer';
import especialidadeListReducer from './especialidadeListarReducer';
import clinicaDigitalState from './ClinicaDigital.js';

import agentePenitenciarioReducer from './agentePenitenciarioReducer';
import agentePenitenciarioListarReducer from './agentePenitenciarioListarReducer';
import agendaAtendimento from './AgendaAtendimentoReducer.js';
import indicadoresReducer from '../View/Indicadores/reducer/IndicadoresReducer.js';


/* COLOCAR AS DEMAIS RECUCER */
export default combineReducers({
    native: reducer,
    user: userReducer,
    medico: medicoReducer,

    parceiro : convenioReducer,
    parceiros : convenioListReducer,

    medicos: medicoListarReducer,
    cliente: associadoReducer,
    clientes: associadoListarReducer, 

    operador: operadorReducer,
    operadores: operadorListarReducer, 

    administrador : administradorReducer,
    administradores : administradorListarReducer,

    dependente: dependenteReducer,
    dependentes: dependentesListarReducer,

    enfermeiros: enfermeiroListarReducer,
    enfermeiro: enfermeiroReducer,

    antecedente: antecedenteReducer,
    antecedentes: antecedentesListarReducer,

    estacoes: EstacoesListarReducer,
    estacao: EstacoesReducer,

    triagens: triagensReducer,

    contato: contatoReducer,
    contatos: contatoListarReducer,

    notificacoes: notificacaoesReducer,

    atendimentos: dashboardReducer,
    
    login: LoginReducer,

    atendimento: Atendimento,
    
    tecEnfermeiros: tecEnfermeirosListarReducer,

    tecEnfermeiro: tecEnfermeiroReducer,


    perfis : perfilReducerListar,
    perfil : perfilReducer,
    
    chat : listChatMessages,
    
    clinicaDigital: clinicaDigital,

    prescricao: prescricao,
    prescricoes: prescricaoList,

    hipotese: hipotese,
    hipotesesList: hipoteseList,

    turno: turno,
    turnosList: turnoList,

    
    horario: horarioReducer,
    horariosList: horarioListReducer,

    agenda: agendaReducer,
    agendasList: agendaListReducer,

    especialidade: especialidade,
    especialidadesList: especialidadeList,

    ocorrencia: ocorrencia,
    ocorrencias: ocorrenciasList,

    atestado: atestadoReducer,

    receituarioEspecial: receituarioEspecialReducer,

    receituarioSimples: receituarioSimplesReducer,

    solicitacao: solicitacaoExamesReducer,

    hipoteses: HipoteseListarReducer,

    especialidades: especialidadeListReducer,

    clinicaDigitalState: clinicaDigitalState,
    
    agentePenitenciario: agentePenitenciarioReducer,
    agentesPenitenciarios: agentePenitenciarioListarReducer,
    agendaAtendimento:  agendaAtendimento,
    indicadoresTela:  indicadoresReducer,

});