import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import AntecedenteModel from '../Model/antecedenteModel';
import AntecedentesModel from '../Model/antecedenteModel';
import { replaceEspace, replaceEspaceFieldsInObject } from '../utils/utils.js';
import { getIdToken, request } from './index.js';

//Muda um campo especifico do antecedente atual na redux
export const ANTECEDENTES_SET_FIELD = 'ANTECEDENTES_SET_FIELD';
export const antecedenteSetField = (field, value) => {
    return {
        type: ANTECEDENTES_SET_FIELD,
        field,
        value,
    };
}

//Muda o antecedente atual na redux
export const ANTECEDENTE_SET_CURRENT = 'ANTECEDENTE_SET_CURRENT';
export const antecedenteSetCurrent = antecedente => ({
    type: ANTECEDENTE_SET_CURRENT,
    antecedente
});

//Envia o antecedente para tela de Create ou Update 
export const antecedenteSetAtual = (id) => async dispatch => {
    if (id == null) {
        const antecedente = new AntecedentesModel().voidConstructor();
        dispatch(antecedenteSetCurrent(antecedente));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/antecedente/${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                const antecedente = new AntecedentesModel().completeConstructor(resp.data.data);
                dispatch(antecedenteSetCurrent(antecedente));
            }).catch(error => {
                console.log(error)
            });
    }
};

//Faz a requisição para atualizar o antecedente
export const antecedenteAtualizar = async () => {
    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
    var antecedenteModel = new AntecedenteModel().completeConstructor(store.getState().antecedente);
    antecedenteModel = replaceEspaceFieldsInObject(antecedenteModel)
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(
                `/antecedente/${antecedenteModel.id}`,
                //Body
                antecedenteModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}
//Faz a requisição para cadastrar o antecedente
export const antecedenteCriar = async () => {
    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente para cadastrar um novo antecedente
    const antecedente = replaceEspaceFieldsInObject(new AntecedenteModel().createConstructor(store.getState().antecedente));
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post(
                "/antecedente/",
                //Body
                antecedente,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    if (resp.data.data === 'The email address is already in use by another account.') {
                        reject(resp.data.data);
                    }
                    reject(resp.data.message);
                }
            }).catch((error) => {
                console.log(error)
                reject(error)
            });
        } catch (error) {
            console.log(error)
            reject(error)
        }
    });
}
//Para não fazer uma nova requisição é chamada essa Function que pega o Antecedente desejado na lista da Redux
export const getAntecedente = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente vazia
            const antecedente = new AntecedentesModel().voidConstructor();
            resolve(antecedente);
        } else {
            var currentValue = store.getState().antecedentes;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    const antecedente = new AntecedentesModel().completeConstructor(item);
                    resolve(antecedente);
                }
            });
        }
    });
}
//Faz a requisição para atualizar a variavel ATIVO do antecedente para True or False;
export const antecedenteSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var antecedente = '';
        await getAntecedente(id).then(resp => {
            antecedente = resp;
        });
        //Se ele estiver Ativo passara a ser Falso e vice-versa
        antecedente.ativo = ativo ? false : true;
        try {
            var idToken = await getIdToken();
            request.patch(
                `/antecedente/${id}`,
                antecedente,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//########################LISTAGEM!################################################################################
export const ANTECEDENTE_LISTAR = 'ANTECEDENTE_LISTAR';
const antecedenteListar = (antecedentes) => ({
    type: ANTECEDENTE_LISTAR,
    antecedentes: antecedentes,
});

//Chama a requisição para listar todos antecedentes
export const antecedentesLista = (callback) => {
    return async dispatch => {
        const arrAntecedentes = [];
        var idToken = await getIdToken();
        request
            .get("/antecedentes",
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                console.log(resp)
                resp.data.data.map(antecedente => {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    let antecedenteModel = new AntecedentesModel().completeConstructor(antecedente);
                    arrAntecedentes.push(antecedenteModel);
                }
                )
                //Envia o array de antecedentes para a redux
                callback(arrAntecedentes);
                return dispatch(antecedenteListar(arrAntecedentes));
            }).catch(error => {
                console.log(error)
            });
    }
}
