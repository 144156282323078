import {
    CLIENTES_SET_FIELD,
    CLIENTES_SET_CURRENT, 
    CLIENTES_SET_CURRENT_LIST_PARCEIRO,
    CLIENTES_SET_CURRENT_SEXO
} from "../actions";
import ClienteModel from "../Model/clienteModel";

const INITIAL_STATE =  new ClienteModel().voidConstructor();

export default function associadoReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case CLIENTES_SET_CURRENT:
            return action.cliente;
        case CLIENTES_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        case CLIENTES_SET_CURRENT_SEXO:
            const newState2 = { ...state, sexo : action.value};
            return newState2;
        case CLIENTES_SET_CURRENT_LIST_PARCEIRO:
            //let tt = { ...doc.data(), id:  doc.id};
            const newState3 = { ...state, parceiros : action.value};
            return newState3; 
        default:
            return state;
    }
}