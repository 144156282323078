import { ESPECIALIDADE_SET_CURRENT, ESPECIALIDADE_SET_FIELD } from '../actions/especialidadeAction';

import
 EspecialidadeModel from '../Model/especialidadeModel';

const INITIAL_STATE_ESPECIALIDADE = new EspecialidadeModel().voidConstructor();

export default function especialidadeReducer(state = INITIAL_STATE_ESPECIALIDADE, action) {
    switch (action.type) {
         case  ESPECIALIDADE_SET_CURRENT:
            return action.especialidade;
        case ESPECIALIDADE_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}