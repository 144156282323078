import { getColor } from "../utils/foto";

class ClienteModel {
    constructor(id,ativo, bairro, celular, cep, complemento, cpf, dataNasc, email, idParceiro, isCliente, isUserAutenticacao, lat, long, logradouro,municipio, nome, nomeMae,numero, idPaciente,nomeParceiro,rg, sexo,uf) {
        this.ativo = ativo;
        this.bairro = bairro;
        this.celular = celular;
        this.cep = cep;
        this.complemento = complemento;
        this.cpf = cpf;
        this.dataNasc = dataNasc;
        this.email = email;
        this.idParceiro = idParceiro;
        this.isCliente = isCliente;
        this.isUserAutenticacao = isUserAutenticacao;
        this.lat = lat;
        this.logradouro = logradouro;
        this.long = long;
        this.municipio = municipio;
        this.nome = nome;
        this.nomeMae = nomeMae;
        this.numero = numero;
        this.idPaciente = idPaciente;
        this.nomeParceiro = nomeParceiro;
        this.rg =rg;
        this.sexo = sexo;
        this.uf = uf;
        this.id = id;
    }

    //Serve tanto para montar a lista quanto para atualizar um Cliente
    completeConstructor(clienteModel){
        var cliente = {
            ativo : clienteModel.ativo,
            bairro : clienteModel.bairro,
            celular : clienteModel.celular,
            cep : clienteModel.cep,
            complemento : clienteModel.complemento,
            cpf : clienteModel.cpf,
            dataNasc : clienteModel.dataNasc,
            email : clienteModel.email,
            idParceiro : clienteModel.idParceiro,
            isCliente : clienteModel.isCliente,
            isUserAutenticacao : clienteModel.isUserAutenticacao,
            lat : clienteModel.lat,
            logradouro : clienteModel.logradouro,
            long : clienteModel.long,
            municipio : clienteModel.municipio,
            nome : clienteModel.nome,
            nomeMae : clienteModel.nomeMae,
            numero : clienteModel.numero,
            idPaciente : clienteModel.idPaciente,
            nomeParceiro : clienteModel.nomeParceiro,
            rg: clienteModel.rg,
            sexo: clienteModel.sexo,
            uf: clienteModel.uf, 
            id: clienteModel.id,
            color: clienteModel.color
        }
        return cliente;
    }
    //Monta um objeto vazio com o intuito de fazer o cadastro do CLiente
    voidConstructor(){
        var cliente = {
            ativo : true,
            bairro : '',
            celular : '',
            cep : '',
            complemento : '',
            cpf : '',
            dataNasc : '',
            email : '',
            idParceiro : '',
            isCliente: false,
            isUserAutenticacao: false,
            lat : '',
            logradouro : '',
            long : '',
            municipio : '',
            nome : '',
            nomeMae : '',
            numero : '',
            idPaciente : '',
            nomeParceiro : '',
            rg: '',
            sexo: '',
            uf: '', 
            id: '',
            color: getColor(),
        }
        return cliente;
    }

    createConstructor(clienteModel){
        var cliente = {
            ativo : clienteModel.ativo,
            bairro : clienteModel.bairro,
            celular : clienteModel.celular,
            cep : clienteModel.cep,
            complemento : clienteModel.complemento,
            cpf : clienteModel.cpf,
            dataNasc : clienteModel.dataNasc,
            email : clienteModel.email,
            idParceiro : clienteModel.idParceiro,
            isCliente : clienteModel.isCliente,
            isUserAutenticacao : clienteModel.isUserAutenticacao,
            lat : clienteModel.lat,
            logradouro : clienteModel.logradouro,
            long : clienteModel.long,
            municipio : clienteModel.municipio,
            nome : clienteModel.nome,
            nomeMae : clienteModel.nomeMae,
            numero : clienteModel.numero,
            idPaciente : clienteModel.idPaciente,
            nomeParceiro : clienteModel.nomeParceiro,
            rg: clienteModel.rg,
            sexo: clienteModel.sexo,
            uf: clienteModel.uf, 
            id: clienteModel.id,
            color: clienteModel.color

        }
        return cliente; 
    }

}
export default ClienteModel;