import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import HipoteseModel from '../Model/hipoteseModel.js';
import { getIdToken, request } from '.';
import { replaceEspace, replaceEspaceFieldsInObject } from '../utils/utils.js';

//Muda um campo especifico da hipotese atual na redux
export const HIPOTESE_SET_FIELD = 'HIPOTESE_SET_FIELD';
export const hipoteseSetField = (field, value) => {
    return {
        type: HIPOTESE_SET_FIELD,
        field,
        value,
    }
}
//Muda a hipotese atual na redux
export const HIPOTESE_SET_CURRENT = 'HIPOTESE_SET_CURRENT';
export const hipoteseSetCurrent = hipotese => ({
    type: HIPOTESE_SET_CURRENT,
    hipotese
});

//Para não fazer uma nova requisição é chamada essa Function que pega a hipotese desejado na lista da Redux
export const getHipotese = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Hipotese e chama o metodo que constrói a Classe de hipotese vazia
            const hipotese = new HipoteseModel().voidConstructor();
            resolve(hipotese);
        } else {
            var currentValue = store.getState().hipotesesList;
            console.log(currentValue)
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model hipotese e chama o metodo que constrói a Classe de hipotese
                    const hipotese = new HipoteseModel().completeConstructor(item);
                    resolve(hipotese);
                }
            });
        }
    });
}


// REST API Leitura Lista:
// app.get('/hipotesesdiagnosticas',verifyHeaderToken, hipoteseDiagnosticaController.getAllHipoteseDiagnosticas);

// REST API Leitura ITEM
// app.get('/hipotesediagnostica/:hipoteseId',verifyHeaderToken, hipoteseDiagnosticaController.getByIdHipoteseDiagnostica);

// REST API ADD
// app.post('/hipotesediagnostica',verifyHeaderToken, hipoteseDiagnosticaController.addHipoteseDiagnostica);

// REST API UPDATE
// app.patch('/hipotesediagnostica/:hipoteseId',verifyHeaderToken, hipoteseDiagnosticaController.updateHipoteseDiagnostica);  



//Faz a requisição para atualizar a variavel ATIVO da hipotese para True or False;
export const hipoteseSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var hipotese = await getHipotese(id);
        hipotese.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/hipotesediagnostica/${id}`,
                hipotese,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//Envia o hipotese para tela de Create ou Update 
export const hipoteseSetAtual = (id) => async dispatch => {
    if (id == null) {
        var hipotese = new HipoteseModel().voidConstructor();
        dispatch(hipoteseSetCurrent(hipotese));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/hipotesediagnostica/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const hipotese = new HipoteseModel().completeConstructor(resp.data.data);
                dispatch(hipoteseSetCurrent(hipotese));
            }).catch(error => {
                console.log(error)
            });
    }
}

//Faz a requisição para atualizar a hipotese
export const hipoteseAtualizar = async (idHipotese) => {
    //Faz a chamada do model hipotese e chama o metodo que constrói a Classe de hipotese para atualizar
    var hipoteseModel = replaceEspaceFieldsInObject(new HipoteseModel().completeConstructor(store.getState().hipotese));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.patch(
                `/hipotesediagnostica/${hipoteseModel.id}`,
                //Body
                hipoteseModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp);
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//Faz a requisição para cadastrar a hipotese
export const hipoteseCriar = async () => {
    //Faz a chamada do model hipotese e chama o metodo que constrói a Classe de hipotese para cadastrar uma nova hipotese
    const hipoteseModel = replaceEspaceFieldsInObject(new HipoteseModel().createConstructor(store.getState().hipotese));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post(
                "/hipotesediagnostica/",
                //Body
                hipoteseModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            console.log(error)
            reject(error)
        }
    });
}

//########################LISTAGEM!################################################################################
export const HIPOTESE_LISTAR = 'HIPOTESE_LISTAR';
const hipoteseListar = (hipotese) => ({
    type: HIPOTESE_LISTAR,
    hipoteses: hipotese,
});

//Chama a requisição para listar todas hipoteses
export const hipotesesLista = (callback) => {
    return async dispatch => {
        const arrHipoteses = [];
        var idToken = await getIdToken();
        request
            .get("/hipotesesdiagnosticas", {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(hipotese => {
                    //Faz a chamada do model Hipotese e chama o metodo que constrói a Classe de Hipotese
                    let hipoteseModel = new HipoteseModel().completeConstructor(hipotese)
                    arrHipoteses.push(hipoteseModel);
                })
                //Envia o array de hipoteses para a redux
                callback(arrHipoteses);
                return dispatch(hipoteseListar(arrHipoteses));
            }).catch(error => {
                console.log(error)
            });
    }
};

//Chama a requisição para listar todas hipoteses e converte para encaixar em um elemento de select
export const hipotesesListaToSelect = () => {
    return async dispatch => {
        var item = [];
        var idToken = await getIdToken();
        request
            .get("/hipotesesdiagnosticas",
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then((resp) => {
                resp.data.data.map(hipotese => {
                    //Faz a chamada do model hipotese e chama o metodo que constrói a Classe de hipotese
                    let tt = {
                        value: hipotese.id,
                        label: hipotese.nome,
                        descricao: hipotese.descricao,
                        codigoCID: hipotese.codigoCID,
                        color: "#00B8D9",
                    }
                    item.push(tt);;
                })
                //Envia o array de hipoteses para a redux
                dispatch(hipoteseListar(item));
            }).catch(error => {
                console.log(error)
            });
    }
}
export default hipoteseAtualizar