import { PERFIL_LISTAR	} from '../actions';

const INITIAL_STATE = {
    perfis : {}
}

export default function perfilListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case PERFIL_LISTAR:
            return action.perfis;
		default:
			return state;
	}
}


