import ClinicaDigital from '../Model/clinicaDigitalModel.js';
import axios from "axios";
import Cripto from '../utils/cripto.js';
import { getIdToken, request } from './index.js';
import { store } from '../index.js';
import { dispatch } from 'rxjs/internal/observable/pairs';
import Translate from '../utils/Translate.js';

const urlSoha = Cripto.DecryptedStorage("urlAPI");
const tokenAPI = Cripto.DecryptedStorage("tokenAPI");

//Chama a API Da Soha para Listar as Clinicas Digitais
export const getListaClinicaDigitais = () => {
    var cabines = [];
    console.log(`${urlSoha}booths`)
    return (
        axios.get(`${urlSoha}booths`,
            {
                headers: {
                    Authorization: `Basic ${tokenAPI}`,
                }
            }
        ).then(success => {
            success.data.data.map(c => {
                var cItem = {
                    value: c.id,
                    label: c.description,
                    address: c.address,
                };
                cabines.push(cItem);
            })
            return cabines;
        }).catch(error => {
            console.log(error)
            console.log("VEIO AQUI")
            return [];
        })
    );
}

//Chama a API Da Soha para ver detalhes de uma Clinica Digital específica
export const getDevicesClinicaDigital = (idClinicaDigital) => {
    var devices = [];
    return (
        axios.get(`${urlSoha}booths/${idClinicaDigital}`, {
            headers: {
                Authorization: `Basic ${tokenAPI}`,
            }
        }).then(resp => {
            resp.data.devices.map(device => {
                var deviceItem = {
                    deviceName: device.name,
                    id: device.id,
                    isLeitura: false,
                    value: "",
                };
                devices.push(deviceItem)
            });
            return devices;
        }).catch(error => {
            return error
        })

    )
};

//Muda um campo especifico da cabine atual na redux
export const ESTACAO_SET_FIELD = 'ESTACAO_SET_FIELD';
export const estacaoSetField = (field, value) => {
    return {
        type: ESTACAO_SET_FIELD,
        field,
        value,
    }
}

export const ESTACAO_ADD_HORARIO = 'ESTACAO_ADD_HORARIO';
export const estacaoAddHorario = (value) => {
    return {
        type: ESTACAO_ADD_HORARIO,
        value
    }
};

export const ESTACAO_UPDATE_HORARIO = 'ESTACAO_UPDATE_HORARIO';
export const estacaoUpdateHorario = (index, value) => {
    return {
        type: ESTACAO_UPDATE_HORARIO,
        index,
        value
    }
}

export const ESTACAO_REMOVE_HORARIO = 'ESTACAO_REMOVE_HORARIO';
export const estacaoRemoveHorario = (value) => {
    return {
        type: ESTACAO_REMOVE_HORARIO,
        value,
    }
}

//Aqui seta a Cabine Atual e manda para a redux para editar ler ou cadastrar uma cabine nova
export const ESTACAO_SET_CURRENT = 'ESTACAO_SET_CURRENT';
const estacaoSetCurrent = estacao => ({
    type: ESTACAO_SET_CURRENT,
    estacao
});

//Faz a requisição que puxa a cabine requirida para tela de Create ou Update 
export const estacaoSetAtual = (idParceiro, idClinicaDigital) => async dispatch => {
    if (idClinicaDigital === undefined || idParceiro === undefined) {
        var clinicaDigital = new ClinicaDigital().voidConstructor();
        dispatch(estacaoSetCurrent(clinicaDigital));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/clinicadigital/${idClinicaDigital}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                const clinicaDigital = new ClinicaDigital().completeConstructor(resp.data.data);
                console.log(clinicaDigital);
                if (clinicaDigital.horarios === undefined) {
                    clinicaDigital.horarios = [];
                }
                dispatch(estacaoSetCurrent(clinicaDigital));
            }).catch(error => {
                return error
            });
    }
}

//Faz a requisição para atualizar a Clinica Digital
export const estacaoAtualizar = async () => {
    //Faz a chamada do model ClinicaDigital e chama o metodo que constrói a Classe de ClinicaDigital
    var clinicaModel = new ClinicaDigital().createConstructor(store.getState().estacao);
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(
                `/parceiro/${clinicaModel.parceiroId}/clinicadigital/${clinicaModel.id}`,
                clinicaModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}
//Faz a requisição para cadastrar o antecedente
export const estacaoCriar = async (idClinica) => {
    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente para cadastrar um novo antecedente
    var estacao = new ClinicaDigital().createConstructor(store.getState().estacao);
    estacao.id = idClinica;
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post(
                `/parceiro/${estacao.parceiroId}/clinicadigital`,
                estacao,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//Para não fazer uma nova requisição é chamada essa Function que pega a Clinica desejado na lista da Redux
export const getClinicaDigital = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente vazia
            const clinicaDigitalModel = new ClinicaDigital().voidConstructor();
            resolve(clinicaDigitalModel);
        } else {
            var currentValue = store.getState().estacoes.estacoesDisponiveis;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    const clinicaDigitalModel = new ClinicaDigital().completeConstructor(item);
                    resolve(clinicaDigitalModel);
                }
            });
        }
    });
}

export const estacaoSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var clinicaDigital = '';
        await getClinicaDigital(id[1]).then(resp => {
            clinicaDigital = resp;
        });
        //Se ele estiver Ativo passara a ser Falso e vice-versa
        clinicaDigital.ativo = ativo ? false : true;
        try {
            var idToken = await getIdToken();
            request.patch(
                `/parceiro/${id[0]}/clinicadigital/${id[1]}`,
                clinicaDigital,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                resolve(resp.data.statusOk);
            }).catch((error) => {
                console.log(error)
                reject();
            });
        } catch (error) {
            console.log(error)
            reject();
        }
    });
}

//#########################LISTAGENS################################################
export const ESTACAO_LISTAR = 'ESTACAO_LISTAR';
const estacoesListar = (estacoes) => ({
    type: ESTACAO_LISTAR,
    estacoes: estacoes,
});

//Chama a requisição para listar todas Cabines Digitais

export const getDevices = (idParceiro) => {
    return async dispatch => {
        var clinicaDigitalArr = [];
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/clinicasdigitais`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                resp.data.data.map(parceiro => {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    let antecedenteModel = new ClinicaDigital().completeConstructor(parceiro);
                    clinicaDigitalArr.push(antecedenteModel);
                    dispatch(estacoesListar(clinicaDigitalArr));
                }
                )
                //Envia o array de EstacoesDisponiveis para a redux
            }).catch(error => {
                return;
            });
    }
};
//########################LISTAGEM!################################################################################
export const ESTACAO_LISTAR_DISPONIVEIS = 'ESTACAO_LISTAR_DISPONIVEIS';
const estacoesListarDisponiveis = (field, value,) => ({
    type: ESTACAO_LISTAR_DISPONIVEIS,
    field,
    value,
});

//Chama a requisição para listar todas Clinicas Digitais e passar para Select
export const estacaoListarDisponiveis = (idParceiro) => {
    return async dispatch => {
        var item = [];
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/clinicasdigitais`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                resp.data.data.map(parceiro => {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    var tipo = '';
                    if(parceiro.tipo !== '' && parceiro.tipo !== undefined) {
                        tipo = parceiro.tipo;
                    }else{
                        tipo = 'clinica'
                    }
                    let antecedenteModel = { value: parceiro.id, label: parceiro.nome + " - " + Translate.translate(tipo) };
                    item.push(antecedenteModel);
                }
                )
                //Envia o array de EstacoesDisponiveis para a redux
                dispatch(estacoesListarDisponiveis('estacoesDisponiveis', item));
            }).catch(error => {
                return error;
            });
    }
}

export const estacaoListar = (idParceiro, callback) => {
    return async dispatch => {
        var item = [];
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/clinicasdigitais`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                console.log(resp)
                resp.data.data.map(parceiro => {
                    //Faz a chamada do model Antecedente e chama o metodo que constrói a Classe de antecedente
                    item.push(parceiro);
                }
                )
                //Envia o array de EstacoesDisponiveis para a redux
                callback(item)
                dispatch(estacoesListarDisponiveis('estacoesDisponiveis', item));
            }).catch(error => {
                return error;
            });
    }
}