class GerarAgendaMedicoModel {
    constructor(id, numerodias) {
        this.numerodias = numerodias;
      
    };
    completeConstructor(agendaMedicoModel) {
        var agendaMedico = {
            numerodias: agendaMedicoModel.numerodias
        }
        return agendaMedico;
    }
    createConstructor(agendaMedicoModel) {
        var agendaMedico = {
            numerodias: agendaMedicoModel.numerodias
        }
        return agendaMedico;
    }
    voidConstructor() {
        var agendaMedico = {
            numerodias: '',
        }
        return agendaMedico;
    }
}
export default GerarAgendaMedicoModel;