import { TURNO_LISTAR	} from '../actions';

const INITIAL_STATE = {
    turnos : [] 
}

export default function turnoListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case TURNO_LISTAR:
            return action.turnos;
		default:
			return state;
	}
}
