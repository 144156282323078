class AntecedenteModel {
    constructor(id, antecedente, ativo) {
        this.id = id;
        this.antecedente = antecedente;
        this.ativo = ativo;
    };
    completeConstructor(antecedenteModel) {
        var antecedente = {
            id: antecedenteModel.id,
            antecedente: antecedenteModel.antecedente.trim(),
            ativo: antecedenteModel.ativo,
        }
        return antecedente;
    }
    createConstructor(antecedenteModel) {
        var antecedente = {
            antecedente: antecedenteModel.antecedente.trim(),
            ativo: antecedenteModel.ativo,
        }
        return antecedente;
    }
    voidConstructor() {
        var antecedente = {
            id: '',
            antecedente: '',
            ativo: true,
        }
        return antecedente;
    }
}
export default AntecedenteModel;