import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import ParceiroModel from '../Model/parceiroModel';
import { replaceEspaceFieldsInObject } from '../utils/utils.js';
import { getIdToken, request } from './index.js';

//Muda um campo especifico do Parceiro atual na redux
export const PARCEIRO_SET_FIELD = 'PARCEIRO_SET_FIELD';
export const parceiroSetField = (field, value) => {
    return {
        type: PARCEIRO_SET_FIELD,
        field,
        value,
    }
}
//Muda o Parceiro atual na redux
export const PARCEIRO_SET_CURRENT = 'PARCEIRO_SET_CURRENT';
const parceiroSetCurrent = parceiro => ({
    type: PARCEIRO_SET_CURRENT,
    parceiro
});

//Envia o antecedente para tela de Create ou Update 
export const parceiroSetAtual = (id) => async dispatch => {
    if (id == null) {
        var parceiro = new ParceiroModel().voidConstructor();
        dispatch(parceiroSetCurrent(parceiro));
    } else {
        var idToken = await getIdToken();
        request.get(`/parceiro/${id}`, {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        }).then(resp => {
            const parceiro = new ParceiroModel().completConstructor(resp.data.data);
            dispatch(parceiroSetCurrent(parceiro));
        }).catch(error => {
            console.log(error);
        })
    }
}

//Faz a requisição para atualizar o Parceiro
export const parceiroAtualizar = async () => {
    //Faz a chamada do model Parceiro e chama o metodo que constrói a Classe de Parceiro
    var parceiroModel = new ParceiroModel().completConstructor(store.getState().parceiro);
    parceiroModel = replaceEspaceFieldsInObject(parceiroModel)
    parceiroModel.nome.trim();
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(`/parceiro/${parceiroModel.id}`, parceiroModel, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
                .then(resp => {
                    if (resp.data.statusOk) {
                        resolve(true)
                    } else {
                        reject(resp.data.message);
                    }
                }).catch(error => {
                    reject(error)
                })
        } catch (error) {
            reject(error)
        }
    });
};

//Faz a requisição para cadastrar o Parceiro
export const parceiroCriar = async () => {
    //Faz a chamada do model Parceiro e chama o metodo que constrói a Classe de Parceiro para cadastrar um novo Parceiro
    var parceiroModel = new ParceiroModel().completConstructor(store.getState().parceiro);
    parceiroModel = replaceEspaceFieldsInObject(parceiroModel)
    parceiroModel.nome.trim();
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post("/parceiro/", parceiroModel, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
                .then(resp => {
                    if (resp.data.statusOk) {
                        resolve(true)
                    } else {
                        reject(resp.data.message);
                    }
                }).catch(error => {
                    reject(error)
                })
        } catch (error) {
            reject(error)
        }
    });
}

export const getParceiro = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Parceiro e chama o metodo que constrói a Classe de Parceiro vazia
            const parceiro = new ParceiroModel().voidConstructor();
            resolve(parceiro);
        } else {
            var currentValue = store.getState().parceiros;
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model Parceiro e chama o metodo que constrói a Classe de Parceiro
                    const parceiro = new ParceiroModel().completConstructor(item);
                    resolve(parceiro);
                }
            });
        }
    });
}

//Faz a requisição para atualizar a variavel ATIVO do Parceiro para True or False;
export const parceiroSetAtivo = async (idParceiro, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var parceiro;
        await getParceiro(idParceiro).then(resp => {
            parceiro = resp;
        });
        //Se ele estiver Ativo passara a ser Falso e vice-versa
        parceiro.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/parceiro/${parceiro.id}`,
                //Body
                parceiro,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//########################LISTAGEM!################################################################################
export const PARCEIRO_LISTAR = 'PARCEIRO_LISTAR';
const parceiroListar = (parceiros) => ({
    type: PARCEIRO_LISTAR,
    parceiros: parceiros,
});
//Chama a requisição para listar todos Parceiros
export const parceirosLista = (callback) => {
    return async dispatch => {
            const arrParceiros = [];
            var idToken = await getIdToken();
            request
                .get("/parceiros", {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
                .then(resp => {
                    resp.data.data.map(item => {
                        //Faz a chamada do model Parceiro e chama o metodo que constrói a Classe de Parceiro
                        let parceiro = new ParceiroModel().completConstructor(item);
                        arrParceiros.push(parceiro)
                    })
                    //Envia o array de antecedentes para a redux
                    callback(arrParceiros)
                    return dispatch(parceiroListar(arrParceiros));
                }).catch(error => {
                    console.log(error)
                });
    }
}
