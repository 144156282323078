import {
    TURNO_SET_FIELD,
    TURNO_SET_CURRENT
} from '../actions';
import TurnoModel from '../Model/turnoModel';

const INITIAL_STATE_TURNO = new TurnoModel().voidConstructor();

export default function turnoReducer(state = INITIAL_STATE_TURNO, action) {
    switch (action.type) {
         case  TURNO_SET_CURRENT:
            return action.turno;
        case TURNO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}