
class DependenteModel {
    constructor(nome,rg,cpf,dataNasc,nomeMae,ativo,parentesco,sexo, id){
        this.nome = nome;
        this.rg = rg;
        this.cpf = cpf;
        this.dataNasc = dataNasc;
        this.nomeMae = nomeMae;
        this.ativo = ativo;
        this.parentesco = parentesco;
        this.sexo = sexo;
        this.id = id;
    }
    voidConstructor() {
        var dependente = {
            nome: "",
            rg: "",
            cpf: "",
            dataNasc: "",
            nomeMae: "",
            ativo: true,
            parentesco: "",
            sexo: "",
            id: "",
        }
        return dependente;
    }
    createConstructor(dependente) {
        let dependenteModel = {
            nome: dependente.nome,
            rg: dependente.rg,
            cpf: dependente.cpf,
            dataNasc: dependente.dataNasc,
            nomeMae: dependente.nomeMae,
            ativo: dependente.ativo,
            parentesco: dependente.parentesco,
            sexo: dependente.sexo
        }; 
        return dependenteModel;
    }

    completeConstructor(dependente) {
        let dependenteModel = {
            id: dependente.id,
            nome: dependente.nome,
            rg: dependente.rg,
            cpf: dependente.cpf,
            dataNasc: dependente.dataNasc,
            nomeMae: dependente.nomeMae,
            ativo: dependente.ativo,
            parentesco: dependente.parentesco,
            sexo: dependente.sexo,
        }
        return dependenteModel;
    }
}
export default DependenteModel;