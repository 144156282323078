import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from '../index.js'
import { agendaSetField, getIdToken, replaceEspaceFieldsInObject, request } from '.';
import { AgentePenitenciarioModel } from '../Model/AgentePenitenciarioModel';

export const AGENTE_PENITENCIARIO_SET_FIELD = 'AGENTE_PENITENCIARIO_SET_FIELD';
export const agentePenitenciarioSetField = (field, value) => {
    return {
        type: AGENTE_PENITENCIARIO_SET_FIELD,
        field,
        value,
    }
}

export const AGENTE_PENITENCIARIO_SET_CURRENT = 'AGENTE_PENITENCIARIO_SET_CURRENT';
const agentePenitenciarioSetCurrent = agentePenitenciario => ({
    type: AGENTE_PENITENCIARIO_SET_CURRENT,
    agentePenitenciario
});
export const agentePenitenciarioSetAtual = (id) => async dispatch => {
    if (id === null) {
        var agentePenitenciario = new AgentePenitenciarioModel().voidConstructor();
        dispatch(agentePenitenciarioSetCurrent(agentePenitenciario));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/agentepenitenciario/${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
            .then(resp => {
                const agentePenitenciario = new AgentePenitenciarioModel().completeConstructor(resp.data.data);
                dispatch(agentePenitenciarioSetCurrent(agentePenitenciario));
            }).catch(error => {
                console.log(error)
            });
    }
}

export const agentePenitenciarioAtualizar = async (id) => {
    var agentePenitenciario = replaceEspaceFieldsInObject(new AgentePenitenciarioModel().completeConstructor(store.getState().agentePenitenciario));
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.patch(
                `/agentepenitenciario/${agentePenitenciario.id}`,
                agentePenitenciario,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}


export const agentePenitenciarioCriar = async (uid) => {
    var agentePenitenciario = replaceEspaceFieldsInObject(new AgentePenitenciarioModel().createConstructor(store.getState().agentePenitenciario));
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.post(
                `/agentepenitenciario`,
                agentePenitenciario,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    if (resp.data.data === 'The email address is already in use by another account.') {
                        reject(resp.data.data);
                    }
                    reject(resp.data.message);
                }
            }).catch((error) => {
                console.log(error);
                reject(error)
            });
        } catch (error) {
            console.log(error);

            reject(error)
        }
    });
}
export const getPacienteFromPenitenciaria = async (cpf, idParceiro) => {
    console.log('a')
    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            request.get(`/paciente/${cpf}/parceiro/${idParceiro}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }).then((resp) => {
                if(resp.data.statusOk){
                    console.log(resp.data.data);
                    resolve(resp.data.data);
                }
                reject(resp.data.message);
            }).catch((error) => {
                console.log(error);
                reject("Falha ao buscar paciente")
            })
        } catch (error) {
            reject("Falha ao buscar paciente")
        }
    })
}
export const getAgentePenitenciario = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            const agentePenitenciario = new AgentePenitenciarioModel().voidConstructor();
            resolve(agentePenitenciario);
        } else {
            var currentValue = store.getState().agentesPenitenciarios;
            currentValue.map((item) => {
                if (item.id === id) {
                    const agentePenitenciario = new AgentePenitenciarioModel().completeConstructor(item);
                    resolve(agentePenitenciario);
                }
            });
        }
    });
}
export const agentePenitenciarioSetAtivo = async (idAgente, ativo) => {
    var agentePenitenciario = await getAgentePenitenciario(idAgente);
    return await new Promise(async (resolve, reject) => {
        agentePenitenciario.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/agentepenitenciario/${idAgente}`,
                agentePenitenciario,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}
//##################################################################################
//#########################LISTAGENS################################################
//##################################################################################
export const AGENTE_PENITENCIARIO_LISTAR = 'AGENTE_PENITENCIARIO_LISTAR';
const agentesPenitenciarios = (agentesPenitenciarios) => ({
    type: AGENTE_PENITENCIARIO_LISTAR,
    agentesPenitenciarios: agentesPenitenciarios,
});

export const agentePenitenciarioListar = (callBack) => {
    return async dispatch => {
        var arrAdms = [];
        var idToken = await getIdToken();
        console.log('carregando')
        request.get('/agentespenitenciarios',
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        )
            .then((resp) => {
                resp.data.data.map(adm => {
                    let agentesPenitenciarios = new AgentePenitenciarioModel().completeConstructor(adm);
                    arrAdms.push(agentesPenitenciarios);
                });
                console.log('carregou')
                callBack(arrAdms)
                dispatch(agentesPenitenciarios(arrAdms));
            }).catch((error) => {
                console.log('deu erro')
                console.log(error)
                return error;
            });
    }
}
