import { ESTACAO_CONSULTA_SET_CURRENT_PACIENTE, ESTACAO_CONSULTA_SET_FIELD } from '../actions';
import SolicitacaoAtendimentoClinicaDigital from '../Model/SolicitacaoAtendimentoClinicaDigital';

const INITIAL_STATE = {
	paciente: {},
	atendimento: new SolicitacaoAtendimentoClinicaDigital().voidConstructor(),
	consulta: {
		status: ""
	}
}

export default function estacoesListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case ESTACAO_CONSULTA_SET_CURRENT_PACIENTE:
			const newState = { ...state };
			newState[action.field] = action.value;
			return newState;
		case ESTACAO_CONSULTA_SET_FIELD:
			const newState2 = { ...state };
			newState2.atendimento[action.field] = action.value;
			return newState2;
		default:
			return state;
	}
}