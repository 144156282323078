import { PRESCRICAO_LISTAR	} from '../actions';

const INITIAL_STATE = {
    prescricoes : [] 
}

export default function convenioListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case PRESCRICAO_LISTAR:
            return action.prescricoes;
		default:
			return state;
	}
}


