import { PARCEIRO_LISTAR	} from '../actions';

const INITIAL_STATE = [] 

export default function convenioListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case PARCEIRO_LISTAR:
            return action.parceiros;
		default:
			return state;
	}
}


