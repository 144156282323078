import {
    PARCEIRO_SET_FIELD,
    PARCEIRO_SET_CURRENT
} from '../actions';

const INITIAL_STATE_PARCEIRO = {
    ativo: true,
    bairro:"", 
    cep:"",
    cnpj:"",
    complemento:"",
    email:"",
    isPenitenciaria: false,
    logradouro:"",
    municipio:"",
    nome:"",
    numero:"",
    telefone:"",
    uf:"",
    id:"",
    parceiros: [

    ],
    parceirosDisponiveis: [
        {
            value: '',
            label: '',
        }
    ],
}

export default function convenioReducer(state = INITIAL_STATE_PARCEIRO, action) {
    switch (action.type) {
         case PARCEIRO_SET_CURRENT:
            return action.parceiro;
        case PARCEIRO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}

