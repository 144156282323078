
import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import { collectionData } from 'rxfire/firestore';
import Cripto from '../utils/cripto'
import { getIdToken, request } from '.';

export const CHAT_MESSAGES = 'CHAT_MESSAGES';
export const chatMessages = (msgs) => {
    return {
        type: CHAT_MESSAGES,
        chat: msgs,
    }
}
export const CHAT_SET_FIELD = 'CHAT_SET_FIELD';

export const ChatSetField = (field, value) => {
    return {
        type: CHAT_SET_FIELD,
        field,
        value,
    }
}

export const observableMessage = (isTriagem, medicoId, atendimentoId, fromScreen, that) => dispatch => {
    try {
        if (medicoId === undefined || medicoId == ''
            || atendimentoId === undefined || atendimentoId == '') {
        }
        if (atendimentoId === null) {

        }
        var collectionRef = '';
        if (isTriagem) {
            collectionRef = firebase.firestore().collection('enfermeiros').doc(medicoId).collection('atendimentos').doc(atendimentoId).collection('messages');
        } else {
            collectionRef = firebase.firestore().collection('medicos').doc(medicoId).collection('atendimentos').doc(atendimentoId).collection('messages');
        }
        collectionData(collectionRef, 'uid')
            .subscribe(msgs => {
                var listMessagens = [];
                msgs.map(doc => {
                    let msg = {
                        idFrom: doc.idFrom,
                        idTo: doc.idTo,
                        content: doc.content,
                        notifica: doc.notifica,
                        notificacaoChat: doc.notificacaoChat,
                        replica: doc.replica,
                        timestamp: doc.timestamp,
                        type: doc.type,
                    };
                    listMessagens.push(msg)
                });
                dispatch(chatMessages(listMessagens));
                try {
                    if (fromScreen) {
                        if (listMessagens[listMessagens.length - 1].idFrom !== medicoId) {
                             that.getTotalMensagemChat()
                        }
                    } else {
                        that.callBackScroll(listMessagens[listMessagens.length - 1]);
                    }
                } catch (error) {
                    console.log(error)
                }

            });
    } catch (error) {
        console.log(error)
    }
}

export const chatAddMessage = async (idPaciente, idAtendimento, msg) => {
    var uid = Cripto.DecryptedStorage('uid');
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(`/medico/${uid}/atendimento/${idAtendimento}/sendmessagepaciente`,
            {
                idFrom: uid,
                idTo: idPaciente,
                content: msg,
                type: 0,
                notifica: true,
                replicar: true,
            },
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        )
            .then(function (result) {
                try {
                    if (result.data.statusOk) {
                        resolve(result.data.message);
                    } else {
                        resolve(result.data.message);
                    }
                } catch (e) {

                    reject("Erro ao chamar inicio de atendimento");

                }
            }
            )
            .catch(function (erro) {

                reject("Erro ao chamar inicio de atendimento");
            })
    })
}

export const chatAddMessageTriagem = async (pacienteId, atendimentoId, msg) => {
    var uid = Cripto.DecryptedStorage('uid');
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(`/enfermeiro/${uid}/atendimento/${atendimentoId}/sendmessagepaciente`, {
            idFrom: uid,
            idTo: pacienteId,
            content: msg,
            type: 0,
            notifica: true,
            replicar: true,
        },
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        )
            .then(result => {
                try {
                    if (result.data.statusOk) {
                        resolve(result.data.message);
                    } else {
                        reject();
                    }
                } catch (e) {

                    reject();

                }
            }
            )
            .catch(erro => {
                reject();
            })
    })
}

export const chatCount = (isTriagem, medicoId, atendimentoId) => {
    try {
        if (medicoId === undefined || medicoId == ''
            || atendimentoId === undefined || atendimentoId == '') {
        }
        if (atendimentoId === null) {

        }

        var totalMensagensRecebidas = 0;
        var collectionRef = '';

        if (isTriagem) {
            collectionRef = firebase.firestore().collection('enfermeiros').doc(medicoId).collection('atendimentos').doc(atendimentoId).collection('messages');
        } else {
            collectionRef = firebase.firestore().collection('medicos').doc(medicoId).collection('atendimentos').doc(atendimentoId).collection('messages');
        }
        collectionData(collectionRef, 'uid')
            .subscribe(msgs => {
                msgs.map(doc => {
                    if (doc.idFrom !== medicoId) {
                        if (doc.lida === false) {
                            totalMensagensRecebidas++
                        }
                    }
                });
                return totalMensagensRecebidas;
            });
    } catch (error) {

    }
}
