import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import { getIdToken, request } from '.';
import GerarAgendaMedicoModel from '../Model/gerarAgendaMedicoModel.js';
import { AgendaFactory } from '../Model/AgendaFactory.js';
import Cripto from '../utils/cripto.js';

//Muda um campo especifico da agenda atual na redux
export const AGENDA_SET_FIELD = 'AGENDA_SET_FIELD';
export const agendaSetField = (field, value) => {
    return {
        type: AGENDA_SET_FIELD,
        field,
        value,
    }
}
//Muda o agenda atual na redux
export const AGENDA_SET_CURRENT = 'AGENDA_SET_CURRENT';
export const agendaSetCurrent = agenda => {
    return {
        type: AGENDA_SET_CURRENT,
        agenda
    }
};

//Para não fazer uma nova requisição é chamada essa Function que pega a agenda desejado na lista da Redux
export const getAgenda = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Agenda e chama o metodo que constrói a Classe de agenda vazia
            const agenda = new GerarAgendaMedicoModel().voidConstructor();
            resolve(agenda);
        } else {
            var currentValue = store.getState().agendasList;
            console.log(currentValue)
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model agenda e chama o metodo que constrói a Classe de agenda
                    const agenda = new GerarAgendaMedicoModel().completeConstructor(item);
                    resolve(agenda);
                }
            });
        }
    });
}


//Envia o agenda para tela de Create ou Update 
export const agendaSetAtual = (idMedico, agendamedicoId) => async dispatch => {
    if (agendamedicoId == null) {
        var agenda = new GerarAgendaMedicoModel().voidConstructor();
        dispatch(agendaSetCurrent(agenda));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/medico/${idMedico}/agenda/${agendamedicoId}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const agenda = new GerarAgendaMedicoModel().completeConstructor(resp.data.data);
                dispatch(agendaSetCurrent(agenda));
            }).catch(error => {
                console.log(error)
            });
    }
}

//Faz a requisição para atualizar o agenda
export const agendaAtualizar = async () => {
    //Faz a chamada do model agenda e chama o metodo que constrói a Classe de agenda para atualizar
    var agendaModel = store.getState().agendaAtendimento;
    agendaModel.nomeAgentePenitenciario = Cripto.DecryptedStorage("nome");
    agendaModel.colorAgentePenitenciario = Cripto.DecryptedStorage("color");
    agendaModel.idAgentePenitenciario = Cripto.DecryptedStorage("uid");
    agendaModel.status = 0;
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.patch(
                `/parceiro/${agendaModel.idParceiro}/agenda/${agendaModel.id}`,
                //Body
                agendaModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp);
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                console.log(error)
                reject(error)
            });
        } catch (error) {
            console.log(error)
            reject(error)
        }
    });
}

export const excluirAgenda = async (idMedico, agendamedicoId) => {
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.delete(
                `/medico/${idMedico}/agenda/${agendamedicoId}`,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    })
}

//Faz a requisição para cadastrar o agenda
export const agendaCriar = async (idMedico) => {
    //Faz a chamada do model agenda e chama o metodo que constrói a Classe de agenda para cadastrar um novo agenda
    var agendaModel = new GerarAgendaMedicoModel().completeConstructor(store.getState().agenda);
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post(
                `/medico/${idMedico}/geraragenda`,
                //Body
                agendaModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                console.log(error)
                reject(error)
            });
        } catch (error) {
            console.log(error)
            reject(error)
        }
    });
}

// app.get('/medico/:medicoId/agendas', agendaMedicoController.getAllAgendaMedicos);
// app.post('/medico/:medicoId/geraragenda', agendaMedicoController.gerarAgendaMedico);
// app.delete('/medico/:medicoId/agenda/:agendamedicoId', agendaMedicoController.deleteAgendaMedico);

//########################LISTAGEM!################################################################################
export const AGENDA_LISTAR = 'AGENDA_LISTAR';
const agendaListar = (agenda) => ({
    type: AGENDA_LISTAR,
    agendas: agenda,
});

//Chama a requisição para listar todos os agendas
export const agendasLista = (idMedico, callback) => {
    return async dispatch => {
        const arrAgendas = [];
        var idToken = await getIdToken();
        request
            .get(`medico/${idMedico}/agendas`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(agenda => {
                    arrAgendas.push(agenda);
                })
                //Envia o array de agendas para a redux
                callback(arrAgendas);
                return dispatch(agendaListar(arrAgendas));
            }).catch(error => {
                console.log(error)
            });
    }
};

export const agendaAtendimentoByParceiro = (idParceiro, callback) => {
    return async dispatch => {
        const arrAgendas = [];
        var idToken = await getIdToken();
        request
            .get(`/parceiro/${idParceiro}/agendas`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                console.log(resp.data.data)
                resp.data.data.map(agenda => {
                    arrAgendas.push(agenda);
                })
                //Envia o array de agendas para a redux
                callback(arrAgendas);
                return dispatch(agendaListar(arrAgendas));
            }).catch(error => {
                console.log(error)
            });
    }
}