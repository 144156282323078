import axios from "axios";
import firebase from "firebase";
import { storage } from "../config/firebasestorage";

import { getIdToken, request } from ".";
import { store } from "..";
import Documento from "../Model/Documento";
import { removeMaskFromCPF } from "../utils/cpf";
import Cripto from "../utils/cripto";
import Log from "../utils/log";
import { replaceEspace } from "../utils/utils";
import { PDFDocument } from "pdf-lib";
import { get } from "js-cookie";

export const montaObjetoDocumento = (tipo, textReceituarioEspecial, textReceituarioSimples, textSolicitacaoExames, textAtestadoObservacoes, relatorioMedico, atestadoperiodo, indicacaoClinica, nome, cpf) => {
  const atendimento = store.getState().atendimento;
  let objEnv = new Documento(tipo, "", 0, "", atendimento.nomeMedico, atendimento.crmMedico, Cripto.DecryptedStorage("medicoUf"), nome, cpf, Cripto.DecryptedStorage('uid'), atendimento.idAtendimento);
  if (objEnv.tipo == 'receituarioEspecial') {
    objEnv.texto = textReceituarioEspecial;
  } else if (objEnv.tipo == 'atestado') {
    objEnv.texto = textAtestadoObservacoes;
    objEnv.numeroDias = atestadoperiodo;
  } else if (objEnv.tipo == 'relatorioMedico') {
    objEnv.texto = relatorioMedico;
  } else if (objEnv.tipo == 'solicitacaoExames') {
    objEnv.texto = textSolicitacaoExames;
    objEnv.indicacaoClinica = indicacaoClinica;
  } else {
    objEnv.texto = textReceituarioSimples;
  }
  return objEnv;
}

export const geraDocumento = async (
  documentos,
  receituarioSimples,
  atestadoObservacoes,
  atestadoperiodo,
  receituarioEspecial,
  indicacaoClinica,
  solicitacaoExames,
  relatorioMedico
) => {
  try {
    var arrayDocumentos = [];
    var textReceituarioEspecial = receituarioEspecial;
    var textReceituarioSimples = receituarioSimples;
    var textSolicitacaoExames = solicitacaoExames;
    var textAtestadoObservacoes = atestadoObservacoes;
    const atendimento = store.getState().atendimento;
    var nome = atendimento.nomePaciente;
    if (atendimento.nomeDependente !== '') {
      nome = atendimento.nomeDependente;
    }
    var cpf = removeMaskFromCPF(atendimento.cpfPaciente);
    arrayDocumentos = documentos.map(item => { return montaObjetoDocumento(item, textReceituarioEspecial, textReceituarioSimples, textSolicitacaoExames, textAtestadoObservacoes, relatorioMedico, atestadoperiodo, indicacaoClinica, nome, cpf) });
    return await new Promise(async (resolve, reject) => {
      try {
        var token = getIdToken();
        let url = process.env.REACT_APP_URL_FUNCTIONS_ADDRESS + "api/gerardocumento";
        //let url = "http://localhost:3002/gerardocumento";
        console.log(arrayDocumentos);
        await axios.post(url, arrayDocumentos, {
          headers: { Authorization: `Bearer ${token}` },
        }).then(async (response) => {
          console.log(response.data.statusOk);
            if(response.data.statusOk){
              resolve(true);
            }
            reject(false)
        }).catch((err) => {
          reject(false)
          return;
        });
      } catch (err) {
        console.log(err);
        reject(false);
        return;
      }
    })
  } catch (ex) {
    console.log(ex);
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'geraDocumento', ex.message, "")
    return;
  }
};

const validaDocumento = async (pdf) => {
  return await new Promise((resolve, reject) => {
    try {
      var idAtendimento = Cripto.DecryptedStorage("id_consulta");
      var nameSplited = pdf.name.split("_");
      let nomeDoc = '';
      if (nameSplited[2] != 'especial') {
        let nome = nameSplited[0].trim();
        let nomeDocAdendo = nameSplited[1].trim();
        nomeDoc = nome + " " + nomeDocAdendo;
        let idAtendimentoSplited = nameSplited[2].trim();
        if (idAtendimento != idAtendimentoSplited) {
          resolve(nomeDoc)
        }
        resolve(nomeDoc);
      } else {
        let nome = nameSplited[0].trim();
        let nomeDocAdendo = nameSplited[1].trim();
        let nomePos3 = nameSplited[2].trim();
        nomeDoc = nome + " " + nomeDocAdendo + " " + " " + nomePos3;
        let idAtendimentoSplited = nameSplited[3].trim();
        if (idAtendimentoSplited !== idAtendimento) {
          resolve(nomeDoc)
        }
        resolve(nomeDoc);
      }
    } catch (error) {
      resolve(pdf.name)
    }
  });
}
const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}
const getTitlePDF = async (file) => {
  return new Promise(async (resolve, reject) => {
    try {
      var blob = await getBase64(file);
      const pdfDoc = await PDFDocument.load(blob, { ignoreEncryption: true });
      resolve(pdfDoc.getTitle())
    } catch (error) {
      reject()
    }
  });
}
export const uploadToFirebase = async (pdfList) => {
  try {
    return await new Promise(async (resolve, reject) => {
      var idAtendimento = Cripto.DecryptedStorage("id_consulta");
      let atendimento = store.getState().atendimento;
      var arrayDocs = atendimento.documentos;
      await Promise.all(pdfList.map(async (pdf) => {
        await validaDocumento(pdf)
          .then(async (nomeDoc) => {
            const uploadFile = storage
              .ref(`atendimentos/${idAtendimento}/${pdf.name}`)
              .put(pdf);
            await uploadFile.then(async (success) => {
              await storage
                .ref(`atendimentos/${idAtendimento}/`)
                .child(pdf.name)
                .getDownloadURL()
                .then(async (url) => {
                  var timestamp = Date.now();
                  var timestring = timestamp.toString();
                  let doc = {
                    id: timestring,
                    url: url,
                    nome: pdf.name,
                    label: nomeDoc
                  };
                  await arrayDocs.push(doc);
                })
                .catch((error) => {
                  console.log(error)
                  Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'uploadToFirebase1', error.message, "")
                  reject("Falha ao enviar os documentos");
                });
            });
          }).catch(error => {
            alert(error + " é um documento inválido!");
          });
      }));
      await SaveOnFirestore(arrayDocs, pdfList)
        .then(() => {
          console.log('Veio no SaveOnFirestore')
          resolve()
        }).catch(error => {
          reject("Falha ao enviar os documentos");
        })
    });
  } catch (ex) {
    console.log(ex);
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'uploadToFirebase2', ex.message, "")
  }
};
const SaveOnFirestore = async (arrayDocs, pdfList) => {
  return await new Promise(async (resolve, reject) => {
    try {
      var idAtendimento = Cripto.DecryptedStorage("id_consulta");
      var idMedico = Cripto.DecryptedStorage('uid');
      let atendimento = store.getState().atendimento;
      if (atendimento.enviouRelatorio === undefined || atendimento.enviouRelatorio === false) {
        pdfList.map(async pdf => {
          if (await getTitlePDF(pdf) === 'Relatório Médico') {
            await firebase
              .firestore()
              .collection("medicos")
              .doc(idMedico)
              .collection("atendimentos")
              .doc(idAtendimento)
              .update({
                enviouRelatorio: true
              })
          }
        })
      }
      await firebase
        .firestore()
        .collection("medicos")
        .doc(idMedico)
        .collection("atendimentos")
        .doc(idAtendimento)
        .update({
          documentos: arrayDocs,
          replicar: true, //array de objetos 
        }).then(async (success) => {
          resolve();
          //await setEnviouDocumentos()
        }).catch(error => {
          console.log(error)
        })
      await resolve();
    } catch (error) {
      reject();
    }
  });
}
export const setEnviouDocumentos = async () => {
  var idAtendimento = Cripto.DecryptedStorage("id_consulta");
  var idMedico = Cripto.DecryptedStorage('uid');
  firebase.firestore()
    .collection('medicos')
    .doc(idMedico)
    .collection('atendimentos')
    .doc(idAtendimento)
    .update({
      enviouDocumento: true,
      replica: true
    })
};