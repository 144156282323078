import { getColor } from "../utils/foto";

class MedicoModel {
    constructor(ativo, crm, cpf, dataNasc, email, fotoPerfil, idAtendimento, nivel, id, nome, rg, sexo, situacao, especialidades, status, uf, password, tempoAtendimento) {
        this.ativo = ativo;
        this.crm = crm;
        this.cpf = cpf;
        this.dataNasc = dataNasc;
        this.email = email;
        this.fotoPerfil = fotoPerfil;
        this.idAtendimento = idAtendimento;
        this.nome = nome;
        this.rg = rg;
        this.sexo = sexo;
        this.situacao = situacao;
        this.especialidades = especialidades;
        this.nivel = nivel;
        this.status = status;
        this.uf = uf;
        this.password = password;
        this.id = id;
        this.tempoAtendimento = tempoAtendimento;
    };

    voidConstructor() {
        var medicoModel = {
            id: '',
            ativo: true,
            crm: "",
            cpf: "",
            dataNasc: "",
            email: "",
            fotoPerfil: "",
            idAtendimento: "",
            nome: "",
            rg: "",
            sexo: "",
            situacao: "",
            especialidades: "",
            statusMedico: 0,
            uf: "",
            nivel: 0,
            password: "",
            color: getColor(),
            tempoAtendimento: 0,
        };
        return medicoModel
    };

    createConstructor(medico) {
        var medicoModel = {
            ativo: medico.ativo,
            crm: medico.crm,
            cpf: medico.cpf,
            dataNasc: medico.dataNasc,
            email: medico.email,
            fotoPerfil: medico.fotoPerfil,
            idAtendimento: medico.idAtendimento,
            nome: medico.nome,
            rg: medico.rg,
            sexo: medico.sexo,
            situacao: medico.situacao,
            especialidades: medico.especialidades,
            statusMedico: 0,
            nivel: medico.nivel,
            uf: medico.uf,
            password: medico.password,
            color: medico.color,
            tempoAtendimento: medico.tempoAtendimento,
        };
        return medicoModel
    };
    completeConstructor(medico) {
        var medicoModel = {
            ativo: medico.ativo,
            crm: medico.crm,
            cpf: medico.cpf,
            dataNasc: medico.dataNasc,
            email: medico.email,
            fotoPerfil: medico.fotoPerfil,
            idAtendimento: medico.idAtendimento,
            id: medico.id,
            nome: medico.nome,
            rg: medico.rg,
            sexo: medico.sexo,
            situacao: medico.situacao,
            especialidades: medico.especialidades,
            statusMedico: 0,
            uf: medico.uf,
            nivel: medico.nivel,
            color: medico.color,
            tempoAtendimento: medico.tempoAtendimento,
        };
        return medicoModel;
    };
}
export default MedicoModel;