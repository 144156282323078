import firebase from "firebase";
import { store } from "..";
//########################LISTAGEM!################################################################################
export const HIPOTESE_SET_FIELD = 'HIPOTESE_SET_FIELD';
export const hipoteseSetField = (field, value) => {
    return {
        type: HIPOTESE_SET_FIELD,
        field,
        value,
    }
};

export const hipoteseSetCurrent = (id) => dispatch => {
    if (id === null) {
        dispatch(hipoteseSetField('hipotese',{}));
    } else {
        var currentValue = store.getState().hipoteses.hipoteses;
        currentValue.map((item) => {
            if (item.value === id) {
                dispatch(hipoteseSetField('hipotese',item));
            }
        });
    }
}

export const hipoteseListaToSelect = () => {
    return async dispatch => {
        var arrHipotese = [];
        var hipoteses = await firebase.firestore().collection('hipoteseDiagnostica').get();
        hipoteses.docs.forEach(item => {
            //console.log(item.data());
            if (item.data().ativo) {
                var obj = {
                    label: item.data().nome,
                    value: item.data().codigoCID,
                    descricao: item.data().descricao
                }
                arrHipotese.push(obj)
            }
        })
        dispatch(hipoteseSetField('hipoteses', arrHipotese));
    }
}