import {
    TEC_ENFERMAGEM_SET_FIELD,
    TEC_ENFERMAGEM_SET_CURRENT,
} from '../actions';
import TecEnfermagemModel from '../Model/tecEnfermagemModel';

var tecEnfermagem = new TecEnfermagemModel().voidConstructor();
tecEnfermagem.statusTecEnfermagem = "";

const INITIAL_STATE = tecEnfermagem;;

export default function enfermeiroReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case TEC_ENFERMAGEM_SET_CURRENT:
            return action.tecEnfermagem;
        case TEC_ENFERMAGEM_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}