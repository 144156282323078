import { Redirect } from 'react-router-dom';
import React from 'react';
import Cripto from '../utils/cripto'



import items from '../menu-items';
import itemsTodosMenus from '../menuCompleto';
import Translate from '../utils/Translate';


export const ADMINISTRADOR = 'administradores';
export const MEDICO = 'medicos';
export const OPERADOR = 'operadores';
export const telasAplicacao = [
    
    { menu: "Cadastros", submenu: "CadMedicos", descricao: Translate.translate("descricaoCadastroMedicos") },
    { menu: "Cadastros", submenu: "CadMedicosListar", descricao: Translate.translate("descricaoListagemMedicos") },

    { menu: "Cadastros", submenu: "CadHorarioAgenda", descricao: Translate.translate("descricaoCadastroHorario") },
    { menu: "Cadastros", submenu: "CadMedicosHorarioListar", descricao: Translate.translate("descricaoListagemHorario") },

    { menu: "Cadastros", submenu: "CadAgendaMedicoListar", descricao: Translate.translate("descricaoListagemHorario") },

    { menu: "Cadastros", submenu: "CadAntecedentes", descricao: Translate.translate("descricaoCadastroAntecedentes") },
    { menu: "Cadastros", submenu: "CadAntecedentesListar", descricao: Translate.translate("descricaoListagemAntecedentes") },

    { menu: "Cadastros", submenu: "CadParceiros", descricao: Translate.translate("descricaoCadastroConvenios") },
    { menu: "Cadastros", submenu: "CadParceirosListar", descricao: Translate.translate("descricaoListagemConvenios") },

    { menu: "Cadastros", submenu: "CadParceirosContato", descricao: Translate.translate("descricaoCadastroParceiro") },
    { menu: "Cadastros", submenu: "CadParceirosContatoListar", descricao: Translate.translate("descricaoListagemParceiro") },

    { menu: "Cadastros", submenu: "CadClientes", descricao: Translate.translate("descricaoCadastroCliente") },
    { menu: "Cadastros", submenu: "CadClientesListar", descricao: Translate.translate("descricaoListagemCliente") },

    { menu: "Cadastros", submenu: "CadClientesDependente", descricao: Translate.translate("descricaoCadastroDependente") },
    { menu: "Cadastros", submenu: "CadClientesDependenteListar", descricao: Translate.translate("descricaoListagemDependente") },

    { menu: "Cadastros", submenu: "CadTecnicoEmEnfermagem", descricao: Translate.translate("descricaoCadastroTecEnfermagem") },
    { menu: "Cadastros", submenu: "CadTecnicoEmEnfermagemListar", descricao: Translate.translate("descricaoListagemTecEnfermagem") },

    { menu: "Cadastros", submenu: "CadEstacaoConsulta", descricao: Translate.translate("descricaoCadastroEstacao") },
    { menu: "Cadastros", submenu: "CadEstacaoConsultaListar", descricao: Translate.translate("descricaoListagemEstacao") },

    { menu: "Cadastros", submenu: "CadEnfermeiros", descricao: Translate.translate("descricaoCadastroEnfermeiro") },
    { menu: "Cadastros", submenu: "CadEnfermeirosListar", descricao: Translate.translate("descricaoListagemEnfermeiro") },

    { menu: "Cadastros", submenu: "CadAdministradores", descricao: Translate.translate("descricaoCadastroAdministradores") },
    { menu: "Cadastros", submenu: "CadAdministradoresListar", descricao: Translate.translate("descricaoListagemAdministradores") },

    { menu: "Cadastros", submenu: "CadOcorrencias", descricao: Translate.translate("descricaoCadastroOcorrencias") },
    { menu: "Cadastros", submenu: "CadOcorrenciasListar", descricao: Translate.translate("descricaoListagemOcorrencias") },

    { menu: "Cadastros", submenu: "CadPrescricoesMedicas", descricao: Translate.translate("descricaoCadastroPrescricoes") },
    { menu: "Cadastros", submenu: "CadPrescricoesMedicasListar", descricao: Translate.translate("descricaoListagemPrescricoes") },

    { menu: "Cadastros", submenu: "CadHipotesesDiagnosticas", descricao: Translate.translate("descricaoCadastroHipoteses") },
    { menu: "Cadastros", submenu: "CadHipotesesDiagnosticasListar", descricao: Translate.translate("descricaoListagemHipoteses") },

    { menu: "Cadastros", submenu: "CadTurnos", descricao: Translate.translate("descricaoCadastroTurnos") },
    { menu: "Cadastros", submenu: "CadTurnosListar", descricao: Translate.translate("descricaoListagemTurnos") },

    { menu: "Cadastros", submenu: "CadEspecialidades", descricao: Translate.translate("descricaoCadastroEspecialidades") },
    { menu: "Cadastros", submenu: "CadEspecialidadesListar", descricao: Translate.translate("descricaoListagemEspecialidades") },

    { menu: "Cadastros", submenu: "CadOperadores", descricao: Translate.translate("descricaoCadastroOperadores") },
    { menu: "Cadastros", submenu: "cadOperadoresListar", descricao: Translate.translate("descricaoListagemOperadores") },

    { menu: "Cadastros", submenu: "CadPerfil", descricao: Translate.translate("descricaoTelaCadastroPerfil") },


    { menu: "Cadastros", submenu: "CadAgentePenitenciario", descricao: Translate.translate("descricaoTelaCadastroPerfil") },
    { menu: "Cadastros", submenu: "CadAgentePenitenciarioListar", descricao: Translate.translate("descricaoTelaCadastroPerfil") },

    { menu: "Dashboard", submenu: "CadAgendamentoConsulta", descricao: Translate.translate("CadAgendamentoConsultaDescricao") },
    { menu: "Cadastros", submenu: "CadAgendamentoConsultaListar", descricao: Translate.translate("CadAgendamentoConsultaListarDescricao") },

    { menu: "Cadastros", submenu: "CadPerfilListar", descricao: Translate.translate("descricaoListagemPerfisUser") },

    { menu: "Cadastros", submenu: "CadPerfilUsuario", descricao: Translate.translate("descricaoConfigPerfilUser") },

    { menu: "Dashboard", submenu: "Triagem", descricao: Translate.translate("descricaoDashEnfermeiro") },

    { menu: "Dashboard", submenu: "Atendimento", descricao: Translate.translate("descricaoDashMedico") },
    { menu: "Dashboard", submenu: "DashboardAdm", descricao: Translate.translate("descricaoAdmDash") },

    { menu: "Dashboard", submenu: "AtendimentoEstacaoConsulta", descricao: Translate.translate("descricaoDashTecEnfermagem") },

    { menu: "Dashboard", submenu: "DashboardTecEnfermagem", descricao: Translate.translate("descricaoDashTecEnfermeiro") },

    { menu: "Dashboard", submenu: "Indicadores", descricao: Translate.translate("descricaoIndicadoresAtendimentos") },

    { menu: "Dashboard", submenu: "AtendimentosVideoCall", descricao: Translate.translate("descricaoVideoMedico") },
    { menu: "Dashboard", submenu: "DashboardMonitorarFila", descricao: Translate.translate("descricaoDashFila") },
    { menu: "Dashboard", submenu: "DashboardMonitorarMapa", descricao: Translate.translate("descricaoVisualizaçãoDoMapa") },

    { menu: "Logoff", submenu: "Logoff", descricao: Translate.translate("descricaoLogOff") },
    { menu: "MenuConfiguracoes", submenu: "MeuPerfil", descricao: Translate.translate("descricaoAlteracaoConfigUsuario") },

]


function montaMenuDinamico(element) {
    var permissaoUsuario = JSON.parse(Cripto.DecryptedStorage('permissao_telas_usuario'));
    if (permissaoUsuario.menu.indexOf(String(element.id)) > - 1 || permissaoUsuario.submenu.indexOf(String(element.id)) > -1) {
        element.visible = 1;
        if (element.children != undefined && element.children.length > 0) {
            element.children.forEach(item => {
                montaMenuDinamico(item);
            });
        }
    } else {
        element.visible = 0;
    }
    return true;
}


// tentar colocar na chama apos o login,, ao ao cair na primeir tela da aplicacao!.. So chamar uma vez
export const montarMenu = () => {
    try {
        var emailUser = Cripto.DecryptedStorage('email');
        if (emailUser === null || emailUser === '') //usuario nao logado!
        {
            return false;
        }
        if (Cripto.DecryptedStorage('permissao_telas_usuario') == '' || Cripto.DecryptedStorage('permissao_telas_usuario') == null) {
            var index = 0;
            items.items = [];
            itemsTodosMenus.itemsMenuDinamico.forEach((element) => {
                items.items[index] = element;
                index++;
            });
            return false;
        } else {
            itemsTodosMenus.itemsMenuDinamico.forEach((element) => {
                montaMenuDinamico(element);
            });
            items.items = itemsTodosMenus.itemsMenuDinamico;
            return true;
        }
    } catch (e) {
        return false;
    }
}


var permissaoUsuario = null;
export const verificarPermissao = (idTela) => {
    if (Cripto.DecryptedStorage('logado') === 'true') {
        if (montarMenu() == false) {
            return <Redirect to="/sempermissao" />
        }

        try {
            permissaoUsuario = JSON.parse(Cripto.DecryptedStorage('permissao_telas_usuario'));
            if (permissaoUsuario == undefined || permissaoUsuario == null || permissaoUsuario === '') {
                return <Redirect to="/sempermissao" />
            }
            if (!permissaoUsuario.submenu.includes(idTela.submenu)) {
                return <Redirect to="/sempermissao" />
            } else {
                return true;
            }
        } catch (e) {
            console.log(e)
            //alert(e.message)
            return <Redirect to="/sempermissao" />
        }
    } else {
        console.log('AQUI')
        return <Redirect to="/sempermissao" />
    }

}