import firebase from "../config/firebase";
import Cripto from '../utils/cripto'
import Log from '../utils/log'
import "firebase/auth";
import "firebase/firestore";
import { store } from "../index.js";
import { collectionData, collectionChanges, docData } from "rxfire/firestore";
import { switchMap } from "rxjs/operators";
import { dispatch } from "rxjs/internal/observable/pairs";
import axios from "axios";
import { storage } from "../config/firebasestorage";


export const dashboardFilaObserverIndicador = (that) => {
    try{
      const collectionDiaCorrente = firebase
        .firestore()
        .collection("indicadores")
        .doc("fila") 
        .collection("parciaisDiaCorrente");

        collectionData(collectionDiaCorrente, "uid").subscribe((dados) => {
            that.callbackSetItensGraficoFromAction(dados);
        });
    }
    catch(ex){
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'dashboardFilaObserverIndicador', ex.message, "")
    }
};