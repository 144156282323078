import { parseISO } from "date-fns";

class TurnoModel {


    constructor(id, nome, dataHoraIni, dataHoraFim, ativo) {
        this.id = id;
        this.nome = nome;
        this.dataHoraIni = dataHoraIni;
        this.dataHoraFim = dataHoraFim;
        this.ativo = ativo;

    };

    completeConstructor(turno) {
        var constructor = {
            id: turno.id,
            nome: turno.nome,
            dataHoraIni: turno.dataHoraIni,
            dataHoraFim: turno.dataHoraFim,
            ativo: turno.ativo,
        };
        return constructor;
    }
    voidConstructor() {
        var constructor = {
            id: '',
            nome: '',
            dataHoraIni: "",
            dataHoraFim: "",
            ativo: true,

        };
        return constructor;
    }

    createConstructor(turno) {
        var constructor = {
            nome: turno.nome,
            dataHoraIni: turno.dataHoraIni,
            dataHoraFim: turno.dataHoraFim,
            ativo: turno.ativo,
        };
        return constructor;
    }

}
export default TurnoModel;