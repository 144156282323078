class EspecialidadeModel {


    constructor(id, especialidadeNome , ativo, nivel) {
        this.id = id;
        this.nivel = nivel;
        this.especialidadeNome  = especialidadeNome ;
        this.ativo = ativo;
    };

    completeConstructor(especialidade) {
        var constructor = {
            id: especialidade.id,
            nivel: especialidade.nivel,
            especialidadeNome : especialidade.especialidadeNome,
            ativo: especialidade.ativo,
        };
        return constructor;
    }
    voidConstructor() {
        var constructor = {
            id: '',
            nivel: 1,
            especialidadeNome: '',
            ativo: true,

        };
        return constructor;
    }

    createConstructor(especialidade) {
        var constructor = {
            nivel: especialidade.nivel,
            especialidadeNome : especialidade.especialidadeNome,
            ativo: especialidade.ativo,
        };
        return constructor;
    }

}
export default EspecialidadeModel;