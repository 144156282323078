import { CHAT_MESSAGES	} from '../actions';

const INITIAL_STATE = {
    chat : [
		
	] 
}

export default function listChatMessages(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CHAT_MESSAGES:
            return action.chat;
		default:
			return state;
	}
}