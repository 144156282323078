import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import OperadorModel from '../Model/operadorModel';
import { store } from '../index.js'
import { docData } from 'rxfire/firestore';
import { getIdToken, replaceEspaceFieldsInObject, request } from '.';

export const OPERADOR_SET_FIELD = 'OPERADOR_SET_FIELD';
export const operadorSetField = (field, value) => {
    return {
        type: OPERADOR_SET_FIELD,
        field,
        value,
    }
}

export const OPERADOR_SET_CURRENT = 'OPERADOR_SET_CURRENT';
const operadorSetCurrent = operador => ({
    type: OPERADOR_SET_CURRENT,
    operador
});

export const OPERADOR_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS = 'OPERADOR_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS';
export const operadorParceirosList = (field, value) => {
    return {
        type: OPERADOR_SET_CURRENT_LIST_PARCEIRO_DISPONIVEIS,
        field,
        value,
    }
}

export const OPERADOR_SET_CURRENT_LIST_PARCEIRO = 'OPERADOR_SET_CURRENT_LIST_PARCEIRO';
export const operadorSetCurrentListParceiro = (field, value) => {
    return {
        type: OPERADOR_SET_CURRENT_LIST_PARCEIRO,
        field,
        value,
    }
}
export const operadorSetAtual = (id) => async dispatch => {
    if (id == null) {
        var operador = new OperadorModel().voidConstructor();
        dispatch(operadorSetCurrent(operador));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/operador/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const operador = new OperadorModel().completeConstructor(resp.data.data);
                dispatch(operadorSetCurrent(operador));
            }).catch(error => {
                console.log(error)
            });
    }
};
export const getOperador = async (id) => {
    return await new Promise(async (resolve, reject) => {
        if (id == null) {
            var operador = new OperadorModel().voidConstructor();
            resolve(operador);
        } else {
            var currentValue = store.getState().operadores;
            let operador = '';
            currentValue.map((item) => {
                if (item.id === id) {
                    operador = new OperadorModel().completeConstructor(item);
                }
            });
            resolve(operador);
        }
    });
}

export const operadorAtualizar = async (id) => {
    var operador = new OperadorModel().completeConstructor(store.getState().operador);
    operador = replaceEspaceFieldsInObject(operador);
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.patch(
            `/operador/${id}`,
            operador,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            console.log(resp);
            if (resp.data.statusOk) {
                resolve(true)
            } else {
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });

}

export const operadorSetAtivo = async (id, ativo) => {
    var operador = await getOperador(id);
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        operador.ativo = ativo ? false : true;
        try {
            request.patch(
                `/operador/${id}`,
                operador,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

export const operadorListarParceiros = () => {
    return async dispatch => {
        var parceirosArr = [];
        var idToken = await getIdToken();
        request.get("/parceiros", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(resp => {
                resp.data.data.map(parceiros => {
                    let tt = { value: parceiros.id, label: parceiros.nome, };
                    parceirosArr.push(tt);
                })
                dispatch(operadorParceirosList("parceirosDisponiveis", parceirosArr));
            })
            .catch((error) => {
                console.log(error)
                return error;
            });
    };
}

export const operadorCriar = async () => {
    var operador = new OperadorModel().createConstructor(store.getState().operador);
    operador = replaceEspaceFieldsInObject(operador);
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        request.post(
            `/operador`,
            operador,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then((resp) => {
            if (resp.data.statusOk) {
                resolve(true)
            } else {
                if (resp.data.data === 'The email address is already in use by another account.') {
                    reject(resp.data.data);
                }
                reject(resp.data.message);
            }
        }).catch((error) => {
            reject(error)
        });
    });
}


//########################LISTAGEM!################################################################################
//REDUCER OPERADORES
//#################################################################################################################
export const OPERADOR_LISTAR = 'OPERADOR_LISTAR';
const operadoresListar = (op) => ({
    type: OPERADOR_LISTAR,
    operadores: op,
});

export const operadoresLista = (callback) => {
    return async dispatch => {
        const arrOperadores = [];
        var idToken = await getIdToken();
        request.get("/operadores", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        })
            .then(resp => {
                resp.data.data.map(operador => {
                    let operadorModel = new OperadorModel().completeConstructor(operador);
                    arrOperadores.push(operadorModel);
                })
                callback(arrOperadores);
                dispatch(operadoresListar(arrOperadores));
            })
            .catch((error) => {
                return error;
            });
    };
}
