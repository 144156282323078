import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from "..";
import { getIdToken, medicosLista, request } from "."


const db = firebase.firestore();


export const SET_LISTA_DE_MEDICOS = 'SET_LISTA_DE_MEDICOS';
export const setListaMedicos = medicos => ({
    type: SET_LISTA_DE_MEDICOS,
    value: medicos
})
export const SET_JA_PESQUISOU = 'SET_JA_PESQUISOU';
export const setJaPesquisou = value => ({
    type: SET_JA_PESQUISOU,
    value: value
})
export const SET_LISTA_DE_ENFERMEIROS = 'SET_LISTA_DE_ENFERMEIROS';
export const setListaEnfermeiros = enfermeiro => ({
    type: SET_LISTA_DE_ENFERMEIROS,
    value: enfermeiro
})
export const SET_LISTA_DE_PARCEIROS = 'SET_LISTA_DE_PARCEIROS';
export const setListaParceiros = parceiros => ({
    type: SET_LISTA_DE_PARCEIROS,
    value: parceiros
})

export const SET_LISTA_DE_TECNICOS_DE_ENFERMAGEM = 'SET_LISTA_DE_TECNICOS_DE_ENFERMAGEM';
export const setListaTecEnfermagem = tecsDeEnfermagem => ({
    type: SET_LISTA_DE_TECNICOS_DE_ENFERMAGEM,
    value: tecsDeEnfermagem
});

export const SET_TECNICO_DE_ENFERMAGEM_SELECIONADO = 'SET_TECNICO_DE_ENFERMAGEM_SELECIONADO';
export const setTecnicoDeEnfermagemSelecionado = tecDeEnfermagem => ({
    type: SET_TECNICO_DE_ENFERMAGEM_SELECIONADO,
    value: tecDeEnfermagem
})

export const SET_MEDICO_SELECIONADO = 'SET_MEDICO_SELECIONADO';
export const setMedicoSelecionado = medico => ({
    type: SET_MEDICO_SELECIONADO,
    value: medico
})
export const SET_ENFERMEIRO_SELECIONADO = 'SET_ENFERMEIRO_SELECIONADO';
export const setEnfermeiroSelecionado = enfermeiro => ({
    type: SET_ENFERMEIRO_SELECIONADO,
    value: enfermeiro
})

export const SET_PARCEIRO_SELECIONADO = 'SET_PARCEIRO_SELECIONADO';
export const setParceiroSelecionado = parceiro => ({
    type: SET_PARCEIRO_SELECIONADO,
    value: parceiro
})
export const ADD_FILTRO = 'ADD_FILTRO';
export const addFiltro = filtro => ({
    type: ADD_FILTRO,
    value: filtro
})

export const SET_FIELD_FILTRO = "SET_FIELD_FILTRO";
export const setFieldFiltro = (field, value) => ({
    type: SET_FIELD_FILTRO,
    field: field,
    value: value,
})

export const FILTRO_SET_CURRENT = 'FILTRO_SET_CURRENT';
const filtroSetCurrent = filtro => ({
    type: FILTRO_SET_CURRENT,
    filtro
});
export const SET_LISTA_INDICADORES = 'SET_LISTA_INDICADORES';
const setListaIndicadores = indicadores => ({
    type: SET_LISTA_INDICADORES,
    list: indicadores
});

export const UPDATE_FILTRO = 'UPDATE_FILTRO';
export const updateFiltro = parceiro => ({
    type: UPDATE_FILTRO,
    value: parceiro
})

export const REMOVE_FILTRO = 'REMOVE_FILTRO';
export const removeFiltro = parceiro => ({
    type: REMOVE_FILTRO,
    value: parceiro
})

export const setFiltroAtual = (filtro) => dispatch => {
    if (filtro === null) {
        dispatch(filtroSetCurrent(filtro));
    }
    const filtros = store.getState().indicadoresTelas.filtros;
    let index = filtros.indexOf(filtro);
    if (index >= 0) {
        dispatch(filtroSetCurrent(filtros[index]));
    }
}

export const adicionarFiltro = (filtro) => {
    return dispatch => {
        return dispatch(addFiltro(filtro));
    }
}

export const removerFiltro = (filtro) => {
    return dispatch => {
        return dispatch(removeFiltro(filtro));
    }
}

export const atualizarFiltro = (filtro) => {
    return dispatch => {
        return dispatch(updateFiltro(filtro));
    }
}


export const listarMedicos = () => {
    return async dispatch => {
        var arrMedicos = [];
        var idToken = await getIdToken();
        await request.get("/medicos", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        }).then(resp => {
            arrMedicos = resp.data.data.map(medico => { return { value: medico.id, label: medico.nome } });
            return dispatch(setListaMedicos(arrMedicos));
        }).catch((error) => {
            return error;
        });
    }
}

export const listarParceiros = () => {
    return async dispatch => {
        var arrParceiros = [];
        var idToken = await getIdToken();
        await request.get("/parceiros", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        }).then(resp => {
            arrParceiros = resp.data.data.map(parceiro => { return { value: parceiro.id, label: parceiro.nome } });
            return dispatch(setListaParceiros(arrParceiros));
        }).catch((error) => {
            return error;
        });
    }
}

export const listarEnfermeiros = () => {
    return async dispatch => {
        var arrEnfermeiros = [];
        var idToken = await getIdToken();
        await request.get("/enfermeiros", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        }).then(resp => {
            arrEnfermeiros = resp.data.data.map(enfermeiro => { return { value: enfermeiro.id, label: enfermeiro.nome } });
            return dispatch(setListaEnfermeiros(arrEnfermeiros));
        }).catch((error) => {
            return error;
        });
    }
}

export const listarTecEmEnfermagem = () => {
    return async dispatch => {
        var arrTecDeEnfermagem = [];
        var idToken = await getIdToken();
        await request.get("/tecenfermagens", {
            headers: {
                "Authorization": `Bearer ${idToken}`,
            }
        }).then(resp => {
            arrTecDeEnfermagem = resp.data.data.map(tecDeEnfermagem => { return { value: tecDeEnfermagem.id, label: tecDeEnfermagem.nome } });
            return dispatch(setListaTecEnfermagem(arrTecDeEnfermagem));
        }).catch((error) => {
            return error;
        });
    }
}
export const getNomeMedico = async (idMedico) => {
    var medico = await firebase.firestore().collection("medicos").doc(idMedico).get();
    return medico.data().nome;
}
export const getNomePaciente = async (idPaciente) => {
    var paciente = await firebase.firestore().collection("pacientes").doc(idPaciente).get();
    return paciente.data().nome;
}
export const getNomeTecEmEnfermagem = async (idTecEnfermagem) => {
    var tecEnfermagem = await firebase.firestore().collection("tecEnfermagem").doc(idTecEnfermagem).get();
    return tecEnfermagem.data().nome;
}
export const getNomeEnfermeiro = async (idEnfermeiro) => {
    var enfermeiro = await firebase.firestore().collection("enfermeiros").doc(idEnfermeiro).get();
    return enfermeiro.data().nome;
}
export const getNomeDependente = async (idPaciente, idDependente) => {
    var dependente = await firebase.firestore().collection("pacientes").doc(idPaciente).collection('dependentes').doc(idDependente).get();
    return dependente.data().nome;
}
export const getNomeParceiro = async (idParceiro) => {
    var parceiro = await firebase.firestore().collection("parceiros").doc(idParceiro).get();
    return parceiro.data().nome;
}

//Listar indicadores
export const listarIndicadoresPorFiltros = (filtros, callback) => {
    return async dispatch => {
        var arrFiltros = destructArray(filtros);
        var idToken = await getIdToken();
        await request.post('/indicadores/', filtros,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }
        ).then(async response => {
            var arr = await Promise.all(response.data.data.map(async (indicador) => {
                if (indicador.idMedico !== '' && indicador.idMedico !== undefined) {
                    indicador.idMedico = await getNomeMedico(indicador.idMedico);
                }
                if (indicador.idDependente !== '' && indicador.idDependente !== undefined) {
                    indicador.idDependente = await getNomeDependente(indicador.idPaciente, indicador.idDependente);
                }
                if (indicador.idEnfermeiro !== '' && indicador.idEnfermeiro !== undefined) {
                    indicador.idEnfermeiro = await getNomeEnfermeiro(indicador.idEnfermeiro);
                }
                if (indicador.idParceiro !== '' && indicador.idParceiro !== undefined) {
                    indicador.idParceiro = await getNomeParceiro(indicador.idParceiro);
                }
                if (indicador.idTecEnfermagem !== '' && indicador.idTecEnfermagem !== undefined) {
                    indicador.idTecEnfermagem = await getNomeTecEmEnfermagem(indicador.idTecEnfermagem);

                }
                if (indicador.idPaciente !== '' && indicador.idPaciente !== undefined) {
                    indicador.idPaciente = await getNomePaciente(indicador.idPaciente);
                }
                if(indicador.tempoDeEsperaFilaAtendimento === null){
                    indicador.tempoDeEsperaFilaAtendimento = 0;
                }
                if(indicador.tempoDeEsperaFilaTriagem === null){
                    indicador.tempoDeEsperaFilaTriagem = 0;
                }
                if(indicador.tempoTotalAtendimento === null){
                    indicador.tempoTotalAtendimento = 0;
                }
                if(indicador.tempoDeTriagem === null){
                    indicador.tempoDeTriagem = 0;
                }
                if(indicador.tempoDeEsperaFilaAtendimento === null){
                    indicador.tempoDeEsperaFilaAtendimento = 0;
                }
                if(indicador.tempoDeAtendimento === null){
                    indicador.tempoDeAtendimento = 0;
                }
                
                return indicador;
            }));
            dispatch(setListaIndicadores(arr));
            callback(true);
        }).catch((error) => {
            callback(false);
            return [];
        })
    }
}
const montaObjetoDeIntervaloDeDias = (isDataInicial, data) => {
    return {
        key: "id",
        filter: isDataInicial ? ">=" : "<=",
        value: data.getTime(),
    }
}
const destructArray = (filtros) => {
    var arrFiltroDestructured = filtros.filter((filtro) => { return filtro.key !== "id" });
    var filtroIntervaloDeDias = filtros.filter((filtro) => { return filtro.key === "id" });
    if (filtroIntervaloDeDias.length > 0) {
        var filtroDataIni = montaObjetoDeIntervaloDeDias(true, filtroIntervaloDeDias[0].valueDataInicial);
        var filtroDataFim = montaObjetoDeIntervaloDeDias(false, filtroIntervaloDeDias[0].valueDataFim);
        arrFiltroDestructured.push(filtroDataIni);
        arrFiltroDestructured.push(filtroDataFim);
    }
    return arrFiltroDestructured;
}