import { DEPENDENTES_LISTAR	} from '../actions';

const INITIAL_STATE = {
    convenios : [    ] 
}

export default function dependenteListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case DEPENDENTES_LISTAR:
            return action.dependentes;
		default:
			return state;
	}
}