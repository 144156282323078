class ParceiroModel {
    constructor(ativo, bairro, cep, cnpj, complemento, email, logradouro, municipio, nome, numero, telefone, uf, id) {
        this.ativo = ativo;
        this.bairro = bairro;
        this.cep = cep;
        this.cnpj = cnpj;
        this.complemento = complemento;
        this.email = email;
        this.logradouro = logradouro;
        this.municipio = municipio;
        this.nome = nome;
        this.numero = numero;
        this.telefone = telefone;
        this.uf = uf;
        this.id = id;
    }
    voidConstructor = () => {
        var parceiro = {
            ativo : true,
            bairro : '',
            cep : '',
            cnpj : '',
            complemento : '',
            email : '',
            logradouro : '',
            municipio : '',
            nome : '',
            numero : '',
            telefone : '',
            uf : '',
            isPenitenciaria: false,
            id : '',
        }
        return parceiro;
    }
    completConstructor(parceiro){
        var parceiroModel = {
            ativo: parceiro.ativo,
            cep : parceiro.cep,
            cnpj : parceiro.cnpj,
            complemento : parceiro.complemento,
            email : parceiro.email,
            logradouro : parceiro.logradouro,
            bairro: parceiro.bairro,
            municipio : parceiro.municipio,
            nome : parceiro.nome,
            numero : parceiro.numero,
            telefone : parceiro.telefone,
            isPenitenciaria: parceiro.isPenitenciaria === undefined ? false : parceiro.isPenitenciaria,
            uf : parceiro.uf,
            id : parceiro.id,
        }
        return parceiroModel;
    }
    createConstructor(parceiro){
        var parceiroModel = {
            ativo: parceiro.ativo,
            cep : parceiro.cep,
            cnpj : parceiro.cnpj,
            complemento : parceiro.complemento,
            email : parceiro.email,
            logradouro : parceiro.logradouro,
            bairro: parceiro.bairro,
            municipio : parceiro.municipio,
            nome : parceiro.nome,
            numero : parceiro.numero,
            isPenitenciaria: parceiro.isPenitenciaria === undefined ? false : parceiro.isPenitenciaria,
            telefone : parceiro.telefone,
            uf : parceiro.uf,
        }
        return parceiroModel; 
    }
}
export default ParceiroModel;