import { CONTATOS_LISTAR } from '../actions';

const INITIAL_STATE = []

export default function dependenteListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case CONTATOS_LISTAR:
			return action.contatos;
		default:
			return state;
	}
}