class PrescricaoModel {


    constructor(titulo,descricao, ativo, id) {
        this.id = id;
        this.titulo = titulo;
        this.descricao = descricao;
        this.ativo = ativo;
    };

    completeConstructor(model){
        var constructor = {
            id: model.id,
            titulo: model.titulo,
            descricao: model.descricao,
            ativo: model.ativo,
        };
        return constructor;
    }
    voidConstructor(){
        var constructor = {
            id: '',
            titulo: '',
            descricao: '',
            ativo: true,
        };
        return constructor;
    }

    createConstructor(ocorrenciaModel) {
        var constructor = {
            titulo: ocorrenciaModel.titulo,
            descricao: ocorrenciaModel.descricao,
            ativo: ocorrenciaModel.ativo,
        };
        return constructor;
    }

}
export default PrescricaoModel;