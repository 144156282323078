import { getColor } from "../utils/foto"

export class AgentePenitenciarioModel {
    constructor() {
    }
    createConstructor(agentePenitenciario) {
        return {
            nome: agentePenitenciario.nome,
            sexo: agentePenitenciario.sexo,
            dataNasc: agentePenitenciario.dataNasc,
            rg: agentePenitenciario.rg,
            cpf: agentePenitenciario.cpf,
            logradouro: agentePenitenciario.logradouro,
            municipio: agentePenitenciario.municipio,
            uf: agentePenitenciario.uf,
            bairro: agentePenitenciario.bairro,
            numero: agentePenitenciario.numero,
            parceiro: agentePenitenciario.parceiro,
            idParceiro: agentePenitenciario.idParceiro,
            ativo: agentePenitenciario.ativo,
            password: agentePenitenciario.password,
            email: agentePenitenciario.email,
            color: agentePenitenciario.color,
            complemento:  agentePenitenciario.complemento,
            cep:  agentePenitenciario.cep,
        }
    }
    completeConstructor(agentePenitenciario) {
        return {
            id: agentePenitenciario.id,
            nome: agentePenitenciario.nome,
            sexo: agentePenitenciario.sexo,
            dataNasc: agentePenitenciario.dataNasc,
            rg: agentePenitenciario.rg,
            cpf: agentePenitenciario.cpf,
            logradouro: agentePenitenciario.logradouro,
            municipio: agentePenitenciario.municipio,
            uf: agentePenitenciario.uf,
            bairro: agentePenitenciario.bairro,
            numero: agentePenitenciario.numero,
            parceiro: agentePenitenciario.parceiro,
            idParceiro: agentePenitenciario.idParceiro,
            ativo: agentePenitenciario.ativo,
            color: agentePenitenciario.color,
            complemento:  agentePenitenciario.complemento,
            email: agentePenitenciario.email,
            cep:  agentePenitenciario.cep,
        }
    }
    voidConstructor() {
        return {
            nome: '',
            sexo: '',
            dataNasc: '',
            rg: '',
            cpf: '',
            logradouro: '',
            municipio: '',
            uf: '',
            bairro: '',
            numero: '',
            complemento: '',
            cep: '',
            parceiro: '',
            idParceiro: '',
            ativo: true,
            password: '',
            email: '',
            id: '',
            color: getColor(),
        }
    }
}