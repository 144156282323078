import {
    ESTACAO_SET_CURRENT,
    ESTACAO_SET_FIELD,
    ESTACAO_ADD_HORARIO,
    ESTACAO_REMOVE_HORARIO,
    ESTACAO_UPDATE_HORARIO
} from '../actions';
import ClinicaDigital from '../Model/clinicaDigitalModel';

const INITIAL_STATE_ESTACAO = new ClinicaDigital().voidConstructor();

export default function administradorReducer(state = INITIAL_STATE_ESTACAO, action) {
    switch (action.type) {
        case ESTACAO_SET_CURRENT:
            return action.estacao;
        case ESTACAO_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        case ESTACAO_ADD_HORARIO:
            const newState2 = {...state};
            newState2.horarios.push(action.value);
            return newState2;
        case ESTACAO_REMOVE_HORARIO:
            const newState3 = {...state};
            newState3.horarios =  newState3.horarios.filter(item => {return item !== action.value})
            return newState3;
        case ESTACAO_UPDATE_HORARIO:
            const newState4 = {...state};
            newState4.horarios[action.index] =  action.value;
            return newState4;
        default:
            return state;
    }
}

