import { HIPOTESE_SET_FIELD	} from '../actions';

const INITIAL_STATE = {
	hipotese: {},
	hipoteses: []
};

export default function hipoteseListarReducers(state = INITIAL_STATE, action) {
	switch (action.type) {
		case HIPOTESE_SET_FIELD:
            const newState = {...state};
			newState[action.field] = action.value;
			return newState;
		default:
			return state;
	}
}