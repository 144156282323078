import {
    HIPOTESE_SET_FIELD,
    HIPOTESE_SET_CURRENT
} from '../actions';
import HipoteseModel from '../Model/hipoteseModel';

const INITIAL_STATE_HIPOTESE = new HipoteseModel().voidConstructor();

export default function hipoteseReducer(state = INITIAL_STATE_HIPOTESE, action) {
    switch (action.type) {
         case HIPOTESE_SET_CURRENT:
            return action.hipotese;
        case HIPOTESE_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}