import { getColor } from "../utils/foto";

class EnfermeiroModel {
    constructor() {
    }

    voidConstructor() {
        let enfermeiroModel = {
            id: '',
            ativo: true,
            clinicaDigitalNome: "",
            coren: "",
            cpf: "",
            dataNasc: "",
            email: "",
            fotoPerfil: "",
            idClinicaDigital: "",
            idAtendimento: "",
            idParceiro: "",
            idPaciente: "",
            nome: "",
            parceiroNome: "",
            uf: "",
            rg: "",
            sexo: "",
            statusEnfermeiro: 0,
            password: '',
            color: getColor()
        }
        return enfermeiroModel;
    }

    completeConstructor(enfermeiro) {
        let enfermeiroModel = {
            id: enfermeiro.id,
            ativo: enfermeiro.ativo,
            clinicaDigitalNome: enfermeiro.clinicaDigitalNome,
            coren: enfermeiro.coren,
            cpf: enfermeiro.cpf,
            dataNasc: enfermeiro.dataNasc,
            email: enfermeiro.email,
            fotoPerfil: enfermeiro.fotoPerfil,
            idClinicaDigital: enfermeiro.idClinicaDigital,
            idAtendimento: enfermeiro.idAtendimento,
            idParceiro: enfermeiro.idParceiro,
            idPaciente: enfermeiro.idPaciente,
            nome: enfermeiro.nome,
            parceiroNome: enfermeiro.parceiroNome,
            rg: enfermeiro.rg ?? '',
            sexo: enfermeiro.sexo,
            uf: enfermeiro.uf,
            statusEnfermeiro: enfermeiro.statusEnfermeiro,
            color: enfermeiro.color
        }
        return enfermeiroModel;
    }

    createConstructor(enfermeiro) {
        let enfermeiroModel = {
            ativo: enfermeiro.ativo,
            clinicaDigitalNome: enfermeiro.clinicaDigitalNome,
            coren: enfermeiro.coren,
            cpf: enfermeiro.cpf,
            dataNasc: enfermeiro.dataNasc,
            email: enfermeiro.email,
            fotoPerfil: enfermeiro.fotoPerfil,
            idClinicaDigital: enfermeiro.idClinicaDigital,
            idAtendimento: enfermeiro.idAtendimento,
            idParceiro: enfermeiro.idParceiro,
            idPaciente: enfermeiro.idPaciente,
            nome: enfermeiro.nome,
            parceiroNome: enfermeiro.parceiroNome,
            rg: enfermeiro.rg,
            sexo: enfermeiro.sexo,
            statusEnfermeiro: enfermeiro.statusEnfermeiro,
            password: enfermeiro.password,
            color: enfermeiro.color,
            uf: enfermeiro.uf
        }
        return enfermeiroModel;
    }
}
export default EnfermeiroModel;