import firebase from "../config/firebase";
import Log from '../utils/log'
import "firebase/auth";
import "firebase/firestore";
import { store } from "../index.js";
import { collectionData, docData } from "rxfire/firestore";
import axios from "axios";
import { storage } from "../config/firebasestorage";
import Cripto from '../utils/cripto'
import PrescricaoModel from "../Model/prescricaoModel";
import { getIdToken, request } from ".";
import { replaceEspace } from "../utils/utils";
import { AgendaFactory } from "../Model/AgendaFactory";
// import hipoteseDiagnostica from "../components/Atendimento/hipoteseDiagnostica";
const db = firebase.firestore();

export const ATENDIMENTO_SET_CURRENT = 'ATENDIMENTO_SET_CURRENT';
export const atendimentoSetCurrent = atendimento => ({
  type: ATENDIMENTO_SET_CURRENT,
  atendimento
});

export const ATENDIMENTOS_LISTAR = "ATENDIMENTOS_LISTAR";
export const atendimentosListar = (atendimento) => ({
  type: ATENDIMENTOS_LISTAR,
  atendimentos: atendimento,
});

export const ATENDIMENTO_COUNT = "ATENDIMENTO_COUNT";
export const atendimentoCount = (atendimento_count) => ({
  type: ATENDIMENTO_COUNT,
  atendimento_count: atendimento_count,
});

export const ATENDIMENTOS_TOTAL = "ATENDIMENTOS_TOTAL";
const atendimentoTotal = (
  totalAtendimentosCollection,
  statusMedico,
  totalAtendimentosRealizados,
  atendimento_current_id
) => ({
  type: ATENDIMENTOS_TOTAL,
  totalAtendimentosCollection: totalAtendimentosCollection,
  totalAtendimentosRealizados: totalAtendimentosRealizados,
  statusMedico: statusMedico,
  atendimento_current_id: atendimento_current_id,
});

export const ATENDIMENTOS_TOTAL_COLLECTION = "ATENDIMENTOS_TOTAL_COLLECTION";
const atendimentoTotalCollection = (totalAtendimentosCollection) => ({
  type: ATENDIMENTOS_TOTAL_COLLECTION,
  totalAtendimentosCollection: totalAtendimentosCollection,
});

export const ATENDIMENTOS_TOTAL_ATENDIDOS = "ATENDIMENTOS_TOTAL_ATENDIDOS";
const atendimentoTotalAtendidos = (totalAtendimentosRealizados) => ({
  type: ATENDIMENTOS_TOTAL_ATENDIDOS,
  totalAtendimentosRealizados: totalAtendimentosRealizados,
});

export const ATENDIMENTOS_SET_CURRENT_ID = "ATENDIMENTOS_SET_CURRENT_ID";
export const atendimentosSetCurrentId = (atendimento_current_id) => ({
  type: ATENDIMENTOS_SET_CURRENT_ID,
  atendimento_current_id: atendimento_current_id,
});

export const ATENDIMENTO_TOTAL_FILA = "ATENDIMENTO_TOTAL_FILA";
const atendimentoTotalFila = (atendimento_totalFila) => ({
  type: ATENDIMENTO_TOTAL_FILA,
  atendimento_totalFila: atendimento_totalFila,
});
export const STATUS_MEDICO = "STATUS_MEDICO";
const statusMedico = (statusMedico) => ({
  type: STATUS_MEDICO,
  statusMedico: statusMedico,
});
export const ATENDIMENTO_TEMPOS = "ATENDIMENTO_TEMPOS";
const atendimentoTempos = (objTempos) => ({
  type: ATENDIMENTO_TEMPOS,
  tempoEsperaFila: objTempos.tempoEsperaFila,
  tempoAtendimento: objTempos.tempoAtendimento,
});

export const ATENDIMENTO_TEMPOS_MEDIOS = "ATENDIMENTO_TEMPOS_MEDIOS";
const atendimentoTemposMedios = (objTempos) => ({
  type: ATENDIMENTO_TEMPOS_MEDIOS,
  tempoMedioAtendimento: objTempos.tempoMedioAtendimento,
  tempoMedioEspera: objTempos.tempoMedioEspera,
  tempoMedioTotalServico: objTempos.tempoMedioTotalServico,
});



export const ATENDIMENTO_DOCUMENTOS = "ATENDIMENTO_DOCUMENTOS";
const atendimentosDocumentos = (documentos) => ({
  type: ATENDIMENTO_DOCUMENTOS,
  documentos: documentos,
});



export const ATESTADO_SET_FIELD = 'ATESTADO_SET_FIELD';
export const atestadoSetField = (field, value) => {
  return {
    type: ATESTADO_SET_FIELD,
    field,
    value,
  }
}


export const SOLICITACAO_EXAMES_SET_FIELD = 'SOLICITACAO_EXAMES_SET_FIELD';
export const solicitacaoExamesSetField = (field, value) => {
  return {
    type: SOLICITACAO_EXAMES_SET_FIELD,
    field,
    value,
  }
}

export const RECEITUARIO_ESPECIAL_SET_FIELD = 'RECEITUARIO_ESPECIAL_SET_FIELD';
export const receituarioEspecialSetField = (field, value) => {
  return {
    type: RECEITUARIO_ESPECIAL_SET_FIELD,
    field,
    value,
  }
}

export const RECEITUARIO_SIMPLES_SET_FIELD = 'RECEITUARIO_SIMPLES_SET_FIELD';
export const receituarioSimplesSetField = (field, value) => {
  return {
    type: RECEITUARIO_SIMPLES_SET_FIELD,
    field,
    value,
  }
}


export const RECEITUARIO_SIMPLES_SET_CURRENT = 'RECEITUARIO_SIMPLES_SET_CURRENT';
export const receituarioSimplesSetCurrent = receituario => ({
  type: RECEITUARIO_SIMPLES_SET_CURRENT,
  receituario
});

export const RECEITUARIO_ESPECIAL_SET_CURRENT = 'RECEITUARIO_ESPECIAL_SET_CURRENT';
export const receituarioEspecialSetCurrent = receituario => ({
  type: RECEITUARIO_ESPECIAL_SET_CURRENT,
  receituario
});


export const ATESTADO_SET_CURRENT = 'ATESTADO_SET_CURRENT';
export const atestadoSetCurrent = atestado => ({
  type: ATESTADO_SET_CURRENT,
  atestado: {
    periodo: '',
    observacoes: '',
  }
});

export const SOLICITACAO_SET_CURRENT = 'SOLICITACAO_SET_CURRENT';
export const solicitacaoSetCurrent = solicitacao => ({
  type: SOLICITACAO_SET_CURRENT,
  solicitacao: {
    indicacao: '',
    solicitacao: '',
  }
}
);

export const receituarioEspecialSetAtual = (id) => dispatch => {
  if (id == null) {
    var prescricao = new PrescricaoModel().voidConstructor();
    dispatch(receituarioEspecialSetCurrent(prescricao));
  } else {
    var currentValue = store.getState().prescricoes;
    let prescricao = '';
    currentValue.map((item) => {
      if (item.value === id) {
        prescricao = item;
      }
    });
    dispatch(receituarioEspecialSetCurrent(prescricao));
  }
}

export const receituarioSimplesSetAtual = (id) => dispatch => {
  if (id == null) {
    var prescricao = new PrescricaoModel().voidConstructor();
    dispatch(receituarioSimplesSetCurrent(prescricao));
  } else {
    var currentValue = store.getState().prescricoes;
    let prescricao = '';
    currentValue.map((item) => {
      if (item.value === id) {
        prescricao = item;
      }
    });
    dispatch(receituarioSimplesSetCurrent(prescricao));
  }
}

export const atendimentosLista = () => {
  try {
    return async dispatch => {
      var id = Cripto.DecryptedStorage('uid');
      var snapshot = await firebase
        .firestore()
        .collection("medicos")
        .doc(id)
        .collection("atendimentos")
        .orderBy("dataIniTriagem", "desc")
        .limit(5)
        .get();
      var item = [];
      snapshot.docs.map((doc) => {
        let atendimento = { ...doc.data(), id: doc.id };
        item.push(atendimento);
      });
      await dispatch(atendimentosListar(item));
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentosLista', ex.message, "")
  }
};

export const observableMedico = (that) => (dispatch) => {
  try {
    const idMedico = Cripto.DecryptedStorage('uid')
    const collectionMedico = firebase.firestore().collection("medicos").doc(idMedico);
    docData(collectionMedico, "uid").subscribe((medico) => {
      Cripto.EncryptedStorage('id_consulta', medico.idAtendimento)
      dispatch(statusMedico(medico.statusMedico));
      dispatch(atendimentosSetCurrentId(medico.idAtendimento));
      that.verificarMedicoEmAtendimento(medico.idAtendimento);
    });
  }
  catch (ex) {
    alert(ex.message)
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'observableMedico', ex.message, "")
  }
}

export const getExames = async (idPaciente, isDependente, idDependente) => {
  try {
    if (isDependente == false) {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("exames")
        .get();
      var exameList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let exameItem = { ...doc.data(), id: doc.id };
          exameList.push(exameItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_exames",
        JSON.stringify(exameList)
      );

    } else {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("dependentes")
        .doc(idDependente)
        .collection("exames")
        .get();
      var exameList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let exameItem = { ...doc.data(), id: doc.id };
          exameList.push(exameItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_exames",
        JSON.stringify(exameList)
      );
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getExames', ex.message, "")
  }
};

export const getAlergias = async (idPaciente, isDependente, idDependente) => {
  try {
    if (isDependente == false) {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("alergias")
        .get();
      var alergiaList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let alergiaItem = { ...doc.data(), id: doc.id };
          alergiaList.push(alergiaItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_alergias",
        JSON.stringify(alergiaList)
      );

    } else {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("dependentes")
        .doc(idDependente)
        .collection("alergias")
        .get();
      var alergiaList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let alergiaItem = { ...doc.data(), id: doc.id };
          alergiaList.push(alergiaItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_alergias",
        JSON.stringify(alergiaList)
      );

    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getAlergias', ex.message, "")
  }
};

function sleep(milliseconds) {
  const date = Date.now();
  let currentDate = null;
  do {
    currentDate = Date.now();
  } while (currentDate - date < milliseconds);
}

export const getDocumentosSync = async () => {
  try {
    var idMedico = Cripto.DecryptedStorage('uid');
    var idConsulta = Cripto.DecryptedStorage("id_consulta");
    let documentos = [];
    return async dispatch => {
      var snap = await firebase
        .firestore()
        .collection("medicos")
        .doc(idMedico)
        .collection("atendimentos")
        .doc(idConsulta)
        .collection("documentos")
        .get();


      snap.docs.map((doc) => {
        let documento = { ...doc.data(), id: doc.id };
        documentos.push(documento);

      });

      Cripto.EncryptedStorage("atendimento_documentos", JSON.stringify(documentos));
      dispatch(atendimentosDocumentos(documentos));
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getDocumentosSync', ex.message, "")
  }
};

export const observableAtendimento = () => (dispatch) => {
  try {

    const idMedico = Cripto.DecryptedStorage('uid');
    const idAtendimento = Cripto.DecryptedStorage('id_consulta');
    const collectionMedico = db
      .collection("medicos")
      .doc(idMedico)
      .collection('atendimentos')
      .doc(idAtendimento);
    docData(collectionMedico, 'uid').subscribe(async (atendimento) => {
      if (atendimento.historicoConsulta === undefined || atendimento.historicoConsulta.length === 0) {
        atendimento.historicoConsulta = '';
      }
      dispatch(atendimentoSetCurrent(atendimento))
    });
  } catch (error) {

  }
}

export const encerrarAtendimento = async (ocorrencia) => {
  try {
    return await new Promise(async (resolve, reject) => {
      const id = Cripto.DecryptedStorage('uid');
      const atendimento = store.getState().atendimento;
      var idToken = await getIdToken();
      request.post(`/medico/${id}/atendimento/${atendimento.idAtendimento}/finalizaratendimento`,
        {
          "idPaciente": atendimento.idPaciente,
          "isOcorrencia": ocorrencia.isOcorrencia,
          "idOcorrencia": ocorrencia.idOcorrencia,
          "ocorrenciaTitulo": ocorrencia.ocorrenciaTitulo,
          "ocorrencia": ocorrencia.descricao,
          "isClinicaDigital": atendimento.isClinicaDigital,
          "idClinicaDigital": atendimento.idClinicaDigital,
          "idTecEnfermagem": atendimento.idTecEnfermagem,
        },
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      ).then(async (result) => {
        if (result.data.statusOk) {
          resolve();
        } else {
          reject()
        }
      }).catch((error) => {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'encerrarAtendimento1', error.message, "")
        reject()
      });
    })
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'encerrarAtendimento2', ex.message, "")
  }
};

export const salvarAnamnese = async () => {
  try {
    const atendimento = store.getState().atendimento;
    const id = Cripto.DecryptedStorage('uid');
    var text = replaceEspace(atendimento.anamneseMedico)
    return await new Promise(async (resolve, reject) => {
      var idToken = await getIdToken();
      request.patch(`/medico/${id}/atendimento/${atendimento.idAtendimento}/updateanamnese`,
        {
          idPaciente: atendimento.idPaciente,
          texto: text,
          gravou: true,
        },
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      ).then((result) => {
        if (result.data.statusOk) {
          resolve(true);
        } else {
          reject(result.data.message)
        }
      }).catch((error) => {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarAnamnese1', error, "")
        reject()
      });
    });
  }
  catch (error) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarAnamnese2', error, "")
  }
};

export const salvarHipotese = async () => {
  try {
    const atendimento = store.getState().atendimento;
    const id = Cripto.DecryptedStorage('uid');
    var text = replaceEspace(atendimento.hipoteseDiagnostica)
    return await new Promise(async (resolve, reject) => {
      var idToken = await getIdToken()
      request.patch(`/medico/${id}/atendimento/${atendimento.idAtendimento}/updatehipotesediagnostica`,
        {
          idPaciente: atendimento.idPaciente,
          texto: text,
          gravou: true,
          id: ''
        },
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      ).then((result) => {
        console.log(result);
        if (result.data.statusOk) {
          resolve(true);
        } else {
          reject('result.data.message')
        }
      })
        .catch((error) => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarHipotese1', error.message, "")
          reject()
        });
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarHipotese2', ex.message, "")
  }
};

export const salvarConduta = async () => {
  try {
    return await new Promise(async (resolve, reject) => {
      const id = Cripto.DecryptedStorage('uid');
      const idAtendimento = Cripto.DecryptedStorage("id_consulta");
      const atendimento = store.getState().atendimento;
      var text = replaceEspace(atendimento.conduta)
      var idToken = await getIdToken();
      request.patch(`/medico/${id}/atendimento/${idAtendimento}/updateconduta`,
        {
          idPaciente: atendimento.idPaciente,
          texto: text,
          gravou: true,
        },
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      ).then((result) => {
        if (result.data.statusOk) {
          resolve(true);
        } else {
          reject()
        }
      })
        .catch((error) => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarConduta1', error, "")
          reject();
        });
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'salvarConduta2', ex, "")
  }
};

export const getCirurgias = async (idPaciente, isDependente, idDependente) => {
  try {
    if (isDependente == false) {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("cirurgias")
        .get();
      var cirurgiasList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let cirurgiasItem = { ...doc.data(), id: doc.id };
          cirurgiasList.push(cirurgiasItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_cirurgias",
        JSON.stringify(cirurgiasList)
      );
    } else {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("dependentes")
        .doc(idDependente)
        .collection("cirurgias")
        .get();
      var cirurgiasList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let cirurgiasItem = { ...doc.data(), id: doc.id };
          cirurgiasList.push(cirurgiasItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_cirurgias",
        JSON.stringify(cirurgiasList)
      );
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getCirurgias', ex.message, "")
  }
};

export const getMedicamentos = async (
  idPaciente,
  isDependente,
  idDependente
) => {
  try {
    if (isDependente == false) {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("medicamentos")
        .get();
      var medicamentosList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let medicamentosItem = { ...doc.data(), id: doc.id };
          medicamentosList.push(medicamentosItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_medicamentos",
        JSON.stringify(medicamentosList)
      );
    } else {
      var snapshot = await firebase
        .firestore()
        .collection("pacientes")
        .doc(idPaciente)
        .collection("dependentes")
        .doc(idDependente)
        .collection("medicamentos")
        .get();
      var medicamentosList = [];
      snapshot.docs.map((doc) => {
        if (doc.data().disponivel) {
          let medicamentosItem = { ...doc.data(), id: doc.id };
          medicamentosList.push(medicamentosItem);
        }
      });
      Cripto.EncryptedStorage(
        "atendimento_paciente_medicamentos",
        JSON.stringify(medicamentosList)
      );
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'getMedicamentos', ex.message, "")
  }
};

export const atendimentoCapturaPontos = async () => {
  try {
    var snapshot = await firebase
      .firestore()
      .collection("pontosAtendimentos")
      .get();
    var pontos = [];
    snapshot.docs.map((doc) => {
      let item = { ...doc.data(), id: doc.id };
      pontos.push(item);
    });
    Cripto.EncryptedStorage("pontos_atendimento_maps", JSON.stringify(pontos));
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoCapturaPontos', ex.message, "")
  }
};

export const atendimentoResetMonitoringCollectionAtendimento = () => {
  try {
    var uid = Cripto.DecryptedStorage('uid');
    return async (dispatch) => {
      var perfil = Cripto.DecryptedStorage('perfil'); //capturar perfil
      perfil = perfil.toLowerCase();
      if (perfil != 'medicos') {
        return;
      }
      if (uid == undefined || uid == null || uid == '')
        return;
      var totalAtendimentosCollection = 0;
      var totalAtendimentosRealizados = 0;
      var idAtendimento = 0;
      try {
        const snapshot = await firebase.firestore().collection("medicos").doc(uid).get();
        totalAtendimentosCollection = 1;
        totalAtendimentosRealizados = 0;
        idAtendimento = snapshot.data().idAtendimento;
        var statusMedico = snapshot.data().statusMedico;
        await dispatch(atendimentoTotal(totalAtendimentosCollection, statusMedico, totalAtendimentosRealizados, idAtendimento));
      } catch (ex) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoResetMonitoringCollectionAtendimento1', ex.message, "")
        return -1;
      }
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoResetMonitoringCollectionAtendimento2', ex.message, "")
    return -1;
  }
}

export const atendimentoObserverCollectionAtendimento = (that) => {
  try {
    var uid = Cripto.DecryptedStorage('uid');
    const docRef = firebase
      .firestore()
      .collection("medicos")
      .doc(uid);
    docData(docRef).subscribe(async (doc) => {
      if (doc.idAtendimento != null) {
        that.hasAtendimentoOberverById(
          doc.idAtendimento
        );
      }
    }
    );
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoObserverCollectionAtendimento', ex.message, "")
  }
};

export const atendimentoIniciar = async () => {
  var uid = Cripto.DecryptedStorage('uid');
  var medicoNome = Cripto.DecryptedStorage("medicoNome");
  var medicoCrm = Cripto.DecryptedStorage("medicoCrm");
  var medicoFoto = Cripto.DecryptedStorage("medicoFoto");
  return await new Promise(async (resolve, reject) => {
    try {
      var idToken = await getIdToken();
      request.post(`/fila/${uid}/atender`, {
        nomeMedico: medicoNome,
        crmMedico: medicoCrm,
        fotoMedico: medicoFoto,
        colorMedico: Cripto.DecryptedStorage('color'),
      },
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      )
        .then((resp) => {
          if (resp.data.statusOk) {
            resolve();
          } else {
            reject();
          }
          return;
        }).catch(function (erro) {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciar2', erro.message, "")
          reject("Erro ao chamar inicio de atendimento - firebase");
        });
    } catch (ex) {
      Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciar4', ex.message, "")
    }
  });
}
export const atenderConsultaAgendada = async (agendaId) => {
  return await new Promise(async (resolve, reject) => {
    var uid = Cripto.DecryptedStorage('uid');
    try {
      var idToken = await getIdToken();
      request.post(`/medico/${uid}/agenda/${agendaId}/atender`,
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      )
        .then((resp) => {
          console.log(resp)
          if (resp.data.statusOk) {
            resolve();
          } else {
            reject(resp.data.message);
          }
          return;
        }).catch(function (erro) {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciar2', erro.message, "")
          reject("Erro ao chamar inicio de atendimento - firebase");
        });
    } catch (ex) {
      Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciar4', ex.message, "")
    }
  });
}

export const atendimentoIniciaCall = async () => {
  try {
    const idMedico = Cripto.DecryptedStorage('uid');
    const idAtendimento = Cripto.DecryptedStorage('id_consulta');
    const idPaciente = store.getState().atendimento.idPaciente;
    return await new Promise(async (resolve, reject) => {
      var idToken = await getIdToken();
      request.post(`/medico/${idMedico}/atendimento/${idAtendimento}/paciente/${idPaciente}/chamarvideo`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      )
        .then((resp) => {
          if (resp.data.statusOk) {
            resolve();
          } else {
            reject();
          }
        })
        .catch(function (erro) {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciaCall1', erro.message, "")
          reject("Erro ao chamar inicio de atendimentoIniciaCall");
        });
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoIniciaCall2', ex.message, "")
  }
};

export const atendimentoFinalizaCallSincrona = async () => {
  try {
    const idMedico = Cripto.DecryptedStorage('uid');
    const idAtendimento = Cripto.DecryptedStorage('id_consulta');
    const idPaciente = store.getState().atendimento.idPaciente;
    return await new Promise(async (resolve, reject) => {
      var idToken = await getIdToken();
      request.post(`/medico/${idMedico}/atendimento/${idAtendimento}/paciente/${idPaciente}/finalizarvideo`,
        {},
        {
          headers: {
            "Authorization": `Bearer ${idToken}`,
          }
        }
      )
        .then((resp) => {
          if (resp.data.statusOk) {
            resolve();
          } else {
            reject();
          }
        })
        .catch((e) => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFinalizaCallSincrona1', e.message, "")
          reject();
        });
    })
  } catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFinalizaCallSincrona2', ex, "")
    return false;
  }
};

export const atendimentoObservableFila = (setColorFilaNumber) => (dispatch) => {
  try {
    const collectionFila = firebase.firestore().collection("fila");
    collectionData(collectionFila, "uid").subscribe((filaItem) => {
      var totalItens = 0;
      var itens = []
      filaItem.map((doc) => {
        itens.push(doc.uid);
        totalItens++;
      });
      dispatch(atendimentoTotalFila(totalItens));
      if (totalItens > 0) {
        setColorFilaNumber(itens[0].charAt(0))
      }
    });
  }
  catch (ex) {
    alert(ex.message)
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoObservableFila', ex.message, "")
  }
};

export const verifyStatusAtendimento = () => {
  try {
    return async () => {
      const idMedico = Cripto.DecryptedStorage('uid');
      const atendimentoId = Cripto.DecryptedStorage('id_consulta')
      const docRef = firebase
        .firestore()
        .collection("medicos")
        .doc(idMedico).
        collection('atendimentos')
        .doc(atendimentoId);
      docData(docRef).subscribe((doc) => {
        var status = doc.status;
        alert(status);
      }
      )
    }
    /*
      0 : NORMAL
      1 : ATENDENDO
      2 : EM CONSULTA
      3 : CHAMANDO
      4 : EM VÍDEO CONFERENCIA
      9: PROCURANDO
    */
  } catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'verifyStatusMedico', ex.message, "")
  }
}

export const getListOcorrencias = async () => {
  return await new Promise(async (resolve, reject) => {
    try {
      var listaOcorrencia = await firebase.firestore().collection('ocorrencias').get();
      if (listaOcorrencia.empty) {
        resolve([]);
      } else {
        var lista = [];
        listaOcorrencia.docs.forEach(ocorrencia => {
          if (ocorrencia.data().ativo === true) {
            var item = {
              value: ocorrencia.id,
              label: ocorrencia.data().titulo,
              descricao: ocorrencia.data().descricao,
              color: "#00B8D9",
            }
            lista.push(item);
          }
        });
        resolve(lista)
      }
    } catch (error) {
      reject()
    }
  });
}

export const getListPrescricoes = async () => {
  return await new Promise(async (resolve, reject) => {
    try {
      var listaPrescricao = await firebase.firestore().collection('prescricaoMedica').get();
      listaPrescricao.docs.forEach(prescricao => {
      })
      if (listaPrescricao.empty) {
        resolve([]);
      } else {
        var lista = [];
        listaPrescricao.docs.forEach(prescricao => {
          if (prescricao.data().ativo === true) {
            var item = {
              value: prescricao.id,
              label: prescricao.data().titulo,
              descricao: prescricao.data().descricao,
              color: "#00B8D9",
            }
            lista.push(item);
          }
        });
        resolve(lista)
      }
    } catch (error) {
      reject()
    }
  });
}

export const verifyStatusMedico = () => {
  try {
    return async (dispatch) => {
      const idMedico = Cripto.DecryptedStorage('uid');
      var status = '';
      const docRef = firebase
        .firestore()
        .collection("medicos")
        .doc(idMedico);
      docData(docRef).subscribe((doc) => {
        status = doc.statusMedico;
        dispatch(statusMedico(status));
      }
      )
    }
    /*
      0 = normal, 1 = chamando, 2 =sendo chamado, 3 = video conferencia
    */
  } catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'verifyStatusMedico', ex.message, "")
  }
}
export const atendimentoObservableTemposConsulta = () => {
  try {
    return async (dispatch) => {
      try {
        var idMedico = Cripto.DecryptedStorage('uid');
        var idConsulta = Cripto.DecryptedStorage("id_consulta");

        var snapshot = await firebase
          .firestore()
          .collection("medicos")
          .doc(idMedico)
          .collection("atendimentos")
          .doc(idConsulta)
          .get();
        var tempo = {
          tempoEsperaFila: 0,
          tempoAtendimento: 0,
        };

        if (snapshot.data() == undefined) return;

        var dataIniFila = snapshot.data().dataIniFila;
        var dataIniAtendimento = snapshot.data().dataIniAtendimento;
        var dataFimAtendimento = snapshot.data().dataFimAtendimento; //nothing to now

        var tempoEsperaFila = dataIniAtendimento - dataIniFila;

        tempo.tempoEsperaFila =
          (tempoEsperaFila / 1000).toString().replace(/\D/g, "") * 1;
        tempo.tempoAtendimento = new Date().getTime() - dataIniAtendimento;

        await dispatch(atendimentoTempos(tempo));
        return true;
      } catch (e) {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoObservableTemposConsulta1', e.message, "")
        return false;
      }
    };
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoObservableTemposConsulta2', ex.message, "")
    return false;
  }
};

export const atendimentoObservableIndicadores = () => (dispatch) => {
  try {
    const docRef = firebase
      .firestore()
      .collection("indicadores")
      .doc("tempoMedio");

    docData(docRef).subscribe((doc) => {
      var tempoMedioAtendimento = 0;
      var tempoMedioEspera = 0;
      var tempoMedioTotalServico = 0;

      if (doc.tempoAtendimento != undefined) {
        tempoMedioAtendimento = doc.tempoAtendimento;
      }
      if (doc.tempoEspera != undefined) {
        tempoMedioEspera = doc.tempoEspera;
      }
      if (doc.tempoNoSistema != undefined) {
        tempoMedioTotalServico = doc.tempoNoSistema;
      }

      var tempo = {
        tempoMedioAtendimento: tempoMedioAtendimento,
        tempoMedioEspera: tempoMedioEspera,
        tempoMedioTotalServico: tempoMedioTotalServico,
      };
      dispatch(atendimentoTemposMedios(tempo));
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoObservableIndicadores', ex.message, "")
  }
};

export const atendimentoFotoPerfilPaciente = async (idPaciente) => {
  try {
    Cripto.EncryptedStorage("atendimento_paciente_foto_selfie", "");
    var snapshot = await firebase
      .firestore()
      .collection("pacientes")
      .doc(idPaciente)
      .collection("profile")
      .doc(idPaciente)
      .get();
    if (snapshot.data().selfie != undefined && snapshot.data().selfie != null) {
      Cripto.EncryptedStorage(
        "atendimento_paciente_foto_selfie",
        snapshot.data().selfie
      );
    }
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFotoPerfilPaciente', ex.message, "")
  }
};



export const encaminhaAtendimentoPorEspecialidade = async (nivel, especialidadeNome) => {
  var idToken = await getIdToken();
  const atendimento = store.getState().atendimento;
  return await new Promise((resolve, reject) => {
    request.post(`/parceiro/${atendimento.idParceiro}/agenda`,
      {
        status: 9,
        documentos: [],
        data: 0,
        isClinicaDigital: true,
        idClinicaDigital: atendimento.idClinicaDigital,
        idMedico: "",
        isDisponivel: false,
        idPaciente: atendimento.idPaciente,
        nomePaciente: atendimento.nomePaciente,
        fotoPaciente: atendimento.fotoPaciente,
        colorPaciente: atendimento.colorPaciente,
        motivoConsulta: "",
        idParceiro: atendimento.idParceiro,
        nomeParceiro: atendimento.nomeParceiro,
        dataIniFilaTriagem: atendimento.dataIniTriagem,
        colorMedico: "",
        crmMedico: "",
        fotoMedico: "",
        nomeMedico: "",
        idAgentePenitenciario: "",
        nomeAgentePenitenciario: "",
        fotoAgentePenitenciario: "",
        colorAgentePenitenciario: "",
        nivel: nivel,
        especialidadeNome: especialidadeNome,
      },
      {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        },
      }
    )
      .then((resp) => {
        console.log(resp);
        if (resp.data.statusOk) {
          resolve()
        }
        resolve("Falha ao encaminhar atendimento")
      })
      .catch((e) => {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFinalizaCallSincrona1', e.message, "")
        reject("Falha ao encaminhar atendimento");
      });
  })
}

export const getEspecialidadesByNivel = async (nivel) => {
  var idToken = await getIdToken();
  return await new Promise((resolve, reject) => {
    request.post(`/especialidades/encaminhamento`,
      {
        nivel: nivel
      },
      {
        headers: {
          "Authorization": `Bearer ${idToken}`,
        },
      }
    )
      .then((resp) => {
        if (resp.data.statusOk) {
          console.log(resp.data.data)
          resolve(resp.data.data)
        }
        resolve([])
      })
      .catch((e) => {
        Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoFinalizaCallSincrona1', e.message, "")
        reject([]);
      });
  })
}

//A VER
export const atendimentoDeleteDocumento = async (idDoc, docNome) => {
  try {
    var idAtendimento = Cripto.DecryptedStorage("id_consulta");
    var documentos = store.getState().atendimento.documentos.filter(documento => documento.id !== idDoc);
    var idMedico = Cripto.DecryptedStorage('uid');
    return await new Promise((resolve, reject) => {
      firebase
        .firestore()
        .collection("medicos")
        .doc(idMedico)
        .collection("atendimentos")
        .doc(idAtendimento)
        .update({
          documentos: documentos
        })
        .then((success) => {
          storage
            .ref(`atendimentos/${idAtendimento}/`)
            .child(`${docNome}`)
            .delete();
          resolve(true)
        })
        .catch((error) => {
          Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoDeleteDocumento1', error.message, "")
          reject(true)
        });
    });
  }
  catch (ex) {
    Log.getInstance().LogginError(Cripto.DecryptedStorage('email'), 'atendimentoDeleteDocumento2', ex.message, "")
  }
};