import { OCORRENCIA_LISTAR	} from '../actions';

const INITIAL_STATE = {
    ocorrencias : [    ] 
}

export default function convenioListReducer(state = INITIAL_STATE, action) {
	switch (action.type) {
		case OCORRENCIA_LISTAR:
            return action.ocorrencias;
		default:
			return state;
	}
}


