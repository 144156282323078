import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from 'react-redux';
import reduxThunk from 'redux-thunk';
import { BrowserRouter, Router } from 'react-router-dom';

import App from './App/index';
import * as serviceWorker from './serviceWorker';

import configOriginal from './configOriginal';
import configDark from './configDark';
import configCollapsed from './configCollapsed';
import Cripto from './utils/cripto'
import rootReducer from '../src/reducers';
import "./i18n";

//const ambiente = window.location.toString();
const ambiente = "xx";

const composeEnhancers = (window.location.toString().includes(ambiente) ?
    compose
    :
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose
);

if (window.location.toString().includes(ambiente)) {
    console.log = function () { }
}

export const store = createStore(rootReducer, composeEnhancers(
    applyMiddleware(reduxThunk)
));




const RouterOriginal = (
    <BrowserRouter basename={configOriginal.basename}>
        {/* basename="/datta-able" */}
        <App />
    </BrowserRouter>
)

const RouterDark = (
    <BrowserRouter basename={configDark.basename}>
        {/* basename="/datta-able" */}
        <App />
    </BrowserRouter>
)


const app = (
    <Provider store={store}>
        {Cripto.DecryptedStorage('color_cliente') == "dark"
            ?
            RouterDark
            :
            RouterOriginal
        }

    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
