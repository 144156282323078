import {
    DEPENDENTES_SET_FIELD,
    DEPENDENTES_SET_CURRENT, 
    DEPENDENTES_SET_CURRENT_PARENTESCO,
    DEPENDENTES_SET_CURRENT_SEXO

} from "../actions";
import DependenteModel from "../Model/dependenteModel";

const INITIAL_STATE = new DependenteModel().voidConstructor();

export default function dependenteReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case DEPENDENTES_SET_CURRENT:
            return action.dependente;
        case DEPENDENTES_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        case DEPENDENTES_SET_CURRENT_PARENTESCO:
            //let tt = { ...doc.data(), id:  doc.id};
            const newState2 = { ...state, parentesco : action.value};
            return newState2;
        case DEPENDENTES_SET_CURRENT_SEXO:
            const newState3 = { ...state, sexo : action.value};
            return newState3;
        default:
            return state;
    }
}