import Translate from "./utils/Translate.js";
export default {

    itemsMenuDinamico: [

        {
            id: 'Dashboard',
            title: '',
            type: 'group',
            visible: 1,
            children: [
                {
                    icon: 'feather icon-home',
                    id: 'Dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    visible: 1,
                },
            ]
        },

        {
            id: 'Cadastros',
            title: '',
            type: 'group',
            icon: 'icon-ui',
            visible: 1,
            children: [
                {
                    id: 'Cadastros',
                    title: Translate.translate("cadastros"),
                    type: 'collapse',
                    icon: 'feather icon-box',
                    visible: 1,
                    children: [
                        {
                            id: 'CadAdministradoresListar',
                            title: Translate.translate("administradores"),
                            type: 'item',
                            url: '/administradores/',
                            visible: 1,
                        },
                        {
                            id: 'CadAgentePenitenciarioListar',
                            title: Translate.translate("agentesPenitenciarios"),
                            type: 'item',
                            url: '/agentes/',
                            visible: 1,
                        },
                        {
                            id: 'CadAntecedentesListar',
                            title: Translate.translate("antecedentes"),
                            type: 'item',
                            url: '/antecedentes',
                            visible: 1,
                        },
                        {
                            id: 'CadAgendamentoConsultaListar',
                            title: Translate.translate("agendamentos"),
                            type: 'item',
                            url: '/agendamentos/',
                            visible: 1,
                        },
                        {
                            id: 'CadClientesListar',
                            title: Translate.translate("clientes"),
                            type: 'item',
                            url: '/clientes/',
                            visible: 1,
                        },

                        {
                            id: 'CadEnfermeirosListar',
                            title: Translate.translate("enfermeiros"),
                            type: 'item',
                            url: '/enfermeiros',
                            visible: 1,
                        },
                        {
                            id: 'CadEstacaoConsultaListar',
                            title: Translate.translate("clinicasDigitais"),
                            type: 'item',
                            url: '/clinicas',
                            visible: 1,
                        },
                        {
                            id: 'CadMedicosListar',
                            title: Translate.translate("medicos"),
                            type: 'item',
                            url: '/medicos',
                            visible: 1,
                        },
                        {
                            id: 'CadParceirosListar',
                            title: Translate.translate("parceiros"),
                            type: 'item',
                            url: '/parceiros/',
                            visible: 1,
                        },
                        {
                            id: 'CadTecnicoEmEnfermagemListar',
                            title: Translate.translate("tecnicos_em_enfermagem"),
                            type: 'item',
                            url: '/tecEnfermagem',
                            visible: 1,
                        },

                        {
                            id: 'CadPerfilListar',
                            title: Translate.translate("perfis"),
                            type: 'item',
                            url: '/perfil/',
                            visible: 1,
                        },


                        {
                            id: 'cadOperadoresListar',
                            title: Translate.translate("operadores"),
                            type: 'item',
                            url: '/operadores/',
                            visible: 1,
                        },

                        {
                            id: 'CadPrescricoesMedicasListar',
                            title: Translate.translate("prescricoes_medicas"),
                            type: 'item',
                            url: '/prescricoes/',
                            visible: 1,
                        },
                        {
                            id: 'CadHipotesesDiagnosticasListar',
                            title: Translate.translate("hipoteses_diagnosticas"),
                            type: 'item',
                            url: '/hipoteses/',
                            visible: 1,
                        },
                        {
                            id: 'CadTurnosListar',
                            title: Translate.translate("turnos"),
                            type: 'item',
                            url: '/turnos/',
                            visible: 1,
                        },
                        {
                            id: 'CadEspecialidadesListar',
                            title: Translate.translate("especialidades"),
                            type: 'item',
                            url: '/especialidades/',
                            visible: 1,
                        },
                        {
                            id: 'CadOcorrenciasListar',
                            title: Translate.translate("ocorrencias"),
                            type: 'item',
                            url: '/ocorrencias/',
                            visible: 1,
                        },
                    ]
                }

            ]
        },
        {
            id: 'Logoff',
            title: '',
            type: 'group',
            visible: 1,
            children: [
                {
                    icon: 'feather icon-log-out',
                    id: 'Logoff',
                    title: Translate.translate("sair"),
                    type: 'item',
                    url: '/logoff',
                    visible: 1,
                }
            ]
        }
    ]
}



