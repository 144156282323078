import Cripto from "../utils/cripto";

class SolicitacaoAtendimentoClinicaDigital {
    constructor() {

    }
    voidConstructor() {
        try {
            let solicitacaoModel = {
                idPaciente: "",
                isDependente: false,
                idDependente: "",
                lat: 0,
                long: 0,
                motivoConsulta: "",
                idParceiro: "",
                nomeParceiro: "",
                isClinicaDigital: true,
                idClinicaDigital: Cripto.DecryptedStorage('idClinicaDigital'),
                idTecEnfermagem: Cripto.DecryptedStorage('uid'),
                nomeTecEnfermagem: Cripto.DecryptedStorage('nome'),
                fotoTecEnfermagem: Cripto.DecryptedStorage('fotoPerfil'),
                colorTecEnfermagem: Cripto.DecryptedStorage('color'),
                corenTecEnfermagem: Cripto.DecryptedStorage('tecEnfermeiroCoren'),
                nomeClinicaDigital: Cripto.DecryptedStorage('nomeClinicaDigital'),
                dataIniFilaTriagem: 0,
                devices: [],
                tipo: Cripto.DecryptedStorage('tipoEstacao').toUpperCase()
            };
            console.log(solicitacaoModel);
            return solicitacaoModel;
        } catch (error) {
            console.log(error)
        }

    }
    completeConstructor(solicitacao) {
        var solicitacaoModel = {
            idPaciente: solicitacao.idPaciente,
            isDependente: solicitacao.isDependente,
            idDependente: solicitacao.idDependente,
            lat: solicitacao.lat,
            long: solicitacao.long,
            motivoConsulta: solicitacao.motivoConsulta,
            idParceiro: solicitacao.idParceiro,
            nomeParceiro: solicitacao.nomeParceiro,
            isClinicaDigital: solicitacao.isClinicaDigital,
            idClinicaDigital: solicitacao.idClinicaDigital,
            idTecEnfermagem: solicitacao.idTecEnfermagem,
            nomeTecEnfermagem: solicitacao.nomeTecEnfermagem,
            fotoTecEnfermagem: solicitacao.fotoTecEnfermagem,
            nomeClinicaDigital: solicitacao.nomeClinicaDigital,
            colorTecEnfermagem: solicitacao.colorTecEnfermagem,
            corenTecEnfermagem: solicitacao.corenTecEnfermagem,
            dataIniFilaTriagem: 0,
            devices: solicitacao.devices,
            tipo: solicitacao.tipo,
        };
        return solicitacaoModel;
    }
}
export default SolicitacaoAtendimentoClinicaDigital;