import Cripto from "../utils/cripto";
import firebase from '../config/firebase';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import { store } from '../index.js'
import { docData } from "rxfire/firestore";
import { atendimentoSetCurrent, atendimentosListar } from './atendimentoActions';
import { getIdToken, request } from ".";
import SolicitacaoAtendimentoClinicaDigital from "../Model/SolicitacaoAtendimentoClinicaDigital";
import { dispatch } from "rxjs/internal/observable/pairs";
import { sweetAlertHandlerError } from "../components/SweetAlert/sweetAlert";
import { replaceEspace } from "../utils/utils";
import { removeMaskFromCPF } from "../utils/cpf";

const db = firebase.firestore();

/////Abrir Consultas Criar Pacientes//////
export const ESTACAO_CONSULTA_SET_CURRENT_PACIENTE = 'ESTACAO_CONSULTA_SET_CURRENT_PACIENTE';
export const setCurrentPaciente = (field, value) => {
    return {
        type: ESTACAO_CONSULTA_SET_CURRENT_PACIENTE,
        field,
        value,
    }
};

export const ESTACAO_CONSULTA_SET_FIELD = 'ESTACAO_CONSULTA_SET_FIELD';
export const setFieldConsulta = (field, value) => {
    return {
        type: ESTACAO_CONSULTA_SET_FIELD,
        field,
        value,
    }
};

export const atendimentoColocarFila = async () => {

    return await new Promise(async (resolve, reject) => {
        try {
            var idToken = await getIdToken();
            const ClinicaDigitalSolicitacaoModel = new SolicitacaoAtendimentoClinicaDigital().completeConstructor(store.getState().clinicaDigital.atendimento);
            ClinicaDigitalSolicitacaoModel.motivoConsulta = replaceEspace(ClinicaDigitalSolicitacaoModel.motivoConsulta);
            request.post('/filatriagem', ClinicaDigitalSolicitacaoModel, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }).then((resp) => {
                if (resp.data.statusOk) {
                    resolve();
                } else {
                    reject()
                }
            }).catch((error) => {
                reject()
            })
        } catch (ex) {
            console.log('atendimentoColocarFila ERROR 1');
            console.log(ex.message);
            reject();
        }
    });
};

export const dispatchToEstacao = (paciente) => {
    try {
        return async dispatch => {
            await dispatch(setCurrentPaciente('paciente', paciente));
        }
    } catch (error) {
        console.log(error);
        return false;
    }
}
//"Authorization": `Bearer ${getIdToken()}`,

export const buscaClientePorCPF = async (cpf) => {
    return await new Promise(async (resolve, reject) => {
        try {
            var cpfSemMascara = removeMaskFromCPF(cpf);
            var idClinicaDigital = store.getState().tecEnfermeiro.idClinicaDigital;
            var idToken = await getIdToken();
            request.get(`/pacienteporcpf/${cpfSemMascara}/clinicadigital/${idClinicaDigital}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            }).then((resp) => {
                if( resp.data.statusOk !== false){
                    if (resp.data.message !== 'notFound_CLIENTE') {
                        resolve(resp.data.data)
                    } else {
                        reject(resp.data.message);
                    }
                }else{
                    reject(resp.data.message);
                }
            })
                .catch((error) => {
                    reject('notFound_CLIENTE')
                });
        } catch (ex) {
            reject('notFound_CLIENTE')
        }
    })

};

export const getValueDevices = async (id, idAtendimento, idClinicaDigital, tipoUsuario) => {
    var idToken = await getIdToken();
    if (tipoUsuario === 'tecEnfermagem') {
        var idPaciente = store.getState().clinicaDigital.paciente.idPaciente;
        console.log(idClinicaDigital)
        return await new Promise((resolve, reject) => {
            request.post(`/tecenfermagem/${id}/clinicadigital/${idClinicaDigital}/paciente/${idPaciente}/getsinaisvitais`, {},
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then(resp => {
                console.log(resp)
                if (resp.data.statusOk === true) {
                    resolve(resp.data.data);
                } else {
                    console.log(resp)
                    reject('Ocorreu um erro ao aferir os sinais vitais')
                }
            }).catch((error) => {
                reject(error)
            })
        })
    } else {
        return (
            request.post(`/${tipoUsuario}/${id}/atendimento/${idAtendimento}/clinicadigital/${idClinicaDigital}/getsinaisvitais`, {
            },
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            )
                .then((resp) => {
                    console.log(resp)
                }).catch((error) => {
                    console.log(error)
                })
        );
    }
}

export const atendimentoObserverCollectionConsultas = (consultaId) => {
    try {
        return async dispatch => {
            const TecEnfermagemId = Cripto.DecryptedStorage('uid');
            const docRef = firebase.firestore().collection('tecEnfermagem').doc(TecEnfermagemId).collection("atendimentos").doc(consultaId);
            docData(docRef).subscribe(async (doc) => {
                if (doc.status === undefined) {
                    dispatch(setCurrentPaciente('consulta', {}))
                }
                let consulta = { ...doc, inConsulta: true }
                dispatch(setCurrentPaciente('consulta', consulta))
            }
            );
        }
    }
    catch (ex) {
        console.log('atendimentoObserverCollectionConsultas ERROR');
        console.log(ex.message);
    }
};

export const atendimentosTecEnfermagemLista = () => {
    try {
        return async (dispatch) => {
            var id = Cripto.DecryptedStorage('uid');

            var snapshot = await firebase
                .firestore()
                .collection("tecEnfermagem")
                .doc(id)
                .collection("atendimentos")
                .orderBy("dataIniTriagem", "desc")
                .limit(10)
                .get();
            var item = [];
            if (snapshot.empty) {
                await dispatch(atendimentosListar('sem consultas'));
            } else {
                snapshot.docs.map((doc) => {
                    let atendimento = { ...doc.data(), id: doc.id };
                    item.push(atendimento);
                });
                await dispatch(atendimentosListar(item));
            }
        };
    }
    catch (ex) {
    }
};

export const observableAtendimentoEstacao = () => (dispatch) => {
    const idMedico = Cripto.DecryptedStorage('uid');
    const idAtendimento = Cripto.DecryptedStorage('id_consulta');
    const collectionMedico = db
        .collection("tecEnfermagem")
        .doc(idMedico)
        .collection('atendimentos')
        .doc(idAtendimento);
    docData(collectionMedico, 'uid').subscribe(async (atendimento) => {
        dispatch(atendimentoSetCurrent(atendimento))
    });
}

export const CancelarFila = async () => {
    return await new Promise(async (resolve, reject) => {
        const TecEnfermeiro = store.getState().tecEnfermeiro;
        var idToken = await getIdToken();
        request.delete(`/filatriagem/${TecEnfermeiro.idAtendimento}`,
            {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve()
                } else {
                    reject()
                }
            })
            .catch(error => {
                console.log(error)
                reject()
            });
    })
};

export const CLINICA_DIGITAL_LOGIN = 'CLINICA_DIGITAL_LOGIN';
export const clinicaDigitalLogin = (value) => {
    return {
        type: CLINICA_DIGITAL_LOGIN,
        value,
    }
};
export const CLINICA_DIGITAL_GET_DADOS = 'CLINICA_DIGITAL_DADOS';
export const clinicaDigitalGetDados = (value) => {
    return {
        type: CLINICA_DIGITAL_GET_DADOS,
        value
    }
}

export const getDadosClinicas = (idParceiro, idClinicaDigital) => dispatch => {
    try {
        console.log(idParceiro, idClinicaDigital)
        const dadosClinica = db.collection('parceiros').doc(idParceiro).collection('clinicasDigitais').doc(idClinicaDigital);
        docData(dadosClinica, "uid").subscribe(async (clinica) => {
            console.log(clinica)
            dispatch(clinicaDigitalGetDados(clinica));
        });
    } catch (error) {
        console.log(error)
        return error
    }
}

export const loginClinica = (email, senha, idParceiro, idClinicaDigital) => dispatch => {
    firebase.auth().signInWithEmailAndPassword(email, senha)
        .then(async () => {
            dispatch(clinicaDigitalLogin(true))
        })
        .catch((error) => {
            console.log(error)
            return alert('error Login')
        });
}

export const setStatusClinica = async (idParceiro, idClinicaDigital) => {
    return await new Promise((resolve, reject) => {
        try {
            console.log(idParceiro)
            console.log(idClinicaDigital)
            firebase
                .firestore()
                .collection('parceiros')
                .doc(idParceiro)
                .collection('clinicasDigitais')
                .doc(idClinicaDigital)
                .update({
                    statusClinicaDigital: 0,
                }).then(() => {
                    resolve();
                }).catch((error) => {
                    reject(error)
                })
        } catch (error) {
            console.log(error)
            reject(error)
        }
    })
}