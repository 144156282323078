import {
    OCORRENCIA_SET_FIELD,
    OCORRENCIA_SET_CURRENT
} from '../actions';
import OcorrenciaModel from '../Model/prescricaoModel';

const INITIAL_STATE_PARCEIRO = new OcorrenciaModel().voidConstructor();

export default function convenioReducer(state = INITIAL_STATE_PARCEIRO, action) {
    switch (action.type) {
        case OCORRENCIA_SET_CURRENT:
            return action.ocorrencia;
        case OCORRENCIA_SET_FIELD:
            const newState = { ...state };
            newState[action.field] = action.value;
            return newState;
        default:
            return state;
    }
}
