import 'firebase/auth';
import 'firebase/firestore';
import { store } from '../index.js'
import TurnoModel from '../Model/turnoModel.js';
import { getIdToken, request } from '.';
import { replaceEspace, replaceEspaceFieldsInObject } from '../utils/utils.js';

//Muda um campo especifico do turno atual na redux
export const TURNO_SET_FIELD = 'TURNO_SET_FIELD';
export const turnoSetField = (field, value) => {
    return {
        type: TURNO_SET_FIELD,
        field,
        value,
    }
}
//Muda o turno atual na redux
export const TURNO_SET_CURRENT = 'TURNO_SET_CURRENT';
export const turnoSetCurrent = turno => ({
    type: TURNO_SET_CURRENT,
    turno
});

//Para não fazer uma nova requisição é chamada essa Function que pega o turno desejado na lista da Redux
export const getTurno = async (id) => {
    return await new Promise((resolve, reject) => {
        if (id == null) {
            //Faz a chamada do model Turno e chama o metodo que constrói a Classe de turno vazia
            const turno = new TurnoModel().voidConstructor();
            resolve(turno);
        } else {
            var currentValue = store.getState().turnosList;
            console.log(currentValue)
            currentValue.map((item) => {
                if (item.id === id) {
                    //Faz a chamada do model turno e chama o metodo que constrói a Classe de turno
                    const turno = new TurnoModel().completeConstructor(item);
                    resolve(turno);
                }
            });
        }
    });
}


//Faz a requisição para atualizar a variavel ATIVO do turno para True or False;
export const turnoSetAtivo = async (id, ativo) => {
    return await new Promise(async (resolve, reject) => {
        var turno = await getTurno(id);
        turno.ativo = ativo ? false : true;
        var idToken = await getIdToken();
        try {
            request.patch(
                `/turno/${id}`,
                turno,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                resolve(resp.data.statusOk);
            }).catch((error) => {
                reject();
            });
        } catch (error) {
            reject();
        }
    });
}

//Envia o turno para tela de Create ou Update 
export const turnoSetAtual = (id) => async dispatch => {
    if (id == null) {
        var turno = new TurnoModel().voidConstructor();
        dispatch(turnoSetCurrent(turno));
    } else {
        var idToken = await getIdToken();
        request
            .get(`/turno/${id}`, {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then(resp => {
                const turno = new TurnoModel().completeConstructor(resp.data.data);
                dispatch(turnoSetCurrent(turno));
            }).catch(error => {
                console.log(error)
            });
    }
}

//Faz a requisição para atualizar o turno
export const turnoAtualizar = async (idTurno) => {
    //Faz a chamada do model turno e chama o metodo que constrói a Classe de turno para atualizar
    var turnoModel = replaceEspaceFieldsInObject (new TurnoModel().completeConstructor(store.getState().turno));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.patch(
                `/turno/${turnoModel.id}`,
                //Body
                turnoModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp);
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//Faz a requisição para cadastrar o turno
export const turnoCriar = async () => {
    //Faz a chamada do model turno e chama o metodo que constrói a Classe de turno para cadastrar um novo turno
    var turnoModel = replaceEspaceFieldsInObject (new TurnoModel().completeConstructor(store.getState().turno));
    return await new Promise(async (resolve, reject) => {
        var idToken = await getIdToken();
        try {
            request.post(
                "/turno/",
                //Body
                turnoModel,
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                }
            ).then((resp) => {
                console.log(resp)
                if (resp.data.statusOk) {
                    resolve(true)
                } else {
                    reject(resp.data.message);
                }
            }).catch((error) => {
                reject(error)
            });
        } catch (error) {
            reject(error)
        }
    });
}

//########################LISTAGEM!################################################################################
export const TURNO_LISTAR = 'TURNO_LISTAR';
const turnoListar = (turno) => ({
    type: TURNO_LISTAR,
    turnos: turno,
});

//Chama a requisição para listar todos os turnos
export const turnosLista = (callback) => {
    return async dispatch => {
        const arrTurnos = [];
        var idToken = await getIdToken();
        request
            .get("/turnos", {
                headers: {
                    "Authorization": `Bearer ${idToken}`,
                }
            })
            .then((resp) => {
                resp.data.data.map(turno => {
                    //Faz a chamada do model turno e chama o metodo que constrói a Classe de turno
                    let turnoModel = new TurnoModel().completeConstructor(turno)
                    arrTurnos.push(turnoModel);
                })
                //Envia o array de turnos para a redux
                callback(arrTurnos);
                return dispatch(turnoListar(arrTurnos));
            }).catch(error => {
                console.log(error)
            });
    }
};

//Chama a requisição para listar todos os turnos e converte para encaixar em um elemento de select
export const turnosListaToSelect = () => {
    return async dispatch => {
        var item = [];
        var idToken = await getIdToken();
        request
            .get("/turnos",
                {
                    headers: {
                        "Authorization": `Bearer ${idToken}`,
                    }
                })
            .then((resp) => {
                resp.data.data.map(turno => {
                    //Faz a chamada do model turno e chama o metodo que constrói a Classe de turno
                    let tt = {
                        value: turno.id,
                        label: turno.nome,
                        dataHoraIni: turno.dataHoraIni,
                        dataHoraFim: turno.dataHoraFim,
                        color: "#00B8D9",
                    }
                    item.push(tt);;
                })
                //Envia o array de turnos para a redux
                dispatch(turnoListar(item));
            }).catch(error => {
                console.log(error)
            });
    }
}
export default turnoAtualizar