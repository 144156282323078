class HorarioAgendaMedica {
    constructor() {
      
    };

    completeConstructor(horario) {
        return {
            id: horario.id,
            dom: horario.dom,
            seg: horario.seg,
            ter: horario.ter,
            qua: horario.qua,
            qui: horario.qui,
            sex: horario.sex,
            sab: horario.sab,
            horaIni: horario.horaIni,
            horaFim: horario.horaFim
        };
    }
    voidConstructor() {
        var horarioModel = {
            id: '',
            dom: false,
            seg: false,
            ter: false,
            qua: false,
            qui: false,
            sex: false,
            sab: false,
            horaIni: '',
            horaFim: '',
        };
        return horarioModel;
    }

    createConstructor(horario) {
        return{
            dom: horario.dom,
            seg: horario.seg,
            ter: horario.ter,
            qua: horario.qua,
            qui: horario.qui,
            sex: horario.sex,
            sab: horario.sab,
            horaIni: horario.horaIni,
            horaFim: horario.horaFim,
        };
    }
}
export default HorarioAgendaMedica;