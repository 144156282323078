import { getColor } from "../utils/foto";

class OperadorModel {
    constructor() {
    }

    voidConstructor() {
        var operadorModel = {
            ativo: true,
            cpf: "",
            dataNasc: "",
            email: "",
            sexo: "",
            fotoPerfil: "",
            nome: "",
            telefone: "",
            celular: "",
            parceiros: [],
            id: '',
            password: '',
            color: getColor()
        };
        return operadorModel;
    };

    createConstructor(operador){
        var operadorModel = {
            ativo: operador.ativo,
            cpf: operador.cpf,
            dataNasc: operador.dataNasc,
            email: operador.email,
            fotoPerfil: operador.fotoPerfil,
            nome: operador.nome,
            telefone: operador.telefone,
            celular: operador.celular,
            sexo: operador.sexo,
            parceiros: operador.parceiros,
            password: operador.password,
            color: operador.color
        };
        return operadorModel;
    };

    completeConstructor(operador){
        var operadorModel = {
            ativo: operador.ativo,
            cpf: operador.cpf,
            dataNasc: operador.dataNasc,
            email: operador.email,
            fotoPerfil: operador.fotoPerfil,
            nome: operador.nome,
            telefone: operador.telefone,
            sexo: operador.sexo,
            celular: operador.celular,
            parceiros: operador.parceiros,
            id: operador.id,
            color: operador.color

        };
        return operadorModel;
    };
}
export default OperadorModel;