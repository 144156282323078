import * as firebase from 'firebase/app';


var firebaseConfig = {
  apiKey: "AIzaSyDxqf1vMlLBtGPLxg71xkN9DN00gSzG-Tk",
  authDomain: "olamed-homolog.firebaseapp.com",
  projectId: "olamed-homolog",
  storageBucket: "olamed-homolog.appspot.com",
  messagingSenderId: "1019195484049",
  appId: "1:1019195484049:web:1388f0c49ba02c267f5c30",
  measurementId: "G-PCYPYRK4CY"
};
firebase.initializeApp(firebaseConfig);


// Initialize Firebase
export default firebase;