import Cripto from './cripto';

var idioma = Cripto.DecryptedStorage('idioma');

var idiomaNavegador = navigator.language;
var idiomaSplited = idiomaNavegador.split('-')[0];
if (idioma === undefined || idioma === "") {
    idioma = idiomaSplited;
}

var message = {
    jaExisteUmFiltroDeIntervaloDeDias: "Já existe um filtro de intervalo de dias",
    jaExisteUmFiltroComEsseMesmoValor: "Já existe um filtro com esse mesmo valor",
    dias: "Dia(s)",
    horas: "Hora(s)",
    minutos: "Minuto(s)",
    segundos: "Segundo(s)",

    descricaoMinLetra: "O campo descrição deve conter no mínimo 1 letra!",
    senhaIncorreta: "Senha incorreta",
    usuarioNaoEncontrado: "Usuário não encontrado",
    erroDesconhecido: "Erro desconhecido",
    preenchaTodosOsCamposCorretamente: "Preencha todos os campos corretamente!",
    emailEOuSenhaErrados: "Email e/ou senhas errados",
    emailESenhaSaoObrigatorios: "Email e senha são obrigatórios",
    emailNaoCadastrado: "Email não cadastrado",
    campoEmailNaoPodeEstarVazio: "Campo de Email não pode estar vazio",
    dadosInvalidos: 'Dados inválidos!',
    cliqueAquiOuArraste: "Clique aqui ou arraste seus arquivos para cá",
    voceSoPodeAnexar1Arquivo: "Você só pode anexar 1 arquivo",
    removerAnexo: "Remover",
    erroAoCriarUsuario: "Erro ao Criar usuário",
    erroAoCadastrarUsuario: "Erro ao cadastrar usuário",
    usuarioValido: "Usuário válido! \nAgora informe um e-mail e senha para completar seu cadastro!",
    usuarioInvalido: "Usuário inválido!",
    erroNaBaseDeDados: "Erro na base de dados!",
    nomeMaeInvalido: "Nome da mãe inválido!",
    nomeCompletoInvalido: "Nome completo inválido!",
    cpfInvalido: "CPF inválido!",
    usuarioInativo: "Usuário inativo!",
    horarioCadastradoComSucesso: "Horário cadastrado com sucesso",
    horarioAtualizadoComSucesso: "Horário atualizado com sucesso",
    erroAoCadastrarHorario: "Erro ao cadastrar horário",
    erroAoAtualizarHorario: "Erro ao atualizar horário",
    horaIni: "Horário Inicial",
    horaFim: "Horário Final",
    horarios_do_medico: "Horários do médico:",
    diasCadastrados: "Dias que já estão cadastrados:",
    horarioExcluido: "Horário excluído com sucesso!",
    falhaAoExcluirHorario: "Falha ao excluir horário",
    adicionarHorarios: "Horários",
    agenda: "Agenda",


    documentoNaoEhDaMesmaConsulta: "Documento anexado não é da mesma consulta.", //ainda n possui tradução 
    documentoInvalido: "Documento inválido!", //ainda n possui tradução
    erroNoUpload: "Erro ao realizar upload, verifique nome do arquivo!",//ainda n possui tradução
    anexeUmDocumento: "Anexe um documento!",//ainda n possui tradução
    caixaDeDocumentosVazia: "A caixa de documentos esta vazia!",//ainda n possui tradução
    selecione_o_sexo: "Selecione o sexo",//ainda n possui tradução
    voceDeveSelecionarAPessoa: "Você deve selecionar a pessoa",//ainda n possui tradução
    voceDeveDigitarAQueixaPrincipal: "Você deve digitar a queixa principal",//ainda n possui tradução
    voceDeveSelecionarOParceiro: "Você deve selecionar o parceiro",//ainda n possui tradução
    usuarioCadastradoComSucesso: "Usuário cadastrado com sucesso!",//ainda n possui tradução
    oPaciente: "O paciente",//ainda n possui tradução
    jaEstaEmUmaOutraConsulta: "já esta em uma outra consulta",//ainda n possui tradução
    campoDeCPFNaoPodeEstarVazio: "O campo de CPF não pode estar vazio",//ainda n possui tradução
    cpfDigitadoInvalido: "O CPF digitado é inválido",//ainda n possui tradução
    estaNaFilaDeTriagem: "esta na fila de triagem",//ainda n possui tradução
    oEnfermeiro: "O enfermeiro",//ainda n possui tradução
    estaRealizandoTriagemDoPaciente: "esta realizando triagem do paciente",//ainda n possui tradução
    foiEncaminhadoParaOAtendimento: "foi encaminhado para o atendimento médico",//ainda n possui tradução
    estaRealizandoTriagemDoPaciente: "esta realizando triagem do paciente",//ainda n possui tradução
    oMedico: "O médico",//ainda n possui tradução
    estaRealizandoOAtendimentoDoPaciente: "esta realizando o atendimento do paciente",//ainda n possui tradução
    oEnfermeiro: "O enfermeiro",//ainda n possui tradução
    aConsultaDoPaciente: "A consulta do paciente",//ainda n possui tradução
    foiEncerradaRedirecionandoParaATelaPrincipal: "foi encerrada! Redirecionando para a tela principal",//ainda n possui tradução
    solicitacaoExames: "Solicitação de exames",//ainda n possui tradução
    receituarioEspecial: "Receituário especial",//ainda n possui tradução
    receituarioSimples: "Receituário simples",//ainda n possui tradução
    relatorioMedico: " Relatório médico",//ainda n possui tradução
    atestado: "Atestado",//ainda n possui tradução
    preenchaTodosOsCamposParaPoderGerarOReceituarioEspecial: "Preencha todos os campos para poder gerar o receituário especial",//ainda n possui tradução  
    preenchaTodosOsCamposParaPoderGerarOAtestadoMedico: "Preencha todos os campos para poder gerar o atestado médico",//ainda n possui tradução  
    preenchaTodosOsCamposParaPoderGerarORelatorioMedico: "Preencha todos os campos para poder gerar o relatório médico",//ainda n possui tradução  
    preenchaTodosOsCamposParaPoderGerarUmaSolicitacaoDeExame: "Preencha todos os campos para poder gerar uma solicitação de exame",//ainda n possui tradução  
    preenchaTodosOsCamposParaPoderGerarOReceituarioSimples: "Preencha todos os campos para poder gerar o receituário simples",//ainda n possui tradução  
    falhaAoFazerDownloadDoPDF: "Falha ao fazer download do PDF",//ainda n possui tradução  
    campoDeTextoNaoPodeEstarVazio: "O campo de texto não pode estar vazio",//ainda n possui tradução
    falhaAoSalvar: "Falha ao salvar",//ainda n possui tradução
    confirmarInativacao: "Confirmar inativação?",//ainda n possui tradução
    hipoteseDiagnostica: "Hipótese diagnóstica",//ainda n possui tradução
    hipoteses_diagnosticas: "Hipóteses diagnósticas",//ainda n possui tradução
    especialidades: "Especialidades",//ainda n possui tradução
    especialidade: "Especialidade",//ainda n possui tradução
    emCasoDeProblemas: "Em caso de problema(s)",//ainda n possui tradução
    realizeUmaOcorrencia: "realize uma ocorrência",//ainda n possui tradução
    vocePrecisaEnviarO: "Você precisa enviar o",//ainda n possui tradução
    paraEncerrarOAtendimento: "para encerrar o atendimento.",//ainda n possui tradução
    vocePrecisaPreencherA: "Você precisa preencher a",//ainda n possui tradução
    conduta: "Conduta",//ainda n possui tradução
    anamnese: "Anamnese",//ainda n possui tradução
    eEnviarO: "e enviar o",//ainda n possui tradução
    eA: "e a",//ainda n possui tradução
    digiteOCampoNomeCorretamente: "Digite o campo de nome corretamente.",//ainda n possui tradução
    cepInformadoNaoFoiEncontrado: "O CEP informado não foi encontrado",//ainda n possui tradução
    erroAoverificarDados: "Erro ao verificar dados!",//ainda n possui tradução
    cpfJaCadastrado: "Cpf já cadastrado!",//ainda n possui tradução
    administradorCadastradoComSucesso: "Administrador cadastrado com sucesso!",//ainda n possui tradução
    turnoCadastradoComSucesso: "Turno cadastrado com sucesso!",//ainda n possui tradução
    erroAoVerificarDados: "Erro ao verificar dados.!",//ainda n possui tradução
    telefoneJaCadastradoNoSistema: "Telefone já cadastrado no sistema!",//ainda n possui tradução
    administradorAtualizadoComSucesso: "Administrador atualizado com sucesso!",//ainda n possui tradução
    turnoAtualizadoComSucesso: "Turno atualizado com sucesso!",//ainda n possui tradução
    especialidadeCadastradoComSucesso: "Especialidade cadastrada com sucesso!",//ainda n possui tradução
    especialidadeAtualizadoComSucesso: "Especialidade atualizada com sucesso!",//ainda n possui tradução
    erroAoCadastrarEspecialidade: "Erro ao cadastrar especialidade",//ainda n possui tradução
    erroAoAtualizarEspecialidade: "Erro ao atualizar especialidade",//ainda n possui tradução
    erroAoAtualizarRegistro: "Erro ao atualizar registro!",//ainda n possui tradução
    cpfInvalido: "CPF inválido",//ainda n possui tradução
    antecedenteJaCadastradaNoSistema: "Antecedente já cadastrada no sistema!",//ainda n possui tradução
    antecedenteCadastradoComSucesso: "Antecedente cadastrado com sucesso!",//ainda n possui tradução
    erroAoSalvarRegistro: "Erro ao salvar registro!",//ainda n possui tradução
    respectivoClientePrecisaTerPeloMenos18Anos: "O respectivo cliente precisa ter pelo menos 18 anos.",//ainda n possui tradução
    erroAoCadastrarCliente: "Erro ao cadastrar cliente!",//ainda n possui tradução
    erroAoAtualizarCliente: "Erro ao atualizar cliente!",//ainda n possui tradução
    erroAoCadastrarTurno: "Erro ao cadastrar turno",//ainda n possui tradução
    erroAoAtualizarTurno: "Erro ao atualizar turno",//ainda n possui tradução
    clienteCadastradoComSucesso: "Cliente cadastrado com sucesso!",//ainda n possui tradução
    selecioneUmParceiro: "Selecione um Parceiro!",//ainda n possui tradução
    celularJaCadastradoNoSistema: "Celular já esta cadastrado no sistema!",//ainda n possui tradução
    clienteAtualizadoComSucesso: "Cliente atualizado com sucesso!",//ainda n possui tradução
    cpfInvalido: "CPF Inválido!",//ainda n possui tradução
    cpfCopiadoComSucesso: "CPF copiado com sucesso",//ainda n possui tradução
    realizadoComSucesso: "Realizado com sucesso!",//ainda n possui tradução
    contatoCadastradoComSucesso: "Contato cadastrado com sucesso!",//ainda n possui tradução
    contatoAtualizadoComSucesso: "Contato atualizado com sucesso!",//ainda n possui tradução
    dadosInvalidosVerifiqueSeOCrmUfCpfOuDataNascEstaoIncorretos: "'Dados inválidos, verifique se o CRM, UF, CPF ou Data Nasc. estão incorretos",//ainda n possui tradução
    parceiroCadastradoComSucesso: "Parceiro cadastrado com sucesso!",//ainda n possui tradução
    cnpjJaFoiCadastradoNoSistema: "CNPJ já foi cadastrado no sistema!",//ainda n possui tradução
    ParceiroAtualizadoComSucesso: "Parceiro atualizado com sucesso!",//ainda n possui tradução
    cnpjInvalido: "CNPJ inválido!",//ainda n possui tradução
    dependenteCadastradoComSucesso: "Dependente cadastrado com sucesso!",//ainda n possui tradução
    erroAoCadastrarDependente: "Erro ao cadastrar dependente!",//ainda n possui tradução
    usuarioAtualizadoComSucesso: "Usuário atualizado com sucesso",//ainda n possui tradução
    digiteCampoNomeCorretamente: "Digite o campo de nome corretamente.",//ainda n possui tradução
    oCoren: "O COREN",//ainda n possui tradução
    jaEstaCadastradoNoSistema: "já esta cadastrado no sistema",//ainda n possui tradução
    estacaoAtualizadaComSucesso: "Estação atualizada com sucesso!",//ainda n possui tradução
    estacaoCadastradaComSucesso: "Estação cadastrada com sucesso!",//ainda n possui tradução
    erroAoCapturarPerfilDoUsuario: "Erro ao capturar perfil do usuário!",//ainda n possui tradução
    verifiqueSeOCrmUfCpfDataNascEstaoIncorretos: "verifique se o CRM, UF, CPF ou Data Nasc. estão incorretos",//ainda n possui tradução
    dadosInvalidos: "Dados inválidos!",//ainda n possui tradução
    oCrm: "O CRM",//ainda n possui tradução
    falhaAoFazerUploadDaFoto: "Falha ao fazer upload da foto",
    formatoDeArquivoInválidoEAceitoSomenteImagens: "Formato de arquivo inválido, é aceito somente imagens!",//ainda n possui tradução
    arquivoEMuitoGrande: "O arquivo é muito grande",//ainda n possui tradução
    tamanhoMaximoPermitido: "Tamanho máximo permitido",//ainda n possui tradução
    ocorrenciaCadastradaComSucesso: "Ocorrência cadastrada com sucesso!",//ainda n possui tradução
    ocorrenciaAtualizadaComSucesso: "Ocorrência atualizada com sucesso!",//ainda n possui tradução
    erroAoAtualizarRegistro: "Erro ao atualizar registro!",//ainda n possui tradução
    campoCrmDeveConter06Caracteres: "O campo CRM deve conter 6 caracteres",//ainda n possui tradução
    campoCepDeveConter8Caracteres: "O campo CEP deve conter 8 caracteres",//ainda n possui tradução
    campoCnpjDeveConter14Caracteres: "O campo CNPJ deve conter 14 caracteres",//ainda n possui tradução
    campoDeDataDeNascimentoObrigatorio: "Campo de Data de Nascimento é obrigatório",//ainda n possui tradução
    campoTelefoneDeveConter10Caracteres: "O campo telefone deve conter 10 caracteres",//ainda n possui tradução
    campoCpfDeveConter11Caracteres: "O campo CPF deve conter 11 caracteres",//ainda n possui tradução
    campoTelefoneDeveConter11Caracteres: "O campo telefone deve conter 11 caracteres",//ainda n possui tradução
    campoCorenDeveConter6Caracteres: "O campo COREN deve conter 6 caracteres",//ainda n possui tradução
    campoSexoObrigatorio: "O campo sexo é obrigatório",//ainda n possui tradução
    campoDataDeNascimentoObrigatorio: "O campo data de nascimento é obrigatório",//ainda n possui tradução
    campoParentescoObrigatorio: "O campo parentesco é obrigatório",//ainda n possui tradução
    campoEstacaoDeConsultaObrigatorio: "O campo estação de consulta é obrigatório",//ainda n possui tradução
    campoParceiroObrigatorio: "O campo parceiro é obrigatório",//ainda n possui tradução
    naoEPossivelRemoverOLogoff: "Não é possivel remover o logoff!",//ainda n possui tradução
    naoEPossivelAdicionarTelaDeAtendimentoParaPerfilNaoMedico: "Não é possivel adicionar tela de atendimento, para um perfil que não é médico!",//ainda n possui tradução
    prescricaoMedicaCadastradaComSucesso: "Prescrição médica cadastrada com sucesso!",//ainda n possui tradução
    hipoteseDiagnosticaAtualizadaComSucesso: "Hipótese diagnóstica atualizada com sucesso!",//ainda n possui tradução
    hipoteseDiagnosticaCadastradaComSucesso: "Hipótese diagnóstica cadastrada com sucesso!",//ainda n possui tradução
    prescricaoMedicaAtualizadaComSucesso: "Prescrição médica atualizada com sucesso!",//ainda n possui tradução
    selecioneUmaEstacaoDeConsulta: "Selecione uma Estação de Consulta",//ainda n possui tradução
    selecioneONivelDeGravidadeAtendimento: "Selecione o nível de gravidade atendimento",//ainda n possui tradução
    digiteOCampoDeAlturaCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de altura corretamente ou marque o campo indeferido.",//ainda n possui tradução
    digiteOCampoDePesoCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de peso corretamente ou marque o campo indeferido.",//ainda n possui tradução
    digiteOCampoDeTemperaturaCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de temperatura corretamente ou marque o campo indeferido.",//ainda n possui tradução
    digiteOCampoDePressaoArterialCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de pressão arterial corretamente ou marque o campo indeferido.",//ainda n possui tradução
    digiteOCampoDePulsoCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de pulso corretamente ou marque o campo indeferido.",//ainda n possui tradução
    digiteOCampoDeRpmCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de RPM corretamente ou marque o campo indeferido.",//ainda n possui tradução
    digiteOcampoDeSpo2CorretamenteOuMarqueOCampoIndeferido: "Digite o campo de saturação de oxigênio corretamente ou marque o campo indeferido.",//ainda n possui tradução
    selecioneONivelDeGravidadeAtendimento: "Selecione o nível de gravidade atendimento",//ainda n possui tradução
    cadastros: "Cadastros",//ainda n possui tradução
    administradores: "Administradores",//ainda n possui tradução
    antecedentes: "Antecedentes",//ainda n possui tradução
    clientes: "Clientes",//ainda n possui tradução
    enfermeiros: "Enfermeiros",//ainda n possui tradução
    estacoesDeConsulta: "Estações de Consulta",//ainda n possui tradução
    medicos: "Médicos",//ainda n possui tradução
    parceiros: "Parceiros",//ainda n possui tradução
    tecnicos_em_enfermagem: "Técnicos em Enfermagem",//ainda n possui tradução
    perfis: "Perfis",//ainda n possui tradução
    operadores: "Operadores",//ainda n possui tradução
    prescricoes_medicas: "Prescrições médicas",//ainda n possui tradução
    ocorrencias: "Ocorrências",//ainda n possui tradução
    sair: "Sair",//ainda n possui tradução
    atendimento: "Atendimento",//ainda n possui tradução
    descricaoAlteracaoConfigUsuario: "Alteração de dados do PERFIL do usuário corrente.",//ainda n possui tradução
    descricaoLogOff: "Opção no menu de logoff (sair) do sistema. Obrigatório para todos usuários.",//ainda n possui tradução
    descricaoVisualizaçãoDoMapa: "Tela DASHBOARD para visualização do MAPA com points de clientes e outros dados.",//ainda n possui tradução
    descricaoDashFila: "Tela DASHBOARD para visualização da FILA e outros dados.",//ainda n possui tradução
    descricaoVideoMedico: "Tela de VIDEO CONFERENCIA para o MEDICO realizar o atendimento e para o ENFERMEIRO realizar a triagem.",//ainda n possui tradução
    descricaoDashTecEnfermeiro: "Tela DASHBOARD para o Técnico em Enfermagem.",//ainda n possui tradução
    descricaoDashTecEnfermagem: "Tela para o técnico de enfermagem iniciar o atendimento do cliente na Estação de Consulta.",//ainda n possui tradução
    descricaoAdmDash: "Tela DASHBOARD para o ADMINISTRADOR.",//ainda n possui tradução
    descricaoDashMedico: "Tela DASHBOARD para o MEDICO capturar clientes da fila e realizar o atendimento.",//ainda n possui tradução
    descricaoDashEnfermeiro: "Tela DASHBOARD para o ENFERMEIRO capturar clientes da fila e realizar a triagem.",//ainda n possui tradução
    descricaoConfigPerfilUser: "Tela para CONFIGURAR o perfil de um usuário especifico. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemPerfisUser: "Tela para LISTAGEM dos perfis de usuários.",//ainda n possui tradução
    descricaoTelaCadastroPerfil: "Tela para CONFIGURAR e CADASTRAR perfil de usuário. Quais telas são permitidas por perfil. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemOperadores: "Tela de LISTAGEM dos operadores.",//ainda n possui tradução
    descricaoCadastroOperadores: "Tela para CADASTRO dos operadores (administradores de um parceiro). (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoCadastroMedicos: "Tela para CADASTRO de médicos atendentes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemMedicos: "Tela de LISTAGEM de médicos atendentes.",//ainda n possui tradução
    descricaoCadastroAntecedentes: "Tela para CADASTRO de antecedentes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemAntecedentes: "Tela de LISTAGEM de antecedentes.",//ainda n possui tradução
    descricaoCadastroConvenios: "Tela para CADASTRO de convênios, onde existem clientes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemConvenios: "Tela de LISTAGEM de convênios.",//ainda n possui tradução
    descricaoCadastroParceiro: "Subtela para CADASTRO de contatos de um parceiro. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemParceiro: "Subtela para LISTAGEM de contatos de um parceiro.",//ainda n possui tradução
    descricaoCadastroCliente: "Tela para CADASTRO de cliente, que pertencem a um parceiro. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemCliente: "Tela de LISTAGEM de clientes.",//ainda n possui tradução
    descricaoCadastroDependente: "Subtela para CADASTRO de dependentes de um cliente. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemDependente: "Subtela para LISTAGEM de dependentes de um cliente.",//ainda n possui tradução
    descricaoCadastroTecEnfermagem: "Subtela para CADASTRO de Técnicos de Enfermagem. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemTecEnfermagem: "Subtela para LISTAGEM de Técnicos em Enfermagem.",//ainda n possui tradução
    descricaoCadastroEstacao: "Subtela para CADASTRO de Estação de Consulta. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemEstacao: "Subtela para LISTAGEM de Estação de Consulta.",//ainda n possui tradução
    descricaoCadastroEnfermeiro: "Tela para CADASTRO de enfermeiros (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemEnfermeiro: "Tela de LISTAGEM de enfermeiros.",//ainda n possui tradução
    descricaoCadastroAdministradores: "Tela para CADASTRO de administradores do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemAdministradores: "Tela de LISTAGEM dos administradores do sistema.",//ainda n possui tradução
    descricaoCadastroOcorrencias: "Tela para CADASTRO de ocorrências do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemOcorrencias: "Tela de LISTAGEM das ocorrências do sistema.",//ainda n possui tradução
    descricaoCadastroPrescricoes: "Tela para CADASTRO de prescrições médicas do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemPrescricoes: "Tela de LISTAGEM das prescrições médicas do sistema.",//ainda n possui tradução
    descricaoCadastroHipoteses: "Tela para CADASTRO de hipóteses diagnósticas do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemHipoteses: "Tela de LISTAGEM das hipóteses diagnósticas do sistema.",//ainda n possui tradução
    descricaoCadastroTurnos: "Tela para CADASTRO de turnos do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemTurnos: "Tela de LISTAGEM dos turnos do sistema.",//ainda n possui tradução
    descricaoCadastroEspecialidades: "Tela para CADASTRO de especialidades do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
    descricaoListagemEspecialidades: "Tela de LISTAGEM das especialidades do sistema.",//ainda n possui tradução
    editar: "Editar",//ainda n possui tradução
    editarContatos: "Editar contatos",//ainda n possui tradução
    adicionarDependentes: "Adicionar dependentes",//ainda n possui tradução
    gerar_documentos: "Gerar Documentos",//ainda n possui tradução,
    enviar_documentos: "Enviar Documentos",//ainda n possui tradução,
    cabineJaEstaCadastrada: "Esta cabine já esta cadastrada no sistema", //ainda n possui tradução,
    enfermeiroCadastradoComSucesso: "Enfermeiro cadastrado com sucesso", //ainda n possui tradução,
    enfermeiroAtualizadoComSucesso: "Enfermeiro atualizado com sucesso", //ainda n possui tradução,
    falhaAoCadastrarAntecedente: "Falha ao cadastrar antecedente",//ainda n possui tradução,
    antecedenteAtualizadoComSucesso: "Antecedente atualizado com sucesso",//ainda n possui tradução
    erroAoAtualizarAntecedente: "Erro ao atualizar antecedente",//ainda n possui tradução
    falhaAoCadastrarOcorrencia: "Falha ao cadastrar ocorrência",//ainda n possui tradução
    erroAoAtualizarOcorrencia: "Erro ao atualizar corrência",//ainda n possui tradução
    ocorrenciaJaCadastradaNoSistema: "Essa ocorrência já esta cadastrada no sistema",//ainda n possui tradução,
    erroAoAtualizarParceiro: "Erro ao atualizar Parceiro!",//ainda n possui tradução,
    erroAoCadastrarParceiro: "Erro ao cadastrar Parceiro!",//ainda n possui tradução,
    clinicasDigitais: "Clínicas digitais",//ainda n possui tradução,
    clinicaDigitalCadastradaComSucesso: "Clínica digital cadastrada com sucesso",//ainda n possui tradução,
    clinicaDigitalJaCadastradaNoSistema: "Clínica digital já cadastrada no sistema",//ainda n possui tradução,
    erroAoCadastrarClinicaDigital: "Erro ao cadastrar clínica digital",//ainda n possui tradução,
    clinicaDigitalAtualizadaComSucesso: "Clínica digital atualizada com sucesso",//ainda n possui tradução,
    erroAoAtualizarClinicaDigital: "Erro ao atualizar clínica digital",//ainda n possui tradução,
    erroAoCadastrarPrescricao: "Erro ao cadastrar prescrição médica",//ainda n possui tradução,
    prescricaoJaCadastradaNoSistema: "Prescrição médica já cadastrada no sistema",//ainda n possui tradução,
    erroAoAtualizarPrescricao: "Erro ao atualizar prescrição médica",//ainda n possui tradução,
    erroAoAtualizarHipotese: "Erro ao atualizar hipótese diagnóstica",//ainda n possui tradução,
    erroAoCadastrarHipotese: "Erro ao cadastrar hipótese diagnóstica",//ainda n possui tradução,
    hipoteseJaCadastradaNoSistema: "Hipótese diagnóstica já cadastrada no sistema",//ainda n possui tradução,
    jaExiste_CPF: "CPF já existe!",//ainda n possui tradução,
    jaExiste_ANTECEDENTE: "Antecedente já existe!",//ainda n possui tradução,
    jaExiste_NOME: "Nome já existe!",//ainda n possui tradução,
    jaExiste_RG: "RG já existe!",//ainda n possui tradução,
    jaExiste_COREN: "COREN já existe!",//ainda n possui tradução,
    jaExiste_CRM: "CRM já existe!",//ainda n possui tradução,
    jaExiste_TITULO: "Título já existe!",//ainda n possui tradução,
    "The email address is already in use by another account.": "E-mail já existe!",//ainda n possui tradução,
    jaExiste_CNPJ: "CNPJ já existe!",//ainda n possui tradução,
    dependenteAtualizadoComSucesso: "Dependente atualizado com sucesso",//ainda n possui tradução,
    erroAoCadastrarAdministrador: "Erro ao cadastrar administrador",//ainda n possui tradução,
    erroAoAtualizarAdministrador: "erroAoAtualizarAdministrador",//ainda n possui tradução
    medicoAtualizadoComSucesso: "Médico atualizado com sucesso",//ainda n possui tradução
    medicoCadastradoComSucesso: "Médico cadastrado com sucesso",//ainda n possui tradução
    erroAoAtualizarMedico: "Erro ao atualizar médico",//ainda n possui tradução
    erroAoCadastrarMedico: "Erro ao cadastrar médico",//ainda n possui tradução
    erroAoCadastrarEnfermeiro: "Erro ao cadastrar enfermeiro",//ainda n possui tradução
    erroAoAtualizarEnfermeiro: "Erro ao atualizar enfermeiro",//ainda n possui tradução
    tecnicoDeEnfermagemCadastradoComSucesso: "Técnico de enfermagem cadastrado com sucesso",//ainda n possui tradução
    tecnicoDeEnfermagemAtualizadoComSucesso: "Técnico de enfermagem atualizado com sucesso",//ainda n possui tradução
    erroAoCadastrarTecnicoDeEnfermagem: "Erro ao cadastrar técnico de enfermagem",//ainda n possui tradução
    erroAoAtualizarTecnicoDeEnfermagem: "Erro ao atualizar técnico de enfermagem",//ainda n possui tradução
    operadorCadastradoComSucesso: "Operador cadastrado com sucesso",//ainda n possui tradução
    operadorAtualizadoComSucesso: "Operador atualizado com sucesso",//ainda n possui tradução
    erroAoCadastrarOperador: "Erro ao cadastrar operador",//ainda n possui tradução
    erroAoAtualizarOperador: "Erro ao atualizar operador",//ainda n possui tradução
    voceNaoEstaEmNenhumAtendimentoNoMomento: "Você não está em nenhum atendimento no momento.",//ainda n possui tradução
    falhaAoCancelarAtendimento: "Falha ao cancelar atendimento.",//ainda n possui tradução
    atendimentoCanceladoComSucesso: "Atendimento cancelado com sucesso.",//ainda n possui tradução
    oAtendimentoFoiEncerrado: "O atendimento foi encerrado",//ainda n possui tradução
    salvoComSucesso: "Salvo com sucesso",//ainda n possui tradução
    erroAoSalvarHipotese: "Erro ao salvar hipótese diagnóstica",//ainda n possui tradução
    erroAoFinalizarVideo: "Erro ao finalizar videoconferência",//ainda n possui tradução
    erroAoIniciarVideo: "Erro ao iniciar videoconferência",//ainda n possui tradução
    falhaAoIniciarTriagem: "Falha ao iniciar triagem",
    falhaAoIniciarAtendimento: "Falha ao iniciar atendimento",
    selecione_prescricao: "Selecione o tipo de prescrição",
    selecione_o_tipo: "Selecione o tipo",//ainda n possui tradução
    tipo: "Tipo",
    erroAoEncerrarTriagem: "Erro ao encerrar triagem",
    jaExiste_CONSULTA: "Cliente já esta em uma consulta",
    "operacaoRealizadaComSucesso": "Operação realizada com sucesso",
    "notFound_CLIENTE": "Cliente não encontrado!",
    voceDeveDigitarAmotivoConsulta: "Você deve preencher o motivo da consulta",
    preenchaOCampoNome: "Preencha o campo nome!",//ainda n possui tradução
    selecioneAHoraDeEncerramentoEAbertura: "Selecione a hora de encerramento e de abertura!",//ainda n possui tradução
    selecionePeloMenosUmDosDias: "Selecione pelo menos um dos dias!",//ainda n possui tradução
    nomeJaEstaEmUso: "O nome já esta em uso!",//ainda n possui tradução
    selecioneAOcorrencia: "Selecione a ocorrência!",
    "linhasPorPagina": "Linhas por página",
    "de": "de",
    "falhaAoAtualizarRegistro": "Falha ao atualizar registro",
    descricaoVazio: "O campo descrição não pode estar vazio!",
    minLetra: "O campo deve conter no mínimo 1 letra!",
    horaDeAberturaEHoraDeEncerramento: "Horário de abertura e horário de encerramento não podem ser iguais!",
    campoDescricao: "O campo de descrição deve conter no minimo 1 letra!",
    turnos: "Turnos",
    clinica: "Clínica Digital",
    totem: "Totem",
    naoInformado: "Não informado",
    agentesPenitenciarios: "Agentes penitenciários",
    agenteCadastradoComSucesso: "Agente penitenciário cadastrado com sucesso!",
    erroAoCadastrarAgente: "Erro ao cadastrar agente penitenciário",
    agenteAtualizadoComSucesso: "Agente penitenciário cadastrado com sucesso!",
    erroAoAtualizarAgente: "Erro ao atualizar agente penitenciário",
    respectivoAgentePrecisaTerPeloMenos18Anos: "Respectivo agente penitenciário precisa ter pelo menos 18 anos",
    voceDeveSelecionarOParceiroDoAgente: "Você deve selecionar um parceiro para o agente penitenciário",
    "seg": "Segunda-feira",
    "ter": "Terça-feira",
    "qua": "Quarta-feira",
    "qui": "Quinta-feira",
    "sex": "Sexta-feira",
    "sab": "Sábado",
    "dom": "Domingo",
    "agendamentos": "Agendamentos",
    "indicadoresTelaDescricao": "Tela para apresentar os dados de atendimentos e triagens",
    "descricaoListagemHorario": "Tela para gerar e consultar a agenda do médico",
    "CadAgendamentoConsultaDescricao": "Tela para agendar uma consulta especializada. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",
    "CadAgendamentoConsultaListarDescricao": "Tela para listar as consultas agendadas",
    "agendaGeradaComSucesso": "Agenda gerada com sucesso",
    "erroAoGerarAgenda": "Erro ao gerar agenda",
    "agendar": "Agendar",
    "descricaoIndicadoresAtendimentos": "Tela para mostrar dados dos atendimentos através de filtros",
    "adicionarFiltro": "Adicionar filtro",
    "pesquisar": "Pesquisar",
    "pesquisando": "Pesquisando",
    "ocorreuUmErroAoFazerPesquisa": "Ocorreu um erro ao fazer pesquisa",
    "semMedico": "Sem médico",
    "semTecDeEnfermagem": "Sem técnico de Enfermagem",
    "semEnfermeiro": "Sem enfermeiro",
    "urlCopiadaComSucesso": "Url Copiada com sucesso",
    "documentosGeradosComSucesso": "Documentos gerados com sucesso",
}

if (idioma === "en") {
    var message = {
        jaExisteUmFiltroDeIntervaloDeDias: "Já existe um filtro de intervalo de dias",
        jaExisteUmFiltroComEsseMesmoValor: "Já existe um filtro com esse mesmo valor",
        dias: "Dia(s)",
        horas: "Hora(s)",
        minutos: "Minuto(s)",
        segundos: "Segundo(s)",
        descricaoMinLetra: "O campo descrição deve conter no mínimo 1 letra!",
        senhaIncorreta: "Wrong password",
        usuarioNaoEncontrado: "User not found",
        erroDesconhecido: "Unknow error",
        preenchaTodosOsCamposCorretamente: "Fill out all of the fields correctly",
        emailEOuSenhaErrados: "Wrong email and/or password",
        emailESenhaSaoObrigatorios: "Email and password are mandatory",
        emailNaoCadastrado: "Email not registered",
        campoEmailNaoPodeEstarVazio: "The email field can't be empty",
        dadosInvalidos: 'Invalid details!',
        cliqueAquiOuArraste: "Click here or drag your files here",
        voceSoPodeAnexar1Arquivo: "You can attach only 1 arquive",
        removerAnexo: "Remove",

        erroAoCriarUsuario: "Error while creating user",
        erroAoCadastrarUsuario: "Error while registering user",
        usuarioValido: "Valid user! \nNow inform an e-mail and password to complete your register!",
        usuarioInvalido: "Invalid user!",
        erroNaBaseDeDados: "Error on the database!",
        nomeMaeInvalido: "Invalid mother's name!",
        nomeCompletoInvalido: "Invalid full name!",
        cpfInvalido: "Invalid CPF!",
        usuarioInativo: "Inactive User!",
        horarioCadastradoComSucesso: "Horário cadastrado com sucesso",//ainda n possui tradução 
        horarioAtualizadoComSucesso: "Horário atualizado com sucesso",//ainda n possui tradução 
        erroAoCadastrarHorario: "Erro ao cadastrar horário",//ainda n possui tradução 
        erroAoAtualizarHorario: "Erro ao atualizar horário",//ainda n possui tradução 
        horaIni: "Horário Inicial",//ainda n possui tradução 
        horaFim: "Horário Final",//ainda n possui tradução
        horarios_do_medico: "Horários do médico:",//ainda n possui tradução
        diasCadastrados: "Dias que já estão cadastrados:",//ainda n possui tradução
        horarioExcluido: "Horário excluído com sucesso!",
        falhaAoExcluirHorario: "Falha ao excluir horário",
        adicionarHorarios: "Horários",
        agenda: "Agenda",



        documentoNaoEhDaMesmaConsulta: "Documento anexado não é da mesma consulta.", //ainda n possui tradução 
        documentoInvalido: "Documento inválido!", //ainda n possui tradução 
        erroNoUpload: "Erro ao realizar upload, verifique nome do arquivo!",//ainda n possui tradução
        anexeUmDocumento: "Anexe um documento!",//ainda n possui tradução
        caixaDeDocumentosVazia: "A caixa de documentos esta vazia!",//ainda n possui tradução
        selecione_o_sexo: "Selecione o sexo",//ainda n possui tradução
        voceDeveSelecionarAPessoa: "Você deve selecionar a pessoa",//ainda n possui tradução
        voceDeveDigitarAQueixaPrincipal: "Você deve digitar a queixa principal",//ainda n possui tradução
        voceDeveSelecionarOParceiro: "Você deve selecionar o parceiro",//ainda n possui tradução
        usuarioCadastradoComSucesso: "Usuário cadastrado com sucesso!",//ainda n possui tradução
        oPaciente: "O paciente",//ainda n possui tradução
        jaEstaEmUmaOutraConsulta: "já esta em uma outra consulta",//ainda n possui tradução
        campoDeCPFNaoPodeEstarVazio: "O campo de CPF não pode estar vazio",//ainda n possui tradução
        cpfDigitadoInvalido: "O CPF digitado é inválido",//ainda n possui tradução
        estaNaFilaDeTriagem: "esta na fila de triagem",//ainda n possui tradução
        oEnfermeiro: "O enfermeiro",//ainda n possui tradução
        estaRealizandoTriagemDoPaciente: "esta realizando triagem do paciente",//ainda n possui tradução
        foiEncaminhadoParaOAtendimento: "foi encaminhado para o atendimento médico",//ainda n possui tradução
        estaRealizandoTriagemDoPaciente: "esta realizando triagem do paciente",//ainda n possui tradução
        oMedico: "O médico",//ainda n possui tradução
        estaRealizandoOAtendimentoDoPaciente: "esta realizando o atendimento do paciente",//ainda n possui tradução
        oEnfermeiro: "O enfermeiro",//ainda n possui tradução
        aConsultaDoPaciente: "A consulta do paciente",//ainda n possui tradução
        foiEncerradaRedirecionandoParaATelaPrincipal: "foi encerrada! Redirecionando para a tela principal",//ainda n possui tradução
        solicitacaoExames: "Solicitação de Exames",//ainda n possui tradução
        receituarioEspecial: "Receituário Especial",//ainda n possui tradução
        receituarioSimples: "Receituário simples",//ainda n possui tradução
        relatorioMedico: " Relatório médico",//ainda n possui tradução
        atestado: "Atestado",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarOReceituarioEspecial: "Preencha todos os campos para poder gerar o receituário especial",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarOAtestadoMedico: "Preencha todos os campos para poder gerar o atestado médico",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarORelatorioMedico: "Preencha todos os campos para poder gerar o relatório médico",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarUmaSolicitacaoDeExame: "Preencha todos os campos para poder gerar uma solicitação de exame",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarOReceituarioSimples: "Preencha todos os campos para poder gerar o receituário simples",//ainda n possui tradução  
        falhaAoFazerDownloadDoPDF: "Falha ao fazer download do PDF",//ainda n possui tradução
        campoDeTextoNaoPodeEstarVazio: "O campo de texto não pode estar vazio",//ainda n possui tradução
        falhaAoSalvar: "Falha ao salvar",//ainda n possui tradução
        confirmarInativacao: "Confirmar inativação?",//ainda n possui tradução
        hipoteseDiagnostica: "Hipótese diagnóstica",//ainda n possui tradução
        hipoteses_diagnosticas: "Hipóteses diagnósticas",//ainda n possui tradução
        especialidades: "Especialidades",//ainda n possui tradução
        especialidade: "Especialidade",//ainda n possui tradução
        emCasoDeProblemas: "Em caso de problema(s)",//ainda n possui tradução
        realizeUmaOcorrencia: "realize uma ocorrência",//ainda n possui tradução
        vocePrecisaEnviarO: "Você precisa enviar o",//ainda n possui tradução
        paraEncerrarOAtendimento: "para encerrar o atendimento.",//ainda n possui tradução
        vocePrecisaPreencherA: "Você precisa preencher a",//ainda n possui tradução
        conduta: "Conduta",//ainda n possui tradução
        anamnese: "Anamnese",//ainda n possui tradução
        eEnviarO: "e enviar o",//ainda n possui tradução
        eA: "e a",//ainda n possui tradução
        digiteOCampoNomeCorretamente: "Digite o campo de nome corretamente.",//ainda n possui tradução
        cepInformadoNaoFoiEncontrado: "O CEP informado não foi encontrado",//ainda n possui tradução
        erroAoverificarDados: "Erro ao verificar dados!",//ainda n possui tradução
        cpfJaCadastrado: "Cpf já cadastrado!",//ainda n possui tradução
        administradorCadastradoComSucesso: "Administrador cadastrado com sucesso!",//ainda n possui tradução
        turnoCadastradoComSucesso: "Turno cadastrado com sucesso!",//ainda n possui tradução
        especialidadeCadastradoComSucesso: "Especialidade cadastrada com sucesso!",//ainda n possui tradução
        especialidadeAtualizadoComSucesso: "Especialidade atualizada com sucesso!",//ainda n possui tradução
        erroAoCadastrarEspecialidade: "Erro ao cadastrar especialidade",//ainda n possui tradução
        erroAoAtualizarEspecialidade: "Erro ao atualizar especialidade",//ainda n possui tradução
        erroAoVerificarDados: "Erro ao verificar dados.!",//ainda n possui tradução
        telefoneJaCadastradoNoSistema: "Telefone já cadastrado no sistema!",//ainda n possui tradução
        administradorAtualizadoComSucesso: "Administrador atualizado com sucesso!",//ainda n possui tradução
        turnoAtualizadoComSucesso: "Turno atualizado com sucesso!",//ainda n possui tradução
        erroAoAtualizarRegistro: "Erro ao atualizar registro!",//ainda n possui tradução
        cpfInvalido: "CPF inválido",//ainda n possui tradução
        antecedenteJaCadastradaNoSistema: "Antecedente já cadastrada no sistema!",//ainda n possui tradução
        antecedenteCadastradoComSucesso: "Antecedente cadastrado com sucesso!",//ainda n possui tradução
        erroAoSalvarRegistro: "Erro ao salvar registro!",//ainda n possui tradução
        respectivoClientePrecisaTerPeloMenos18Anos: "O respectivo cliente precisa ter pelo menos 18 anos.",//ainda n possui tradução
        erroAoCadastrarCliente: "Erro ao cadastrar cliente!",//ainda n possui tradução
        erroAoAtualizarCliente: "Erro ao atualizar cliente!",//ainda n possui tradução
        erroAoCadastrarTurno: "Erro ao cadastrar turno",//ainda n possui tradução
        erroAoAtualizarTurno: "Erro ao atualizar turno",//ainda n possui tradução
        clienteCadastradoComSucesso: "Cliente cadastrado com sucesso!",//ainda n possui tradução
        selecioneUmParceiro: "Selecione um Parceiro!",//ainda n possui tradução
        celularJaCadastradoNoSistema: "Celular já esta cadastrado no sistema!",//ainda n possui tradução
        clienteAtualizadoComSucesso: "Cliente atualizado com sucesso!",//ainda n possui tradução
        cpfInvalido: "CPF Inválido!",//ainda n possui tradução
        cpfCopiadoComSucesso: "CPF copiado com sucesso",//ainda n possui tradução
        realizadoComSucesso: "Realizado com sucesso!",//ainda n possui tradução
        contatoCadastradoComSucesso: "Contato cadastrado com sucesso!",//ainda n possui tradução
        contatoAtualizadoComSucesso: "Contato atualizado com sucesso!",//ainda n possui tradução
        dadosInvalidosVerifiqueSeOCrmUfCpfOuDataNascEstaoIncorretos: "'Dados inválidos, verifique se o CRM, UF, CPF ou Data Nasc. estão incorretos",//ainda n possui tradução
        parceiroCadastradoComSucesso: "Parceiro cadastrado com sucesso!",//ainda n possui tradução
        cnpjJaFoiCadastradoNoSistema: "CNPJ já foi cadastrado no sistema!",//ainda n possui tradução
        ParceiroAtualizadoComSucesso: "Parceiro atualizado com sucesso!",//ainda n possui tradução
        cnpjInvalido: "CNPJ inválido!",//ainda n possui tradução
        dependenteCadastradoComSucesso: "Dependente cadastrado com sucesso!",//ainda n possui tradução
        erroAoCadastrarDependente: "Erro ao cadastrar dependente!",//ainda n possui tradução
        usuarioAtualizadoComSucesso: "Usuário atualizado com sucesso",//ainda n possui tradução
        digiteCampoNomeCorretamente: "Digite o campo de nome corretamente.",//ainda n possui tradução
        oCoren: "O COREN",//ainda n possui tradução
        jaEstaCadastradoNoSistema: "já esta cadastrado no sistema",//ainda n possui tradução
        estacaoAtualizadaComSucesso: "Estação atualizada com sucesso!",//ainda n possui tradução
        estacaoCadastradaComSucesso: "Estação cadastrada com sucesso!",//ainda n possui tradução
        erroAoCapturarPerfilDoUsuario: "Erro ao capturar perfil do usuário!",//ainda n possui tradução
        verifiqueSeOCrmUfCpfDataNascEstaoIncorretos: "verifique se o CRM, UF, CPF ou Data Nasc. estão incorretos",//ainda n possui tradução
        dadosInvalidos: "Dados inválidos!",//ainda n possui tradução
        oCrm: "O CRM",//ainda n possui tradução
        falhaAoFazerUploadDaFoto: "Falha ao fazer upload da foto",
        formatoDeArquivoInválidoEAceitoSomenteImagens: "Formato de arquivo inválido, é aceito somente imagens!",//ainda n possui tradução
        arquivoEMuitoGrande: "O arquivo é muito grande",//ainda n possui tradução
        tamanhoMaximoPermitido: "Tamanho máximo permitido",//ainda n possui tradução
        ocorrenciaCadastradaComSucesso: "Ocorrência cadastrada com sucesso!",//ainda n possui tradução
        ocorrenciaAtualizadaComSucesso: "Ocorrência atualizada com sucesso!",//ainda n possui tradução
        erroAoAtualizarRegistro: "Erro ao atualizar registro!",//ainda n possui tradução

        campoCrmDeveConter06Caracteres: "O campo CRM deve conter 6 caracteres",//ainda n possui tradução
        campoCepDeveConter8Caracteres: "O campo CEP deve conter 8 caracteres",//ainda n possui tradução
        campoCnpjDeveConter14Caracteres: "O campo CNPJ deve conter 14 caracteres",//ainda n possui tradução
        campoDeDataDeNascimentoObrigatorio: "Campo de Data de Nascimento é obrigatório",//ainda n possui tradução
        campoTelefoneDeveConter10Caracteres: "O campo telefone deve conter 10 caracteres",//ainda n possui tradução
        campoCpfDeveConter11Caracteres: "O campo CPF deve conter 11 caracteres",//ainda n possui tradução
        campoTelefoneDeveConter11Caracteres: "O campo telefone deve conter 11 caracteres",//ainda n possui tradução
        campoCorenDeveConter6Caracteres: "O campo COREN deve conter 6 caracteres",//ainda n possui tradução
        campoSexoObrigatorio: "O campo sexo é obrigatório",//ainda n possui tradução
        campoDataDeNascimentoObrigatorio: "O campo data de nascimento é obrigatório",//ainda n possui tradução
        campoParentescoObrigatorio: "O campo parentesco é obrigatório",//ainda n possui tradução
        campoEstacaoDeConsultaObrigatorio: "O campo estação de consulta é obrigatório",//ainda n possui tradução
        campoParceiroObrigatorio: "O campo parceiro é obrigatório",//ainda n possui tradução
        naoEPossivelRemoverOLogoff: "Não é possivel remover o logoff!",//ainda n possui tradução
        naoEPossivelAdicionarTelaDeAtendimentoParaPerfilNaoMedico: "Não é possivel adicionar tela de atendimento, para um perfil que não é médico!",//ainda n possui tradução
        prescricaoMedicaCadastradaComSucesso: "Prescrição médica cadastrada com sucesso!",//ainda n possui tradução
        prescricaoMedicaAtualizadaComSucesso: "Prescrição médica atualizada com sucesso!",//ainda n possui tradução
        hipoteseDiagnosticaAtualizadaComSucesso: "Hipótese diagnóstica atualizada com sucesso!",//ainda n possui tradução
        hipoteseDiagnosticaCadastradaComSucesso: "Hipótese diagnóstica cadastrada com sucesso!",//ainda n possui tradução    
        selecioneUmaEstacaoDeConsulta: "Selecione uma Estação de Consulta",//ainda n possui tradução
        selecioneONivelDeGravidadeAtendimento: "Selecione o nível de gravidade atendimento",//ainda n possui tradução
        digiteOCampoDeAlturaCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de altura corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDePesoCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de peso corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDeTemperaturaCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de temperatura corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDePressaoArterialCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de pressão arterial corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDePulsoCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de pulso corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDeRpmCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de RPM corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOcampoDeSpo2CorretamenteOuMarqueOCampoIndeferido: "Digite o campo de saturação de oxigênio corretamente ou marque o campo indeferido.",//ainda n possui tradução
        selecioneONivelDeGravidadeAtendimento: "Selecione o nível de gravidade atendimento",//ainda n possui tradução
        cadastros: "Cadastros",//ainda n possui tradução
        administradores: "Administradores",//ainda n possui tradução
        antecedentes: "Antecedentes",//ainda n possui tradução
        clientes: "Clientes",//ainda n possui tradução
        enfermeiros: "Enfermeiros",//ainda n possui tradução
        estacoesDeConsulta: "Estações de Consulta",//ainda n possui tradução
        medicos: "Médicos",//ainda n possui tradução
        parceiros: "Parceiros",//ainda n possui tradução
        tecnicos_em_enfermagem: "Técnicos em Enfermagem",//ainda n possui tradução
        perfis: "Perfis",//ainda n possui tradução
        operadores: "Operadores",//ainda n possui tradução
        prescricoes_medicas: "Prescrições médicas",//ainda n possui tradução
        ocorrencias: "Ocorrências",//ainda n possui tradução
        sair: "Sair",//ainda n possui tradução
        atendimento: "Atendimento",//ainda n possui tradução
        descricaoAlteracaoConfigUsuario: "Alteração de dados do PERFIL do usuário corrente.",//ainda n possui tradução
        descricaoLogOff: "Opção no menu de logoff (sair) do sistema. Obrigatório para todos usuários.",//ainda n possui tradução
        descricaoVisualizaçãoDoMapa: "Tela DASHBOARD para visualização do MAPA com points de clientes e outros dados.",//ainda n possui tradução
        descricaoDashFila: "Tela DASHBOARD para visualização da FILA e outros dados.",//ainda n possui tradução
        descricaoVideoMedico: "Tela de VIDEO CONFERENCIA para o MEDICO realizar o atendimento e para o ENFERMEIRO realizar a triagem.",//ainda n possui tradução
        descricaoDashTecEnfermeiro: "Tela DASHBOARD para o Técnico em Enfermagem.",//ainda n possui tradução
        descricaoDashTecEnfermagem: "Tela para o técnico de enfermagem iniciar o atendimento do cliente na Estação de Consulta.",//ainda n possui tradução
        descricaoAdmDash: "Tela DASHBOARD para o ADMINISTRADOR.",//ainda n possui tradução
        descricaoDashMedico: "Tela DASHBOARD para o MEDICO capturar clientes da fila e realizar o atendimento.",//ainda n possui tradução
        descricaoDashEnfermeiro: "Tela DASHBOARD para o ENFERMEIRO capturar clientes da fila e realizar a triagem.",//ainda n possui tradução
        descricaoConfigPerfilUser: "Tela para CONFIGURAR o perfil de um usuário especifico. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemPerfisUser: "Tela para LISTAGEM dos perfis de usuários.",//ainda n possui tradução
        descricaoTelaCadastroPerfil: "Tela para CONFIGURAR e CADASTRAR perfil de usuário. Quais telas são permitidas por perfil. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemOperadores: "Tela de LISTAGEM dos operadores.",//ainda n possui tradução
        descricaoCadastroOperadores: "Tela para CADASTRO dos operadores (administradores de um parceiro). (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoCadastroMedicos: "Tela para CADASTRO de médicos atendentes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemMedicos: "Tela de LISTAGEM de médicos atendentes.",//ainda n possui tradução
        descricaoCadastroAntecedentes: "Tela para CADASTRO de antecedentes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemAntecedentes: "Tela de LISTAGEM de antecedentes.",//ainda n possui tradução
        descricaoCadastroConvenios: "Tela para CADASTRO de convênios, onde existem clientes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemConvenios: "Tela de LISTAGEM de convênios.",//ainda n possui tradução
        descricaoCadastroParceiro: "Subtela para CADASTRO de contatos de um parceiro. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemParceiro: "Subtela para LISTAGEM de contatos de um parceiro.",//ainda n possui tradução
        descricaoCadastroCliente: "Tela para CADASTRO de cliente, que pertencem a um parceiro. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemCliente: "Tela de LISTAGEM de clientes.",//ainda n possui tradução
        descricaoCadastroDependente: "Subtela para CADASTRO de dependentes de um cliente. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemDependente: "Subtela para LISTAGEM de dependentes de um cliente.",//ainda n possui tradução
        descricaoCadastroTecEnfermagem: "Subtela para CADASTRO de Técnicos de Enfermagem. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemTecEnfermagem: "Subtela para LISTAGEM de Técnicos em Enfermagem.",//ainda n possui tradução
        descricaoCadastroEstacao: "Subtela para CADASTRO de Estação de Consulta. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemEstacao: "Subtela para LISTAGEM de Estação de Consulta.",//ainda n possui tradução
        descricaoCadastroEnfermeiro: "Tela para CADASTRO de enfermeiros (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemEnfermeiro: "Tela de LISTAGEM de enfermeiros.",//ainda n possui tradução
        descricaoCadastroAdministradores: "Tela para CADASTRO de administradores do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemAdministradores: "Tela de LISTAGEM dos administradores do sistema.",//ainda n possui tradução
        descricaoCadastroOcorrencias: "Tela para CADASTRO de ocorrências do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemOcorrencias: "Tela de LISTAGEM das ocorrências do sistema.",//ainda n possui tradução
        descricaoCadastroPrescricoes: "Tela para CADASTRO de prescrições médicas do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemPrescricoes: "Tela de LISTAGEM das prescrições médicas do sistema.",//ainda n possui tradução
        descricaoCadastroHipoteses: "Tela para CADASTRO de hipóteses diagnósticas do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemHipoteses: "Tela de LISTAGEM das hipóteses diagnósticas do sistema.",//ainda n possui tradução
        descricaoCadastroTurnos: "Tela para CADASTRO de turnos do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemTurnos: "Tela de LISTAGEM dos turnos do sistema.",//ainda n possui tradução
        descricaoCadastroEspecialidades: "Tela para CADASTRO de especialidades do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemEspecialidades: "Tela de LISTAGEM das especialidades do sistema.",//ainda n possui tradução
        editar: "Editar",//ainda n possui tradução
        editarContatos: "Editar contatos",//ainda n possui tradução
        adicionarDependentes: "Adicionar dependentes",//ainda n possui tradução,
        gerar_documentos: "Gerar Documentos",//ainda n possui tradução,
        enviar_documentos: "Enviar Documentos",//ainda n possui tradução,
        cabineJaEstaCadastrada: "Esta cabine já esta cadastrada no sistema", //ainda n possui tradução,
        enfermeiroCadastradoComSucesso: "Enfermeiro cadastrado com sucesso", //ainda n possui tradução,
        enfermeiroAtualizadoComSucesso: "Enfermeiro atualizado com sucesso", //ainda n possui tradução,
        falhaAoCadastrarAntecedente: "Falha ao cadastrar antecedente",//ainda n possui tradução,
        antecedenteAtualizadoComSucesso: "Antecedente atualizado com sucesso",//ainda n possui tradução
        erroAoAtualizarAntecedente: "Erro ao atualizar antecedente",//ainda n possui tradução
        falhaAoCadastrarOcorrencia: "Falha ao cadastrar ocorrência",//ainda n possui tradução
        erroAoAtualizarOcorrencia: "Erro ao atualizar corrência",//ainda n possui tradução
        ocorrenciaJaCadastradaNoSistema: "Essa ocorrência já esta cadastrada no sistema",//ainda n possui tradução,
        erroAoAtualizarParceiro: "Erro ao atualizar Parceiro!",//ainda n possui tradução,
        erroAoCadastrarParceiro: "Erro ao cadastrar Parceiro!",//ainda n possui tradução,
        clinicasDigitais: "Clínicas digitais",//ainda n possui tradução,
        clinicaDigitalCadastradaComSucesso: "Clínica digital cadastrada com sucesso",//ainda n possui tradução,
        clinicaDigitalJaCadastradaNoSistema: "Clínica digital já cadastrada no sistema",//ainda n possui tradução,
        erroAoCadastrarClinicaDigital: "Erro ao cadastrar clínica digital",//ainda n possui tradução,
        clinicaDigitalAtualizadaComSucesso: "Clínica digital atualizada com sucesso",//ainda n possui tradução,
        erroAoAtualizarClinicaDigital: "Erro ao atualizar clínica digital",//ainda n possui tradução,
        erroAoCadastrarPrescricao: "Erro ao cadastrar prescrição médica",//ainda n possui tradução,
        prescricaoJaCadastradaNoSistema: "Prescrição médica já cadastrada no sistema",//ainda n possui tradução,
        erroAoAtualizarPrescricao: "Erro ao atualizar prescrição médica",//ainda n possui tradução,
        erroAoAtualizarHipotese: "Erro ao atualizar hipótese diagnóstica",//ainda n possui tradução,
        erroAoCadastrarHipotese: "Erro ao cadastrar hipótese diagnóstica",//ainda n possui tradução,
        hipoteseJaCadastradaNoSistema: "Hipótese diagnóstica já cadastrada no sistema",//ainda n possui tradução,
        jaExiste_CPF: "CPF já existe!",//ainda n possui tradução,
        jaExiste_ANTECEDENTE: "Antecedente já existe!",//ainda n possui tradução,
        jaExiste_NOME: "Nome já existe!",//ainda n possui tradução,
        jaExiste_RG: "RG já existe!",//ainda n possui tradução,
        jaExiste_COREN: "COREN já existe!",//ainda n possui tradução,
        jaExiste_CRM: "CRM já existe!",//ainda n possui tradução,
        jaExiste_TITULO: "Título já existe!",//ainda n possui tradução,
        "The email address is already in use by another account.": "E-mail já existe!",//ainda n possui tradução,
        jaExiste_CNPJ: "CNPJ já existe!",//ainda n possui tradução,
        dependenteAtualizadoComSucesso: "Dependente atualizado com sucesso",//ainda n possui tradução,
        erroAoCadastrarAdministrador: "Erro ao cadastrar administrador",//ainda n possui tradução,
        erroAoAtualizarAdministrador: "erroAoAtualizarAdministrador",//ainda n possui tradução
        medicoAtualizadoComSucesso: "Médico atualizado com sucesso",//ainda n possui tradução
        medicoCadastradoComSucesso: "Médico cadastrado com sucesso",//ainda n possui tradução
        erroAoAtualizarMedico: "Erro ao atualizar médico",//ainda n possui tradução
        erroAoCadastrarMedico: "Erro ao cadastrar médico",//ainda n possui tradução
        erroAoCadastrarEnfermeiro: "Erro ao cadastrar enfermeiro",//ainda n possui tradução
        erroAoAtualizarEnfermeiro: "Erro ao atualizar enfermeiro",//ainda n possui tradução
        tecnicoDeEnfermagemCadastradoComSucesso: "Técnico de enfermagem cadastrado com sucesso",//ainda n possui tradução
        tecnicoDeEnfermagemAtualizadoComSucesso: "Técnico de enfermagem atualizado com sucesso",//ainda n possui tradução
        erroAoCadastrarTecnicoDeEnfermagem: "Erro ao cadastrar técnico de enfermagem",//ainda n possui tradução
        erroAoAtualizarTecnicoDeEnfermagem: "Erro ao atualizar técnico de enfermagem",//ainda n possui tradução
        operadorCadastradoComSucesso: "Operador cadastrado com sucesso",//ainda n possui tradução
        operadorAtualizadoComSucesso: "Operador atualizado com sucesso",//ainda n possui tradução
        erroAoCadastrarOperador: "Erro ao cadastrar operador",//ainda n possui tradução
        erroAoAtualizarOperador: "Erro ao atualizar operador",//ainda n possui tradução
        voceNaoEstaEmNenhumAtendimentoNoMomento: "Você não está em nenhum atendimento no momento.",//ainda n possui tradução
        falhaAoCancelarAtendimento: "Falha ao cancelar atendimento.",//ainda n possui tradução
        atendimentoCanceladoComSucesso: "Atendimento cancelado com sucesso.",//ainda n possui tradução
        oAtendimentoFoiEncerrado: "O atendimento foi encerrado",//ainda n possui tradução
        salvoComSucesso: "Salvo com sucesso",//ainda n possui tradução
        erroAoSalvarHipotese: "Erro ao salvar hipótese diagnóstica",//ainda n possui tradução
        erroAoFinalizarVideo: "Erro ao finalizar videoconferência",//ainda n possui tradução
        erroAoIniciarVideo: "Erro ao iniciar videoconferência",//ainda n possui tradução
        falhaAoIniciarTriagem: "Falha ao iniciar triagem",//ainda n possui tradução
        falhaAoIniciarAtendimento: "Falha ao iniciar atendimento",//ainda n possui tradução
        selecione_prescricao: "Selecione o tipo de prescrição",//ainda n possui tradução
        selecione_o_tipo: "Selecione o tipo",//ainda n possui tradução
        tipo: "Tipo",
        erroAoEncerrarTriagem: "Erro ao encerrar triagem",//ainda n possui tradução
        jaExiste_CONSULTA: "Cliente já esta em uma consulta",//ainda n possui tradução
        operacaoRealizadaComSucesso: "Operation successfully completed",//ainda n possui tradução
        notFound_CLIENTE: "Cliente não encontrado!",//ainda n possui tradução
        voceDeveDigitarAmotivoConsulta: "Você deve preencher o motivo da consulta",//ainda n possui tradução
        preenchaOCampoNome: "Preencha o campo nome!",//ainda n possui tradução
        selecioneAHoraDeEncerramentoEAbertura: "Selecione a hora de encerramento e de abertura!",//ainda n possui tradução
        selecionePeloMenosUmDosDias: "Selecione pelo menos um dos dias!",//ainda n possui tradução
        nomeJaEstaEmUso: "O nome já esta em uso!",//ainda n possui tradução    
        selecioneAOcorrencia: "Selecione a ocorrência!",
        "linhasPorPagina": "Rows per page:",
        "de": "of",
        "falhaAoAtualizarRegistro": "Falha ao atualizar registro",
        descricaoVazio: "O campo descrição não pode estar vazio!",
        minLetra: "O campo deve conter no mínimo 1 letra!",
        horaDeAberturaEHoraDeEncerramento: "Horário de abertura e horário de encerramento não podem ser iguais!",
        campoDescricao: "O campo de descrição deve conter no minimo 1 letra!",
        turnos: "Turnos",
        clinica: "Clínica Digital",
        totem: "Totem",
        naoInformado: "Não informado",
        agentesPenitenciarios: "Agentes penitenciários",
        agenteCadastradoComSucesso: "Agente penitenciário cadastrado com sucesso!",
        erroAoCadastrarAgente: "Erro ao cadastrar agente penitenciário",
        agenteAtualizadoComSucesso: "Agente penitenciário cadastrado com sucesso!",
        erroAoAtualizarAgente: "Erro ao atualizar agente penitenciário",
        respectivoAgentePrecisaTerPeloMenos18Anos: "Respectivo agente penitenciário precisa ter pelo menos 18 anos",
        voceDeveSelecionarOParceiroDoAgente: "Você deve selecionar um parceiro para o agente penitenciário",
        "seg": "Segunda-feira",
        "ter": "Terça-feira",
        "qua": "Quarta-feira",
        "qui": "Quinta-feira",
        "sex": "Sexta-feira",
        "sab": "Sábado",
        "dom": "Domingo",
        "agendamentos": "Agendamentos",
        "indicadoresTelaDescricao": "Tela para apresentar os dados de atendimentos e triagens",
        "descricaoListagemHorario": "Tela para gerar e consultar a agenda do médico",
        "CadAgendamentoConsultaDescricao": "Tela para agendar uma consulta especializada. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",
        "CadAgendamentoConsultaListarDescricao": "Tela para listar as consultas agendadas",
        "agendaGeradaComSucesso": "Agenda gerada com sucesso",
        "erroAoGerarAgenda": "Erro ao gerar agenda",
        "agendar": "Agendar",
        "descricaoIndicadoresAtendimentos": "Tela para mostrar dados dos atendimentos através de filtros",
        "adicionarFiltro": "Adicionar filtro",
        "pesquisar": "Pesquisar",
        "pesquisando": "Pesquisando",
        "ocorreuUmErroAoFazerPesquisa": "Ocorreu um erro ao fazer pesquisa",
        "semMedico": "Sem médico",
        "semTecDeEnfermagem": "Sem técnico de Enfermagem",
        "semEnfermeiro": "Sem enfermeiro",
        "urlCopiadaComSucesso": "Url Copiada com sucesso",
        "documentosGeradosComSucesso": "Documentos gerados com sucesso",
    }
}

if (idioma === "es") {
    var message = {
        jaExisteUmFiltroDeIntervaloDeDias: "Já existe um filtro de intervalo de dias",
        jaExisteUmFiltroComEsseMesmoValor: "Já existe um filtro com esse mesmo valor",
        dias: "Dia(s)",
        horas: "Hora(s)",
        minutos: "Minuto(s)",
        segundos: "Segundo(s)",
        descricaoMinLetra: "O campo descrição deve conter no mínimo 1 letra!",
        senhaIncorreta: "Wrong password",
        usuarioNaoEncontrado: "User not found",
        erroDesconhecido: "Unknow error",
        preenchaTodosOsCamposCorretamente: "Fill out all of the fields correctly",
        emailEOuSenhaErrados: "Wrong email and/or password",
        emailESenhaSaoObrigatorios: "Email and password are mandatory",
        emailNaoCadastrado: "Email not registered",
        campoEmailNaoPodeEstarVazio: "The email field can't be empty",
        dadosInvalidos: 'Invalid details!',
        cliqueAquiOuArraste: "Click here or drag your files here",
        voceSoPodeAnexar1Arquivo: "You can attach only 1 arquive",
        removerAnexo: "Remove",

        erroAoCriarUsuario: "Error while creating user",
        erroAoCadastrarUsuario: "Error while registering user",
        usuarioValido: "Valid user! \nNow inform an e-mail and password to complete your register!",
        usuarioInvalido: "Invalid user!",
        erroNaBaseDeDados: "Error on the database!",
        nomeMaeInvalido: "Invalid mother's name!",
        nomeCompletoInvalido: "Invalid full name!",
        cpfInvalido: "Invalid CPF!",
        usuarioInativo: "Inactive User!",
        horarioCadastradoComSucesso: "Horário cadastrado com sucesso",//ainda n possui tradução 
        horarioAtualizadoComSucesso: "Horário atualizado com sucesso",//ainda n possui tradução 
        erroAoCadastrarHorario: "Erro ao cadastrar horário",//ainda n possui tradução 
        erroAoAtualizarHorario: "Erro ao atualizar horário",//ainda n possui tradução 
        horaIni: "Horário Inicial",//ainda n possui tradução 
        horaFim: "Horário Final",//ainda n possui tradução 
        horarios_do_medico: "Horários do médico:",
        diasCadastrados: "Dias que já estão cadastrados:",
        horarioExcluido: "Horário excluído com sucesso!",
        falhaAoExcluirHorario: "Falha ao excluir horário",
        adicionarHorarios: "Horários",
        agenda: "Agenda",


        documentoNaoEhDaMesmaConsulta: "Documento anexado não é da mesma consulta.", //ainda n possui tradução 
        documentoInvalido: "Documento inválido!", //ainda n possui tradução 
        erroNoUpload: "Erro ao realizar upload, verifique nome do arquivo!",//ainda n possui tradução
        anexeUmDocumento: "Anexe um documento!",//ainda n possui tradução
        caixaDeDocumentosVazia: "A caixa de documentos esta vazia!",//ainda n possui tradução
        selecione_o_sexo: "Selecione o sexo",//ainda n possui tradução
        voceDeveSelecionarAPessoa: "Você deve selecionar a pessoa",//ainda n possui tradução
        voceDeveDigitarAQueixaPrincipal: "Você deve digitar a queixa principal",//ainda n possui tradução
        voceDeveSelecionarOParceiro: "Você deve selecionar o parceiro",//ainda n possui tradução
        usuarioCadastradoComSucesso: "Usuário cadastrado com sucesso!",//ainda n possui tradução
        oPaciente: "O paciente",//ainda n possui tradução
        jaEstaEmUmaOutraConsulta: "já esta em uma outra consulta",//ainda n possui tradução
        campoDeCPFNaoPodeEstarVazio: "O campo de CPF não pode estar vazio",//ainda n possui tradução
        cpfDigitadoInvalido: "O CPF digitado é inválido",//ainda n possui tradução
        estaNaFilaDeTriagem: "esta na fila de triagem",//ainda n possui tradução
        oEnfermeiro: "O enfermeiro",//ainda n possui tradução
        estaRealizandoTriagemDoPaciente: "esta realizando triagem do paciente",//ainda n possui tradução
        foiEncaminhadoParaOAtendimento: "foi encaminhado para o atendimento médico",//ainda n possui tradução
        estaRealizandoTriagemDoPaciente: "esta realizando triagem do paciente",//ainda n possui tradução
        oMedico: "O médico",//ainda n possui tradução
        estaRealizandoOAtendimentoDoPaciente: "esta realizando o atendimento do paciente",//ainda n possui tradução
        oEnfermeiro: "O enfermeiro",//ainda n possui tradução
        aConsultaDoPaciente: "A consulta do paciente",//ainda n possui tradução
        foiEncerradaRedirecionandoParaATelaPrincipal: "foi encerrada! Redirecionando para a tela principal",//ainda n possui tradução
        solicitacaoExames: "Solicitação de Exames",//ainda n possui tradução
        receituarioEspecial: "Receituário Especial",//ainda n possui tradução
        receituarioSimples: "Receituário simples",//ainda n possui tradução
        relatorioMedico: " Relatório médico",//ainda n possui tradução
        atestado: "Atestado",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarOReceituarioEspecial: "Preencha todos os campos para poder gerar o receituário especial",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarOAtestadoMedico: "Preencha todos os campos para poder gerar o atestado médico",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarORelatorioMedico: "Preencha todos os campos para poder gerar o relatório médico",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarUmaSolicitacaoDeExame: "Preencha todos os campos para poder gerar uma solicitação de exame",//ainda n possui tradução  
        preenchaTodosOsCamposParaPoderGerarOReceituarioSimples: "Preencha todos os campos para poder gerar o receituário simples",//ainda n possui tradução  
        falhaAoFazerDownloadDoPDF: "Falha ao fazer download do PDF",//ainda n possui tradução
        campoDeTextoNaoPodeEstarVazio: "O campo de texto não pode estar vazio",//ainda n possui tradução
        falhaAoSalvar: "Falha ao salvar",//ainda n possui tradução
        confirmarInativacao: "Confirmar inativação?",//ainda n possui tradução
        hipoteseDiagnostica: "Hipótese diagnóstica",//ainda n possui tradução
        hipoteses_diagnosticas: "Hipóteses diagnósticas",//ainda n possui tradução
        especialidades: "Especialidades",//ainda n possui tradução
        especialidade: "Especialidade",//ainda n possui tradução
        emCasoDeProblemas: "Em caso de problema(s)",//ainda n possui tradução
        realizeUmaOcorrencia: "realize uma ocorrência",//ainda n possui tradução
        vocePrecisaEnviarO: "Você precisa enviar o",//ainda n possui tradução
        paraEncerrarOAtendimento: "para encerrar o atendimento.",//ainda n possui tradução
        vocePrecisaPreencherA: "Você precisa preencher a",//ainda n possui tradução
        conduta: "Conduta",//ainda n possui tradução
        anamnese: "Anamnese",//ainda n possui tradução
        eEnviarO: "e enviar o",//ainda n possui tradução
        eA: "e a",//ainda n possui tradução
        digiteOCampoNomeCorretamente: "Digite o campo de nome corretamente.",//ainda n possui tradução
        cepInformadoNaoFoiEncontrado: "O CEP informado não foi encontrado",//ainda n possui tradução
        erroAoverificarDados: "Erro ao verificar dados!",//ainda n possui tradução
        cpfJaCadastrado: "Cpf já cadastrado!",//ainda n possui tradução
        administradorCadastradoComSucesso: "Administrador cadastrado com sucesso!",//ainda n possui tradução
        turnoCadastradoComSucesso: "Turno cadastrado com sucesso!",//ainda n possui tradução
        especialidadeCadastradoComSucesso: "Especialidade cadastrada com sucesso!",//ainda n possui tradução
        especialidadeAtualizadoComSucesso: "Especialidade atualizada com sucesso!",//ainda n possui tradução
        erroAoCadastrarEspecialidade: "Erro ao cadastrar especialidade",//ainda n possui tradução
        erroAoAtualizarEspecialidade: "Erro ao atualizar especialidade",//ainda n possui tradução
        erroAoVerificarDados: "Erro ao verificar dados.!",//ainda n possui tradução
        telefoneJaCadastradoNoSistema: "Telefone já cadastrado no sistema!",//ainda n possui tradução
        administradorAtualizadoComSucesso: "Administrador atualizado com sucesso!",//ainda n possui tradução
        turnoAtualizadoComSucesso: "Turno atualizado com sucesso!",//ainda n possui tradução
        erroAoAtualizarRegistro: "Erro ao atualizar registro!",//ainda n possui tradução
        cpfInvalido: "CPF inválido",//ainda n possui tradução
        antecedenteJaCadastradaNoSistema: "Antecedente já cadastrada no sistema!",//ainda n possui tradução
        antecedenteCadastradoComSucesso: "Antecedente cadastrado com sucesso!",//ainda n possui tradução
        erroAoSalvarRegistro: "Erro ao salvar registro!",//ainda n possui tradução
        respectivoClientePrecisaTerPeloMenos18Anos: "O respectivo cliente precisa ter pelo menos 18 anos.",//ainda n possui tradução
        erroAoCadastrarCliente: "Erro ao cadastrar cliente!",//ainda n possui tradução
        erroAoAtualizarCliente: "Erro ao atualizar cliente!",//ainda n possui tradução
        erroAoCadastrarTurno: "Erro ao cadastrar turno",//ainda n possui tradução
        erroAoAtualizarTurno: "Erro ao atualizar turno",//ainda n possui tradução
        clienteCadastradoComSucesso: "Cliente cadastrado com sucesso!",//ainda n possui tradução
        selecioneUmParceiro: "Selecione um Parceiro!",//ainda n possui tradução
        celularJaCadastradoNoSistema: "Celular já esta cadastrado no sistema!",//ainda n possui tradução
        clienteAtualizadoComSucesso: "Cliente atualizado com sucesso!",//ainda n possui tradução
        cpfInvalido: "CPF Inválido!",//ainda n possui tradução
        cpfCopiadoComSucesso: "CPF copiado com sucesso",//ainda n possui tradução
        realizadoComSucesso: "Realizado com sucesso!",//ainda n possui tradução
        contatoCadastradoComSucesso: "Contato cadastrado com sucesso!",//ainda n possui tradução
        contatoAtualizadoComSucesso: "Contato atualizado com sucesso!",//ainda n possui tradução
        dadosInvalidosVerifiqueSeOCrmUfCpfOuDataNascEstaoIncorretos: "'Dados inválidos, verifique se o CRM, UF, CPF ou Data Nasc. estão incorretos",//ainda n possui tradução
        parceiroCadastradoComSucesso: "Parceiro cadastrado com sucesso!",//ainda n possui tradução
        cnpjJaFoiCadastradoNoSistema: "CNPJ já foi cadastrado no sistema!",//ainda n possui tradução
        ParceiroAtualizadoComSucesso: "Parceiro atualizado com sucesso!",//ainda n possui tradução
        cnpjInvalido: "CNPJ inválido!",//ainda n possui tradução
        dependenteCadastradoComSucesso: "Dependente cadastrado com sucesso!",//ainda n possui tradução
        erroAoCadastrarDependente: "Erro ao cadastrar dependente!",//ainda n possui tradução
        usuarioAtualizadoComSucesso: "Usuário atualizado com sucesso",//ainda n possui tradução
        digiteCampoNomeCorretamente: "Digite o campo de nome corretamente.",//ainda n possui tradução
        oCoren: "O COREN",//ainda n possui tradução
        jaEstaCadastradoNoSistema: "já esta cadastrado no sistema",//ainda n possui tradução
        estacaoAtualizadaComSucesso: "Estação atualizada com sucesso!",//ainda n possui tradução
        estacaoCadastradaComSucesso: "Estação cadastrada com sucesso!",//ainda n possui tradução
        erroAoCapturarPerfilDoUsuario: "Erro ao capturar perfil do usuário!",//ainda n possui tradução
        verifiqueSeOCrmUfCpfDataNascEstaoIncorretos: "verifique se o CRM, UF, CPF ou Data Nasc. estão incorretos",//ainda n possui tradução
        dadosInvalidos: "Dados inválidos!",//ainda n possui tradução
        oCrm: "O CRM",//ainda n possui tradução
        falhaAoFazerUploadDaFoto: "Falha ao fazer upload da foto",
        formatoDeArquivoInválidoEAceitoSomenteImagens: "Formato de arquivo inválido, é aceito somente imagens!",//ainda n possui tradução
        arquivoEMuitoGrande: "O arquivo é muito grande",//ainda n possui tradução
        tamanhoMaximoPermitido: "Tamanho máximo permitido",//ainda n possui tradução
        ocorrenciaCadastradaComSucesso: "Ocorrência cadastrada com sucesso!",//ainda n possui tradução
        ocorrenciaAtualizadaComSucesso: "Ocorrência atualizada com sucesso!",//ainda n possui tradução
        erroAoAtualizarRegistro: "Erro ao atualizar registro!",//ainda n possui tradução

        campoCrmDeveConter06Caracteres: "O campo CRM deve conter 6 caracteres",//ainda n possui tradução
        campoCepDeveConter8Caracteres: "O campo CEP deve conter 8 caracteres",//ainda n possui tradução
        campoCnpjDeveConter14Caracteres: "O campo CNPJ deve conter 14 caracteres",//ainda n possui tradução
        campoDeDataDeNascimentoObrigatorio: "Campo de Data de Nascimento é obrigatório",//ainda n possui tradução
        campoTelefoneDeveConter10Caracteres: "O campo telefone deve conter 10 caracteres",//ainda n possui tradução
        campoCpfDeveConter11Caracteres: "O campo CPF deve conter 11 caracteres",//ainda n possui tradução
        campoTelefoneDeveConter11Caracteres: "O campo telefone deve conter 11 caracteres",//ainda n possui tradução
        campoCorenDeveConter6Caracteres: "O campo COREN deve conter 6 caracteres",//ainda n possui tradução
        campoSexoObrigatorio: "O campo sexo é obrigatório",//ainda n possui tradução
        campoDataDeNascimentoObrigatorio: "O campo data de nascimento é obrigatório",//ainda n possui tradução
        campoParentescoObrigatorio: "O campo parentesco é obrigatório",//ainda n possui tradução
        campoEstacaoDeConsultaObrigatorio: "O campo estação de consulta é obrigatório",//ainda n possui tradução
        campoParceiroObrigatorio: "O campo parceiro é obrigatório",//ainda n possui tradução
        naoEPossivelRemoverOLogoff: "Não é possivel remover o logoff!",//ainda n possui tradução
        naoEPossivelAdicionarTelaDeAtendimentoParaPerfilNaoMedico: "Não é possivel adicionar tela de atendimento, para um perfil que não é médico!",//ainda n possui tradução
        prescricaoMedicaCadastradaComSucesso: "Prescrição médica cadastrada com sucesso!",//ainda n possui tradução
        prescricaoMedicaAtualizadaComSucesso: "Prescrição médica atualizada com sucesso!",//ainda n possui tradução
        hipoteseDiagnosticaAtualizadaComSucesso: "Hipótese diagnóstica atualizada com sucesso!",//ainda n possui tradução
        hipoteseDiagnosticaCadastradaComSucesso: "Hipótese diagnóstica cadastrada com sucesso!",//ainda n possui tradução    
        selecioneUmaEstacaoDeConsulta: "Selecione uma Estação de Consulta",//ainda n possui tradução
        selecioneONivelDeGravidadeAtendimento: "Selecione o nível de gravidade atendimento",//ainda n possui tradução
        digiteOCampoDeAlturaCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de altura corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDePesoCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de peso corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDeTemperaturaCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de temperatura corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDePressaoArterialCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de pressão arterial corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDePulsoCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de pulso corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOCampoDeRpmCorretamenteOuMarqueOCampoIndeferido: "Digite o campo de RPM corretamente ou marque o campo indeferido.",//ainda n possui tradução
        digiteOcampoDeSpo2CorretamenteOuMarqueOCampoIndeferido: "Digite o campo de saturação de oxigênio corretamente ou marque o campo indeferido.",//ainda n possui tradução
        selecioneONivelDeGravidadeAtendimento: "Selecione o nível de gravidade atendimento",//ainda n possui tradução
        cadastros: "Cadastros",//ainda n possui tradução
        administradores: "Administradores",//ainda n possui tradução
        antecedentes: "Antecedentes",//ainda n possui tradução
        clientes: "Clientes",//ainda n possui tradução
        enfermeiros: "Enfermeiros",//ainda n possui tradução
        estacoesDeConsulta: "Estações de Consulta",//ainda n possui tradução
        medicos: "Médicos",//ainda n possui tradução
        parceiros: "Parceiros",//ainda n possui tradução
        tecnicos_em_enfermagem: "Técnicos em Enfermagem",//ainda n possui tradução
        perfis: "Perfis",//ainda n possui tradução
        operadores: "Operadores",//ainda n possui tradução
        prescricoes_medicas: "Prescrições médicas",//ainda n possui tradução
        ocorrencias: "Ocorrências",//ainda n possui tradução
        sair: "Sair",//ainda n possui tradução
        atendimento: "Atendimento",//ainda n possui tradução
        descricaoAlteracaoConfigUsuario: "Alteração de dados do PERFIL do usuário corrente.",//ainda n possui tradução
        descricaoLogOff: "Opção no menu de logoff (sair) do sistema. Obrigatório para todos usuários.",//ainda n possui tradução
        descricaoVisualizaçãoDoMapa: "Tela DASHBOARD para visualização do MAPA com points de clientes e outros dados.",//ainda n possui tradução
        descricaoDashFila: "Tela DASHBOARD para visualização da FILA e outros dados.",//ainda n possui tradução
        descricaoVideoMedico: "Tela de VIDEO CONFERENCIA para o MEDICO realizar o atendimento e para o ENFERMEIRO realizar a triagem.",//ainda n possui tradução
        descricaoDashTecEnfermeiro: "Tela DASHBOARD para o Técnico em Enfermagem.",//ainda n possui tradução
        descricaoDashTecEnfermagem: "Tela para o técnico de enfermagem iniciar o atendimento do cliente na Estação de Consulta.",//ainda n possui tradução
        descricaoAdmDash: "Tela DASHBOARD para o ADMINISTRADOR.",//ainda n possui tradução
        descricaoDashMedico: "Tela DASHBOARD para o MEDICO capturar clientes da fila e realizar o atendimento.",//ainda n possui tradução
        descricaoDashEnfermeiro: "Tela DASHBOARD para o ENFERMEIRO capturar clientes da fila e realizar a triagem.",//ainda n possui tradução
        descricaoConfigPerfilUser: "Tela para CONFIGURAR o perfil de um usuário especifico. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemPerfisUser: "Tela para LISTAGEM dos perfis de usuários.",//ainda n possui tradução
        descricaoTelaCadastroPerfil: "Tela para CONFIGURAR e CADASTRAR perfil de usuário. Quais telas são permitidas por perfil. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemOperadores: "Tela de LISTAGEM dos operadores.",//ainda n possui tradução
        descricaoCadastroOperadores: "Tela para CADASTRO dos operadores (administradores de um parceiro). (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoCadastroMedicos: "Tela para CADASTRO de médicos atendentes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemMedicos: "Tela de LISTAGEM de médicos atendentes.",//ainda n possui tradução
        descricaoCadastroAntecedentes: "Tela para CADASTRO de antecedentes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemAntecedentes: "Tela de LISTAGEM de antecedentes.",//ainda n possui tradução
        descricaoCadastroConvenios: "Tela para CADASTRO de convênios, onde existem clientes. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemConvenios: "Tela de LISTAGEM de convênios.",//ainda n possui tradução
        descricaoCadastroParceiro: "Subtela para CADASTRO de contatos de um parceiro. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemParceiro: "Subtela para LISTAGEM de contatos de um parceiro.",//ainda n possui tradução
        descricaoCadastroCliente: "Tela para CADASTRO de cliente, que pertencem a um parceiro. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemCliente: "Tela de LISTAGEM de clientes.",//ainda n possui tradução
        descricaoCadastroDependente: "Subtela para CADASTRO de dependentes de um cliente. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemDependente: "Subtela para LISTAGEM de dependentes de um cliente.",//ainda n possui tradução
        descricaoCadastroTecEnfermagem: "Subtela para CADASTRO de Técnicos de Enfermagem. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemTecEnfermagem: "Subtela para LISTAGEM de Técnicos em Enfermagem.",//ainda n possui tradução
        descricaoCadastroEstacao: "Subtela para CADASTRO de Estação de Consulta. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemEstacao: "Subtela para LISTAGEM de Estação de Consulta.",//ainda n possui tradução
        descricaoCadastroEnfermeiro: "Tela para CADASTRO de enfermeiros (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemEnfermeiro: "Tela de LISTAGEM de enfermeiros.",//ainda n possui tradução
        descricaoCadastroAdministradores: "Tela para CADASTRO de administradores do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemAdministradores: "Tela de LISTAGEM dos administradores do sistema.",//ainda n possui tradução
        descricaoCadastroOcorrencias: "Tela para CADASTRO de ocorrências do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemOcorrencias: "Tela de LISTAGEM das ocorrências do sistema.",//ainda n possui tradução
        descricaoCadastroPrescricoes: "Tela para CADASTRO de prescrições médicas do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemPrescricoes: "Tela de LISTAGEM das prescrições médicas do sistema.",//ainda n possui tradução
        descricaoCadastroHipoteses: "Tela para CADASTRO de hipóteses diagnósticas do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemHipoteses: "Tela de LISTAGEM das hipóteses diagnósticas do sistema.",//ainda n possui tradução
        descricaoCadastroTurnos: "Tela para CADASTRO de turnos do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemTurnos: "Tela de LISTAGEM dos turnos do sistema.",//ainda n possui tradução
        descricaoCadastroEspecialidades: "Tela para CADASTRO de especialidades do sistema. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",//ainda n possui tradução
        descricaoListagemEspecialidades: "Tela de LISTAGEM das especialidades do sistema.",//ainda n possui tradução
        editar: "Editar",//ainda n possui tradução
        editarContatos: "Editar contatos",//ainda n possui tradução
        adicionarDependentes: "Adicionar dependentes",//ainda n possui tradução,
        gerar_documentos: "Gerar Documentos",//ainda n possui tradução,
        enviar_documentos: "Enviar Documentos",//ainda n possui tradução,
        cabineJaEstaCadastrada: "Esta cabine já esta cadastrada no sistema", //ainda n possui tradução,
        enfermeiroCadastradoComSucesso: "Enfermeiro cadastrado com sucesso", //ainda n possui tradução,
        enfermeiroAtualizadoComSucesso: "Enfermeiro atualizado com sucesso", //ainda n possui tradução,
        falhaAoCadastrarAntecedente: "Falha ao cadastrar antecedente",//ainda n possui tradução
        antecedenteAtualizadoComSucesso: "Antecedente atualizado com sucesso",//ainda n possui tradução
        erroAoAtualizarAntecedente: "Erro ao atualizar antecedente",//ainda n possui tradução
        falhaAoCadastrarOcorrencia: "Falha ao cadastrar ocorrência",//ainda n possui tradução
        erroAoAtualizarOcorrencia: "Erro ao atualizar corrência",//ainda n possui tradução
        ocorrenciaJaCadastradaNoSistema: "Essa ocorrência já esta cadastrada no sistema",//ainda n possui tradução,
        erroAoAtualizarParceiro: "Erro ao atualizar Parceiro!",//ainda n possui tradução,
        erroAoCadastrarParceiro: "Erro ao cadastrar Parceiro!",//ainda n possui tradução,
        clinicasDigitais: "Clínicas digitais",//ainda n possui tradução,
        clinicaDigitalCadastradaComSucesso: "Clínica digital cadastrada com sucesso",//ainda n possui tradução,
        clinicaDigitalJaCadastradaNoSistema: "Clínica digital já cadastrada no sistema",//ainda n possui tradução,
        erroAoCadastrarClinicaDigital: "Erro ao cadastrar clínica digital",//ainda n possui tradução,
        clinicaDigitalAtualizadaComSucesso: "Clínica digital atualizada com sucesso",//ainda n possui tradução,
        erroAoAtualizarClinicaDigital: "Erro ao atualizar clínica digital",//ainda n possui tradução,
        erroAoCadastrarPrescricao: "Erro ao cadastrar prescrição médica",//ainda n possui tradução,
        prescricaoJaCadastradaNoSistema: "Prescrição médica já cadastrada no sistema",//ainda n possui tradução,
        erroAoAtualizarPrescricao: "Erro ao atualizar prescrição médica",//ainda n possui tradução,
        erroAoAtualizarHipotese: "Erro ao atualizar hipótese diagnóstica",//ainda n possui tradução,
        erroAoCadastrarHipotese: "Erro ao cadastrar hipótese diagnóstica",//ainda n possui tradução,
        hipoteseJaCadastradaNoSistema: "Hipótese diagnóstica já cadastrada no sistema",//ainda n possui tradução,
        jaExiste_CPF: "CPF já existe!",//ainda n possui tradução,
        jaExiste_ANTECEDENTE: "Antecedente já existe!",//ainda n possui tradução,
        jaExiste_NOME: "Nome já existe!",//ainda n possui tradução,
        jaExiste_RG: "RG já existe!",//ainda n possui tradução,
        jaExiste_COREN: "COREN já existe!",//ainda n possui tradução,
        jaExiste_CRM: "CRM já existe!",//ainda n possui tradução,
        jaExiste_TITULO: "Título já existe!",//ainda n possui tradução,
        "The email address is already in use by another account.": "E-mail já existe!",//ainda n possui tradução,
        jaExiste_CNPJ: "CNPJ já existe!",//ainda n possui tradução,
        dependenteAtualizadoComSucesso: "Dependente atualizado com sucesso",//ainda n possui tradução
        erroAoCadastrarAdministrador: "Erro ao cadastrar administrador",//ainda n possui tradução
        erroAoAtualizarAdministrador: "erroAoAtualizarAdministrador",//ainda n possui tradução
        medicoAtualizadoComSucesso: "Médico atualizado com sucesso",//ainda n possui tradução
        medicoCadastradoComSucesso: "Médico cadastrado com sucesso",//ainda n possui tradução
        erroAoAtualizarMedico: "Erro ao atualizar médico",//ainda n possui tradução
        erroAoCadastrarMedico: "Erro ao cadastrar médico",//ainda n possui tradução
        erroAoCadastrarEnfermeiro: "Erro ao cadastrar enfermeiro",//ainda n possui tradução
        erroAoAtualizarEnfermeiro: "Erro ao atualizar enfermeiro",//ainda n possui tradução
        tecnicoDeEnfermagemCadastradoComSucesso: "Técnico de enfermagem cadastrado com sucesso",//ainda n possui tradução
        tecnicoDeEnfermagemAtualizadoComSucesso: "Técnico de enfermagem atualizado com sucesso",//ainda n possui tradução
        erroAoCadastrarTecnicoDeEnfermagem: "Erro ao cadastrar técnico de enfermagem",//ainda n possui tradução
        erroAoAtualizarTecnicoDeEnfermagem: "Erro ao atualizar técnico de enfermagem",//ainda n possui tradução
        operadorCadastradoComSucesso: "Operador cadastrado com sucesso",//ainda n possui tradução
        operadorAtualizadoComSucesso: "Operador atualizado com sucesso",//ainda n possui tradução
        erroAoCadastrarOperador: "Erro ao cadastrar operador",//ainda n possui tradução
        erroAoAtualizarOperador: "Erro ao atualizar operador",//ainda n possui tradução
        voceNaoEstaEmNenhumAtendimentoNoMomento: "Você não está em nenhum atendimento no momento.",//ainda n possui tradução
        falhaAoCancelarAtendimento: "Falha ao cancelar atendimento.",//ainda n possui tradução
        atendimentoCanceladoComSucesso: "Atendimento cancelado com sucesso.",//ainda n possui tradução
        oAtendimentoFoiEncerrado: "O atendimento foi encerrado",//ainda n possui tradução
        salvoComSucesso: "Salvo com sucesso",//ainda n possui tradução
        erroAoSalvarHipotese: "Erro ao salvar hipótese diagnóstica",//ainda n possui tradução
        erroAoFinalizarVideo: "Erro ao finalizar videoconferência",//ainda n possui tradução
        erroAoIniciarVideo: "Erro ao iniciar videoconferência",//ainda n possui tradução
        falhaAoIniciarTriagem: "Falha ao iniciar triagem",//ainda n possui tradução
        falhaAoIniciarAtendimento: "Falha ao iniciar atendimento",//ainda n possui tradução
        selecione_prescricao: "Selecione o tipo de prescrição",//ainda n possui tradução
        selecione_o_tipo: "Selecione o tipo",//ainda n possui tradução
        tipo: "Tipo",
        erroAoEncerrarTriagem: "Erro ao encerrar triagem",//ainda n possui tradução
        jaExiste_CONSULTA: "Cliente já esta em uma consulta",//ainda n possui tradução
        operacaoRealizadaComSucesso: "Operação realizada com sucesso",//ainda n possui tradução
        notFound_CLIENTE: "Cliente não encontrado!",//ainda n possui tradução
        voceDeveDigitarAmotivoConsulta: "Você deve preencher o motivo da consulta",//ainda n possui tradução
        preenchaOCampoNome: "Preencha o campo nome!",//ainda n possui tradução
        selecioneAHoraDeEncerramentoEAbertura: "Selecione a hora de encerramento e de abertura!",//ainda n possui tradução
        selecionePeloMenosUmDosDias: "Selecione pelo menos um dos dias!",//ainda n possui tradução
        nomeJaEstaEmUso: "O nome já esta em uso!",//ainda n possui tradução
        selecioneAOcorrencia: "Selecione a ocorrência!",
        "linhasPorPagina": "Linhas por página:",
        "de": "de",
        "falhaAoAtualizarRegistro": "Falha ao atualizar registro",
        descricaoVazio: "O campo descrição não pode estar vazio!",
        minLetra: "O campo deve conter no mínimo 1 letra!",
        horaDeAberturaEHoraDeEncerramento: "Horário de abertura e horário de encerramento não podem ser iguais!",
        campoDescricao: "O campo de descrição deve conter no minimo 1 letra!",
        turnos: "Turnos",
        clinica: "Clínica Digital",
        totem: "Totem",
        naoInformado: "Não informado",
        agentesPenitenciarios: "Agentes penitenciários",
        agenteCadastradoComSucesso: "Agente penitenciário cadastrado com sucesso!",
        erroAoCadastrarAgente: "Erro ao cadastrar agente penitenciário",
        agenteAtualizadoComSucesso: "Agente penitenciário cadastrado com sucesso!",
        erroAoAtualizarAgente: "Erro ao atualizar agente penitenciário",
        respectivoAgentePrecisaTerPeloMenos18Anos: "Respectivo agente penitenciário precisa ter pelo menos 18 anos",
        voceDeveSelecionarOParceiroDoAgente: "Você deve selecionar um parceiro para o agente penitenciário",
        "seg": "Segunda-feira",
        "ter": "Terça-feira",
        "qua": "Quarta-feira",
        "qui": "Quinta-feira",
        "sex": "Sexta-feira",
        "sab": "Sábado",
        "dom": "Domingo",
        "agendamentos": "Agendamentos",
        "indicadoresTelaDescricao": "Tela para apresentar os dados de atendimentos e triagens",
        "descricaoListagemHorario": "Tela para gerar e consultar a agenda do médico",
        "CadAgendamentoConsultaDescricao": "Tela para agendar uma consulta especializada. (Obs: 'Tem que ativar a tela de listagem para poder acessar está tela')",
        "CadAgendamentoConsultaListarDescricao": "Tela para listar as consultas agendadas",
        "agendaGeradaComSucesso": "Agenda gerada com sucesso",
        "erroAoGerarAgenda": "Erro ao gerar agenda",
        "agendar": "Agendar",
        "descricaoIndicadoresAtendimentos": "Tela para mostrar dados dos atendimentos através de filtros",
        "adicionarFiltro": "Adicionar filtro",
        "pesquisar": "Pesquisar",
        "pesquisando": "Pesquisando",
        "ocorreuUmErroAoFazerPesquisa": "Ocorreu um erro ao fazer pesquisa",
        "semMedico": "Sem médico",
        "semTecDeEnfermagem": "Sem técnico de enfermagem",
        "semEnfermeiro": "Sem enfermeiro",
        "urlCopiadaComSucesso": "Url Copiada com sucesso",
        "documentosGeradosComSucesso": "Documentos gerados com sucesso",
    }
}

class Translate {
    static translate(msgName) {
        return message[msgName];
    };
    static getListParentesco() {
        if (idioma === "en") {
            const parentescoList = [
                { value: 'Avô(ó)', label: 'Avô(ó)', },
                { value: 'Entiado(a)', label: 'Entiado(a)' },
                { value: 'Filho(a)', label: 'Filho(a)' },
                { value: 'Irmão(a)', label: 'Irmão(a)' },
                { value: 'Mãe', label: 'Mãe' },
                { value: 'Pai', label: 'Pai' },
                { value: 'Sobrinho(a)', label: 'Sobrinho(a)' },
                { value: 'Tio(a)', label: 'Tio(a)' },
                { value: 'Marido', label: 'Marido' },
                { value: 'Esposa', label: 'Esposa' },
            ];
            return parentescoList;
        } else if (idioma === 'es') {
            const parentescoList = [
                { value: 'Avô(ó)', label: 'Avô(ó)', },
                { value: 'Entiado(a)', label: 'Entiado(a)' },
                { value: 'Filho(a)', label: 'Filho(a)' },
                { value: 'Irmão(a)', label: 'Irmão(a)' },
                { value: 'Mãe', label: 'Mãe' },
                { value: 'Pai', label: 'Pai' },
                { value: 'Sobrinho(a)', label: 'Sobrinho(a)' },
                { value: 'Tio(a)', label: 'Tio(a)' },
                { value: 'Marido', label: 'Marido' },
                { value: 'Esposa', label: 'Esposa' },
            ];
            return parentescoList;
        } else {
            const parentescoList = [
                { value: 'Avô(ó)', label: 'Avô(ó)', },
                { value: 'Entiado(a)', label: 'Entiado(a)' },
                { value: 'Filho(a)', label: 'Filho(a)' },
                { value: 'Irmão(a)', label: 'Irmão(a)' },
                { value: 'Mãe', label: 'Mãe' },
                { value: 'Pai', label: 'Pai' },
                { value: 'Sobrinho(a)', label: 'Sobrinho(a)' },
                { value: 'Tio(a)', label: 'Tio(a)' },
                { value: 'Marido', label: 'Marido' },
                { value: 'Esposa', label: 'Esposa' },
            ];
            return parentescoList;
        }
    }
    static listaOpcao = [
        {
            value: "id",
            label: "Intervalo de dias"
        },
        {
            value: "idEnfermeiro",
            label: "Enfermeiro"
        },
        {
            value: "idMedico",
            label: "Médico"
        },
        {
            value: "idParceiro",
            label: "Parceiro"
        },
        {
            value: "idTecEnfermagem",
            label: "Técnico de enfermagem"
        },
        {
            value: "tempoDeTriagem",
            label: "Tempo de triagem"
        },

        {
            value: "tempoDeAtendimento",
            label: "Tempo de atendimento"
        },
        {
            value: "tempoDeEsperaFilaAtendimento",
            label: "Tempo de espera na fila de atendimento"
        },
        {
            value: "tempoDeEsperaFilaTriagem",
            label: "Tempo de espera na fila de triagem"
        },
        {
            value: "isAbandono",
            label: "Consultas abandonadas na fila"
        },
        {
            value: "tempoTotalAtendimento",
            label: "Tempo total de atendimento"
        },
        {
            value: "tipo",
            label: "Tipo de consulta"
        },
        {
            value: "encerramentoPorOcorrencia",
            label: "Consultas encerradas por ocorrência"
        },
    ];
    static tipoConsultaList = [
        {
            value: 'WEB',
            label: 'Web'
        },
        {
            value: 'TOTEM',
            label: 'Totem'
        },
        {
            value: 'CLINICA',
            label: 'Cliníca digital'
        },
        {
            value: 'APP',
            label: 'Aplicativo'
        },
    ]
    static listSimNao = [
        {
            value: true,
            label: 'Sim'
        },
        {
            value: false,
            label: 'Não'
        },
    ]
    static filterList = [
        {
            value: '<',
            label: 'Menor'
        },
        {
            value: '<=',
            label: 'Menor ou igual'
        },
        {
            value: '==',
            label: 'Igual'
        },
        {
            value: '>',
            label: 'Maior'
        },
    
        {
            value: '>=',
            label: 'Maior ou igual'
        },
    ]
    static getDays(arrDia) {
        var newArrDia = [];
        if (idioma === "en") {
            console.log(1)
            arrDia.map(item => {
                if (item.dom) {
                    newArrDia.push("Sunday");
                } else if (item.seg) {
                    newArrDia.push("Monday");
                } else if (item.ter) {
                    newArrDia.push("Tuesday");
                } else if (item.qua) {
                    newArrDia.push("Wednesday");
                } else if (item.qui) {
                    newArrDia.push("Thursday");
                } else if (item.sex) {
                    newArrDia.push("Friday");
                } else if (item.sab) {
                    newArrDia.push("Saturday");
                }
            });
            return newArrDia;
        } else if (idioma === "es") {
            console.log(2)
            arrDia.map(item => {
                if (item.dom) {
                    newArrDia.push("Domingo");
                } else if (item.seg) {
                    newArrDia.push("Lunes");
                } else if (item.ter) {
                    newArrDia.push("Martes");
                } else if (item.qua) {
                    newArrDia.push("Miércoles");
                } else if (item.qui) {
                    newArrDia.push("Jueves");
                } else if (item.sex) {
                    newArrDia.push("Viernes");
                } else if (item.sab) {
                    newArrDia.push("Sábado");
                }
            });
            return newArrDia;
        } else {
            console.log(3)
            arrDia.map(item => {
                if (item.dom) {
                    newArrDia.push("Domingo");
                } else if (item.seg) {
                    newArrDia.push("Segunda");
                } else if (item.ter) {
                    newArrDia.push("Terça");
                } else if (item.qua) {
                    newArrDia.push("Quarta");
                } else if (item.qui) {
                    newArrDia.push("Quinta");
                } else if (item.sex) {
                    newArrDia.push("Sexta");
                } else if (item.sab) {
                    newArrDia.push("Sábado");
                }
            });
            return newArrDia;
        }

    }
    static getListSexo() {
        if (idioma === "en") {
            var SexoList = [
                {
                    value: 'Feminino',
                    label: 'Female',
                },
                {
                    value: 'Masculino',
                    label: 'Male',
                },
                {
                    value: 'Outros',
                    label: 'Other',
                },
            ];
            return SexoList;
        }
        if (idioma === "es") {
            var SexoList = [
                {
                    value: 'Feminino',
                    label: 'Female',
                },
                {
                    value: 'Masculino',
                    label: 'Male',
                },
                {
                    value: 'Outros',
                    label: 'Other',
                },
            ];
            return SexoList;
        }
        var SexoList = [
            {
                value: 'Feminino',
                label: 'Feminino',
            },
            {
                value: 'Masculino',
                label: 'Masculino',
            },
            {
                value: 'Outros',
                label: 'Outros',
            },
        ];
        return SexoList;
    };
    static getTipo() {
        if (idioma === "en") {
            var TipoList = [
                {
                    value: 'totem',
                    label: 'Totem',
                },
                {
                    value: 'clinica',
                    label: 'Clínica Digital',
                },
            ];
            return TipoList;
        }
        if (idioma === "es") {
            var TipoList = [
                {
                    value: 'totem',
                    label: 'Totem',
                },
                {
                    value: 'clinica',
                    label: 'Clínica Digital',
                },
            ];
            return TipoList;
        }
        var TipoList = [
            {
                value: 'totem',
                label: 'Totem',
            },
            {
                value: 'clinica',
                label: 'Clínica Digital',
            },
        ];
        return TipoList;
    }
}
export default Translate;